import React from 'react';
import classNames from 'classnames';

import styles from './Tabs.module.scss';

const TabsContext = React.createContext({});

export class Tabs extends React.Component {

	_container = React.createRef();

	_indicator = React.createRef();

	_tabs = React.createRef();

	offset = 0;

	tabsRefs = new Map();

	setTabRef = (props, idx, el) => {
		this.tabsRefs.set(idx, {
			el,
			value: props.value,
		});
	};

	getMovingEl = () => {
		return this.props.reverseMove ? this._tabs.current : this._indicator.current;
	};

	getOffsetMultiplier = () => {
		return this.props.reverseMove ? -1 : 1;
	};

	getActiveTab = () => {
		// eslint-disable-next-line no-unused-vars
		for (const [key, { el, value }] of this.tabsRefs) {
			if (value === this.props.selected) {
				return el;
			}
		}
	};

	getFirstTab = () => {
		return Array.from(this.tabsRefs)[0][1].el;
	};

	getLastTab = () => {
		return Array.from(this.tabsRefs)[this.tabsRefs.size - 1][1].el;
	};

	calcOffset = (activeElement) => {
		const container = this._container.current;
		const tabs = this._tabs.current;
		const elClientWidth = activeElement.clientWidth / 2;
		const parentWidth = container.clientWidth / 2;
		const tabsOffset = tabs.offsetLeft;
		const iconWidth = 0;
		const activeElementOffset = activeElement.offsetLeft;
		return Math.round(activeElementOffset + elClientWidth - parentWidth - iconWidth + tabsOffset);
	};

	applyOffset = (offset, noTransition = false) => {
		const container = this.getMovingEl();
		container.style.transform = `translateX(${offset}px)`;
		if (noTransition) {
			container.style.transition = 'none';
		} else {
			container.style.transition = '';
		}
	};

	move = (noTransition = false) => {
		const container = this._container.current;
		const tabs = this._tabs.current;
		if (!container || !tabs) {
			return;
		}
		const activeElement = this.getActiveTab();
		if (!activeElement) {
			return;
		}
		const offset = this.calcOffset(activeElement);
		const multiplier = this.getOffsetMultiplier();

		this.offset = offset * multiplier;
		this.applyOffset(this.offset, noTransition);
	};

	touchStartX = 0;

	applyEdgeOffsets = (newOffset) => {
		const firstTabOffset = -this.calcOffset(this.getFirstTab());
		const lastTabOffset = -this.calcOffset(this.getLastTab());
		return Math.max(lastTabOffset, Math.min(firstTabOffset, newOffset));
	};

	handleTouchMove = (e) => {
		const delta = e.touches[0].clientX - this.touchStartX;
		const newOffset = this.applyEdgeOffsets(this.offset + delta);
		this.applyOffset(newOffset, true);
	};

	handleTouchStart = (e) => {
		this.touchStartX = e.touches[0].clientX;
	};

	findTabByOffset = (offset, direction) => {
		const tabs = this.tabsRefs;
		for (const [index, tab] of tabs) {
			const prevTab = tabs.get(index - direction);
			if (!prevTab) {
				continue;
			}
			const tabOffset = this.calcOffset(tab.el);
			const prevTabOffset = this.calcOffset(prevTab.el);
			if (((direction > 0) === (offset > prevTabOffset)) && ((direction > 0) === (offset < tabOffset))) {
				return tab;
			}
		}
	};

	handleTouchEnd = (e) => {
		const delta = e.changedTouches[0].clientX - this.touchStartX;
		const newOffset = this.applyEdgeOffsets(this.offset + delta);
		const actualDelta = this.offset - newOffset;
		this.offset = newOffset;

		if (actualDelta !== 0) {
			if (actualDelta > 15 || actualDelta < -15) {
				const tab = this.findTabByOffset(-this.offset, actualDelta / Math.abs(actualDelta));
				if (tab) {
					this.handleChange(tab.value);
				}
			} else {
				this.move();
			}
		}
	};

	applyTouchListeners = () => {
		this._tabs.current.addEventListener('touchstart', this.handleTouchStart, {
			passive: true,
		});
		this._tabs.current.addEventListener('touchmove', this.handleTouchMove, {
			passive: true,
		});
		this._tabs.current.addEventListener('touchend', this.handleTouchEnd);
	};

	removeTouchListeners = () => {
		this._tabs.current.removeEventListener('touchstart', this.handleTouchStart, {
			passive: true,
		});
		this._tabs.current.removeEventListener('touchmove', this.handleTouchMove, {
			passive: true,
		});
		this._tabs.current.removeEventListener('touchend', this.handleTouchEnd);
	};

	componentDidMount() {
		this.move(true);
		if (this.props.reverseMove) {
			this.applyTouchListeners();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.reverseMove && !this.props.reverseMove) {
			this._tabs.current.style.transition = 'none';
			this._tabs.current.style.transform = '';
			this.move(true);
			this.removeTouchListeners();
		} else if (!prevProps.reverseMove && this.props.reverseMove) {
			this._indicator.current.style.transition = 'none';
			this._indicator.current.style.transform = '';
			this.move(true);
			this.applyTouchListeners();
		}
		if (prevProps.selected !== this.props.selected) {
			this.move();
		}
	}

	componentWillUnmount() {
		this.removeTouchListeners();
	}

	handleChange = (value) => {
		this.props.onChange(value);
	};

	render() {
		// eslint-disable-next-line no-unused-vars
		const { selected, onChange, children, className, tabsClassName, indicatorClassName, reverseMove, ...rest } = this.props;
		const containerClassName = classNames([styles.container, className]);
		const tabsContainerClassName = classNames([styles.tabs, tabsClassName]);
		const indicatorClass = classNames([styles.indicator, indicatorClassName]);
		let tabsCounter = 0;
		return (
			<div className={containerClassName} {...rest} ref={this._container}>
				<div
					ref={this._tabs}
					className={tabsContainerClassName}
				>
					<TabsContext.Provider value={{ selected, onChange: this.handleChange }}>
						{React.Children.map(children, (child) => {
							if (!React.isValidElement(child) || child.type.displayName !== 'Tab') {
								return child;
							}
							const refsSetter = this.setTabRef.bind(this, child.props, tabsCounter);
							const newChild = React.cloneElement(child, { ref: refsSetter });
							tabsCounter++;
							return newChild;
						})}
					</TabsContext.Provider>
				</div>
				<div className={styles.indicatorContainer}>
					<div
						ref={this._indicator}
						className={indicatorClass}
					/>
				</div>
			</div>
		);
	}
}

export const Tab = React.forwardRef(({ value, children, className, selectedClassName, ...rest }, ref) => {
	return (
		<TabsContext.Consumer>
			{({ selected, onChange }) => {
				const tabClassName = classNames([styles.tab, className, {
					[selectedClassName]: selectedClassName && selected === value,
				}]);
				return (
					<div
						ref={ref}
						className={tabClassName}
						data-value={value}
						onClick={() => onChange(value)}
						{...rest}>
						{children}
					</div>
				);
			}}
		</TabsContext.Consumer>
	);
});
