import React from 'react';
import classNames from 'classnames';

import styles from './VisitInfo.module.scss';
import formatPhone from '../../utils/formatPhone';

const SalonInfo = (props) => {
	const { salon, icon, className, withPhone = false } = props;
	const salonVars = salon?.meta?.templateVars?.pageVars;
	const address = salonVars?.salonAddress ?? 'Салон не доступен';
	return (
		<div className={classNames([styles.info, className])}>
			{icon && <div className={styles.icon}>
				{icon}
			</div>}
			<div className={styles.salon}>
				<span>{address}</span>
				{withPhone && ', '}
				{(withPhone && salonVars) && (
					<a href={'tel:+' + (salonVars.salonPhones?.[0]?.phone ?? '')}>
						{formatPhone(salonVars.salonPhones?.[0]?.phone ?? '')}
					</a>
				)}
			</div>
		</div>
	);
};

export default SalonInfo;
