class StoreListener {
	constructor({ selectors, onChange }) {
		this.selectors = selectors;
		this.onChange = onChange;
	}

	run(state) {
		let changed = false;
		this.selectors.forEach((prev, selector) => {
			const newValue = selector(state);
			if (newValue !== prev) {
				changed = true;
				this.selectors.set(selector, newValue);
			}
		});
		if (changed) {
			this.onChange(...Array.from(this.selectors.values()));
		}
	}
}

class StoreObserver {

	constructor(store) {
		this.store = store;
		this.listeners = [];
		this.running = Promise.resolve();

		this.unsub = this.store.subscribe(async () => {
			const state = { ...this.store.getState() };
			await this.running;
			this.running = new Promise((resolve) => {
				this.listeners.forEach((listener) => listener.run(state));
				resolve();
			});
		});
	}

	listen(selectors, onChange, immediate = false) {
		const selectorsMap = new Map();
		if (!Array.isArray(selectors)) {
			if (typeof selectors !== 'function') {
				throw new Error('Selector must be a function or an array of functions');
			}
			selectorsMap.set(selectors, !immediate ? selectors(this.store.getState()) : undefined);
		} else {
			selectors.forEach((selector) => selectorsMap.set(selector, !immediate ? selector(this.store.getState()) : undefined));
		}
		const listener = new StoreListener({ selectors: selectorsMap, onChange });
		this.listeners.push(listener);
		if (immediate) {
			listener.run(this.store.getState());
		}
	}

	destroy() {
		this.unsub();
	}
}

export default StoreObserver;
