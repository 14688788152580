import React from 'react';
import styles from './Club.module.scss';
import uselessImage from '@/assets/useless.jpg';
import Button from '@/components/Button/Button';
import SmartLink from '@/components/SmartLink';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import parseHtml from '@/utils/htmlParserWithCustomLinks';

export const Club = (props) => {

	const page = props.content;
	const vars = page.templateVars.pageVars;

	const history = useHistory();

	const title = page.title;
	const subtitle = vars.subtitle;

	const level1Title = vars.level1.title;
	const level1Description = parseHtml(vars.level1.description);

	const level2Title = vars.level2.title;
	const level2Description = parseHtml(vars.level2.description);

	const level3Title = vars.level3.title;
	const level3Description = parseHtml(vars.level3.description);

	const blueListTitle = vars.blueList.title;
	const blueListText = parseHtml(vars.blueList.text);

	const info = vars.info;

	return (
		<div className={styles.root}>
			<div>
				<h1 className={styles.title}>{title}</h1>
				<h3 className={styles.subtitle}>{subtitle}</h3>
			</div>
			<div className={styles.bg}>
				<img src={uselessImage} alt={'Фон'}/>
			</div>
			<div className={styles.delimiter}>
				<span className={styles.delimiterText}>Всё просто!</span>
			</div>
			<div className={styles.content}>
				<div className={styles.levels}>
					<div className={classNames([styles.level1, styles.level])}>
						<div className={styles.levelTitle}>{level1Title}</div>
						<div className={classNames([styles.levelDescription, 'text-content'])}>{level1Description}</div>
					</div>
					<div className={classNames([styles.level2, styles.level])}>
						<div className={styles.levelTitle}>{level2Title}</div>
						<div className={classNames([styles.levelDescription, 'text-content'])}>{level2Description}</div>
					</div>
					<div className={classNames([styles.level3, styles.level])}>
						<div className={styles.levelTitle}>{level3Title}</div>
						<div className={classNames([styles.levelDescription, 'text-content'])}>{level3Description}</div>
					</div>
				</div>
				<div className={styles.additionalInfo}>
					<div className={styles.blueList}>
						<div className={styles.blueListContent}>
							<div className={styles.blueListTitle}>
								{blueListTitle}
							</div>
							<div className={classNames([styles.blueListText, 'text-content'])}>
								{blueListText}
							</div>
						</div>
					</div>
					{info.map((item, i) => {
						return (
							<div key={i} className={styles.infoBlock}>
								<div className={styles.infoIcon}>
									<img src={item.icon.image[0].path} alt={item.icon.imageAlt}/>
								</div>
								<div className={classNames([styles.infoText, 'text-content'])}>
									{parseHtml(item.description)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className={styles.action}>
				<div className={styles.joinText}>
					<span>Присоединяйтесь <br/>к «Клубу&nbsp;Пальчики»!</span>
				</div>
				<div className={styles.arrow}></div>
				<div className={styles.buttonContainer}>
					<Button shadowColor={'blue'} onClick={() => history.push('#profile')}>
						Вступить в клуб
					</Button>
				</div>
				<div className={styles.link}>
					<SmartLink path={'https://palchiki.com/static/loyalty.pdf'}>
						Полные правила программы лояльности «Клуб Пальчики»
					</SmartLink>
				</div>
			</div>
		</div>
	);
};
