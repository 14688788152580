import React from 'react';
import styles from './NewSeasonText.module.css';
import NumiconOne from '../../assets/icon-number-1.svg';
import NumiconTwo from '../../assets/icon-number-2.svg';
import NumiconThree from '../../assets/icon-number-3.svg';

class NewSeasonText extends React.Component {

	render() {
		let link;

		switch (this.props.type) {
			default:
			case 'one':
				link = NumiconOne;
				break;
			case 'two':
				link = NumiconTwo;
				break;

			case 'three':
				link = NumiconThree;
				break;
		}

		return (
			<div className={styles.container}>
				<div className={styles.contentBlock}>
					<img className={styles.icon} src={link} alt="icon"/>
					<div className={styles.title}>{this.props.title}</div>
					<div className={styles.secondText}>{this.props.content}</div>
				</div>
			</div>
		);
	}
}

export default NewSeasonText;
