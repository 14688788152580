import React from 'react';

import styles from './TextPage.module.scss';
import parseHtml from '@/utils/htmlParserWithCustomLinks';

export const TextPage = (props) => {

	const page = props.content;
	const vars = page.templateVars;

	return <div className={styles.container}>
		<div className={styles.contentContainer}>
			<div className={styles.textContainer}>
				<div className={styles.content}>
					<h1 className={styles.title}>{page.title}</h1>
					<article className={'text-content'}>{parseHtml(vars.pageVars.content || '')}</article>
				</div>
			</div>
		</div>
	</div>;
};
