export const getGpsDistance = (lat, lng, lat0, lng0) => {
	const R = 6372795;
	const latR = lat * Math.PI / 180;
	const lat0R = lat0 * Math.PI / 180;
	const lngR = lng * Math.PI / 180;
	const lng0R = lng0 * Math.PI / 180;
	const cl1 = Math.cos(latR);
	const cl2 = Math.cos(lat0R);
	const sl1 = Math.sin(latR);
	const sl2 = Math.sin(lat0R);
	const delta = lng0R - lngR;
	const cdelta = Math.cos(delta);
	const sdelta = Math.sin(delta);
	const y = Math.sqrt(Math.pow(cl2 * sdelta, 2) + Math.pow(cl1 * sl2 - sl1 * cl2 * cdelta, 2));
	const x = sl1 * sl2 + cl1 * cl2 * cdelta;
	const ad = Math.atan2(y, x);
	const dist = ad * R;
	return dist;
};
