const summator = (acc, service) => (acc + service.duration * service.amount);

const filterByType = (services, types) => {
	return services.filter((service) => types.includes(service.type));
};

export default (services) => {
	const servicesFeetDuration = filterByType(services, [2, 7, 9]).reduce(summator, 0);
	const servicesHandsDuration = filterByType(services, [1, 7, 8]).reduce(summator, 0);
	const othersDuraion = filterByType(services, [5, 6, 10]).reduce(summator, 0);
	const duration = Math.max(servicesFeetDuration, servicesHandsDuration) + othersDuraion;
	return duration;
};
