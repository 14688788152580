import { createSelector } from 'reselect';
import { getActiveServicesIndex, getSalons, getServices } from '@/store/selectors/DataSelectors';

function getFullServices(selectedServices = [], servicesIndex = {}) {
	return selectedServices.reduce((acc, item) => {
		const serviceInList = servicesIndex[item.id];
		if (serviceInList) {
			const fullService = { ...serviceInList };
			fullService.amount = item.amount;
			acc.push(fullService);
		}
		return acc;
	}, []);
}

export const getAppointmentId = (state) => state.appointment.id;
export const getAppointmentSalonId = (state) => state.appointment.salonId;
export const getAppointmentTime = (state) => state.appointment.time;
export const getAppointmentComment = (state) => state.appointment.comment || '';
export const getAppointmentAvailableTime = (state) => state.appointment.availableTime;
export const isAvailableTimeLoading = (state) => state.appointment.availableTimeLoading;
export const getAppointmentDate = (state) => state.appointment.date;
export const getAppointmentServices = (state) => state.appointment.services;
export const getAppointmentNotification = (state) => state.appointment.notification;

export const getAppointmentSalon = createSelector(
	[getSalons, getAppointmentSalonId],
	(salons, salonId) => {
		return salons.find((salon) => salon.id === salonId) ?? null;
	},
);

// Возврашает объекты выбранных сервисов
export const getAppointmentServicesFull = createSelector(
	[getAppointmentServices, getActiveServicesIndex],
	(clients = {}, servicesIndex = {}) => {
		return Object.entries(clients).reduce((carry, [clientId, selectedServices]) => {
			carry[clientId] = getFullServices(selectedServices, servicesIndex);
			return carry;
		}, {});
	},
);

export const getAppointmentCurrentServices = (state, props) => state.appointment.services[props.clientIndex || 0];

export const getAppointmentCurrentServicesFull = createSelector(
	[getAppointmentCurrentServices, getServices],
	(selectedServices = [], services = []) => getFullServices(selectedServices, services),
);

export const getAppointmentServicesTypes = createSelector(
	[getAppointmentServicesFull],
	(clients = {}) => {
		return Object.entries(clients).reduce((serviceTypesByClient, [clientId, services]) => {
			serviceTypesByClient[clientId] = services.reduce((serviceTypes, service) => {
				const type = service.meta?.templateVars?.pageVars?.type;
				if (!serviceTypes.includes(type)) {
					serviceTypes.push(type);
				}
				return serviceTypes;
			}, []);

			return serviceTypesByClient;
		}, {});
	},
);
