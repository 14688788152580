import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import ShadowInput from '../ShadowInput/ShadowInput';
import ShadowSelect from '../ShadowSelect/ShadowSelect';
import Button from '../Button/Button';
import Countdown from 'react-countdown';

import { confirmPhone, signUp, signUpCheck } from '../../api';
import { getCities, getSelectedCityId } from '../../store/selectors';
import { validateEmail } from '../../utils/validateEmail';

import StatusModal from '../StatusModal/StatusModal';

import styles from './Form.module.scss';
import formStyles from './SignUpForm.module.scss';
import { codeInputMask, focusMaskStart, phoneInputMask } from '@/utils/masks';
import { conformToMask } from 'react-text-mask';
import SmartLink from '@/components/SmartLink';

//TODO: Поделить на 2 формы + контроллер

class SignUpForm extends React.Component {

	state = {
		signUpFormData: {
			defaultCityId: this.props.selectedCityId,
			firstName: '',
			lastName: '',
			sex: null,
			phone: '',
			email: '',
			password: '',
			passwordConfirm: '',
			personalData: true,
		},
		confirmPhoneFormData: {
			code: '',
		},
		codeIssuedAt: null,
		stage: 'data',
		formError: null,
		loading: false,
	};

	setConfirmPhoneFormData = (field, value) => {
		this.setState({
			confirmPhoneFormData: {
				...this.state.signUpFormData,
				[field]: value,
			},
		});
	};

	setSignUpFormData = (field, value) => {
		this.setState({
			signUpFormData: {
				...this.state.signUpFormData,
				[field]: value,
			},
		});
	};

	handlePhoneInputBlur = (e) => {
		if (e.target.value.replace(/\D/g, '') === '7') {
			this.setSignUpFormData('phone', '');
		}
	};

	handlePhoneInputPaste = (e) => {
		e.preventDefault();
		const value = e.clipboardData.getData('Text').replace(/\D/g, '');
		if (value[0] === '7') {
			const { conformedValue } = conformToMask('+' + value, phoneInputMask);
			this.setSignUpFormData('phone', conformedValue);
		} else if (value[0] === '8') {
			const { conformedValue } = conformToMask('+7' + value.slice(1), phoneInputMask);
			this.setSignUpFormData('phone', conformedValue);
		} else {
			const { conformedValue } = conformToMask(value, phoneInputMask);
			this.setSignUpFormData('phone', conformedValue);
		}
	};

	handlePhoneInputChange = (value) => {
		this.setSignUpFormData('phone', value);
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		if (this.state.stage === 'data') {
			if (!this.state.signUpFormData.defaultCityId) {
				this.setState({ formError: 'Не выбран город' });
				return;
			}
			if (!this.state.signUpFormData.firstName) {
				this.setState({ formError: 'Не указано имя' });
				return;
			}
			const clearPhone = this.state.signUpFormData.phone.replace(/\D/g, '');
			if (!clearPhone.length) {
				this.setState({ formError: 'Не указан номер телефона' });
				return;
			}
			if (this.state.signUpFormData.email.length && !validateEmail(this.state.signUpFormData.email)) {
				this.setState({ formError: 'Неверный формат email' });
				return;
			}
			if (!this.state.signUpFormData.password) {
				this.setState({ formError: 'Не указан пароль' });
				return;
			}
			const password = this.state.signUpFormData.password;
			const passwordConfirm = this.state.signUpFormData.passwordConfirm;
			const passwordErrorMismatch = !!(password.length && passwordConfirm.length && password !== passwordConfirm);
			if (passwordErrorMismatch) {
				this.setState({ formError: 'Пароли не совпадают' });
				return;
			}
			this.setLoading(true);
			try {
				await signUpCheck({ ...this.state.signUpFormData, phone: clearPhone });
				await this.sendCode();
				this.setState({
					stage: 'confirm',
				});
				this.setLoading(false);
			} catch (e) {
				this.setState({ formError: e.message });
				this.setLoading(false);
			}
			return;
		}

		if (this.state.stage === 'confirm') {
			if (!this.state.confirmPhoneFormData.code) {
				this.setState({ formError: 'Не указан код подтверждения' });
				return;
			}
			this.setLoading(true);
			const clearPhone = this.state.signUpFormData.phone.replace(/\D/g, '');
			try {
				await signUp({ ...this.state.signUpFormData, phone: clearPhone, code: this.state.confirmPhoneFormData.code });
				this.setLoading(false);
				this.props.onSuccess({ ...this.state.signUpFormData, phone: clearPhone });
			} catch (e) {
				this.setState({ formError: e.message });
				this.setLoading(false);
			}
		}
	};

	sendCode = async () => {
		if ((new Date()).getTime() < this.state.codeIssuedAt + this.codeDuration) {
			return;
		}
		const clearPhone = this.state.signUpFormData.phone.replace(/\D/g, '');
		try {
			await confirmPhone({
				phone: clearPhone,
				purpose: 'sign-up',
			});
			this.setState({
				codeIssuedAt: (new Date()).getTime(),
			});
		} catch (e) {
			this.setState({ formError: e.message });
		}
	};

	dismissErrorModal = () => {
		this.setState({
			formError: null,
		});
	};

	setLoading = (loading) => {
		this.setState({ loading });
	};

	countDownRenderer = ({ minutes, seconds, completed }) => {
		if (completed) {
			return <span onClick={(e) => {
				e.preventDefault();
				this.sendCode();
			}}>Получить код повторно</span>;
		} else {
			const minutesString = minutes.toString().padStart(2, '0');
			const secondsString = seconds.toString().padStart(2, '0');
			return <span>Запросить код повторно можно через {minutesString}:{secondsString}</span>;
		}
	};

	render() {
		const password = this.state.signUpFormData.password;
		const passwordConfirm = this.state.signUpFormData.passwordConfirm;
		const passwordErrorMismatch = !!(password.length && passwordConfirm.length && password !== passwordConfirm);

		return (
			<form noValidate onSubmit={this.handleSubmit} className={styles.form}>
				{this.state.stage === 'data' && <>
					<div className={styles.fields}>
						<ShadowSelect
							onChange={(value) => this.setSignUpFormData('defaultCityId', value)}
							value={this.state.signUpFormData.defaultCityId}
							name={'city'}
							className={styles.field}
							shadowColor={'yellow'}
							placeholder={'Город*'}
							options={this.props.cities.map(({ city }) => city)}
						/>
						<ShadowInput
							autoComplete={'new-client-firstname'}
							onChange={(value) => this.setSignUpFormData('firstName', value)}
							value={this.state.signUpFormData.firstName}
							name={'firstName'}
							className={styles.field}
							shadowColor={'pink'}
							placeholder={'Имя*'}
						/>
						<ShadowInput
							autoComplete={'new-client-lastname'}
							onChange={(value) => this.setSignUpFormData('lastName', value)}
							value={this.state.signUpFormData.lastName}
							name={'lastName'}
							className={styles.field}
							shadowColor={'pink'}
							placeholder={'Фамилия'}
						/>
						<ShadowSelect
							onChange={(value) => this.setSignUpFormData('sex', value)}
							value={this.state.signUpFormData.sex}
							name={'sex'}
							className={styles.field}
							shadowColor={'blue'}
							placeholder={'Пол'}
							options={[{ id: 2, name: 'Мужской' }, { id: 3, name: 'Женский' }]}
						/>
						<ShadowInput
							autoComplete={'new-client-phone'}
							onFocus={focusMaskStart}
							onBlur={this.handlePhoneInputBlur}
							onChange={this.handlePhoneInputChange}
							onPaste={this.handlePhoneInputPaste}
							value={this.state.signUpFormData.phone}
							type={'tel'}
							name={'phone'}
							mask={phoneInputMask}
							className={styles.field}
							shadowColor={'satin'}
							placeholder={'Мобильный телефон*'}
						/>
						<ShadowInput
							autoComplete={'new-client-email'}
							onChange={(value) => this.setSignUpFormData('email', value)}
							value={this.state.signUpFormData.email}
							type={'email'}
							name={'email'}
							className={styles.field}
							shadowColor={'satin'}
							placeholder={'Email'}
						/>
						<ShadowInput
							autoComplete={'new-client-password'}
							lang={'en'}
							error={passwordErrorMismatch}
							onChange={(value) => this.setSignUpFormData('password', value)}
							value={this.state.signUpFormData.password}
							name={'password'}
							type={'password'}
							className={styles.field}
							shadowColor={'purple'}
							placeholder={'Пароль*'}
						/>
						<ShadowInput
							autoComplete={'new-client-password-confirm'}
							lang={'en'}
							error={passwordErrorMismatch}
							onChange={(value) => this.setSignUpFormData('passwordConfirm', value)}
							value={this.state.signUpFormData.passwordConfirm}
							name={'passwordConfirm'}
							type={'password'}
							className={styles.field}
							shadowColor={'purple'}
							placeholder={'Повторите пароль*'}
						/>
						<div className={classNames([styles.field, styles.personal])}>
							<div>
								<span>Нажимая на кнопку &quot;Готово&quot; я даю своё согласие на обработку моей персональной информации на условиях, определенных </span>
								<SmartLink
									href={'/personal'}
									target={'_blank'}
									rel="noopener noreferrer">
									Политикой конфидициальности.
								</SmartLink>
							</div>
						</div>
					</div>
				</>}
				{this.state.stage === 'confirm' && <>
					<div className={styles.fields}>
						<div className={styles.note}>
							Введите код из смс
						</div>
						<ShadowInput
							onFocus={focusMaskStart}
							mask={codeInputMask}
							onChange={(value) => this.setConfirmPhoneFormData('code', value)}
							value={this.state.confirmPhoneFormData.code}
							name={'code'}
							className={styles.field}
							shadowColor={'purple'}
						/>
						<div className={formStyles.resend}>
							<Countdown
								key={this.state.codeIssuedAt}
								date={this.state.codeIssuedAt + this.codeDuration}
								renderer={this.countDownRenderer}
							/>
						</div>
					</div>
				</>}
				<div className={styles.actions}>
					<Button
						type={'submit'}
						disabled={this.state.loading}
						className={styles.action}>
						Готово
					</Button>
				</div>
				<StatusModal
					status={'error'}
					modalText={this.state.formError}
					buttonText={'ОК'}
					onClose={this.dismissErrorModal}
					onButtonClick={this.dismissErrorModal}
					isOpen={this.state.formError !== null}/>
			</form>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		cities: getCities(state),
		selectedCityId: getSelectedCityId(state),
	};
};

export default connect(mapStateToProps)(SignUpForm);
