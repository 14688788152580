import React from 'react';
import classNames from 'classnames';

import formatPhone from '../../utils/formatPhone';

import styles from './Contacts.module.scss';

function ContactsBlock({ block }) {

	const phones = block.phone.split(',').map((phone) => phone.replace(/\D/g, ''));

	return <div className={styles.block} itemScope itemType={'http://schema.org/Organization'}>
		<div className={styles.blockIcon}>
			<img className={styles.icon} src={block.icon ? block.icon.image[0].path : undefined} alt="icon"/>
		</div>
		<div className={styles.blockContent}>
			<div itemProp={'name'} className={styles.blockTitle}>{block.title}</div>
			{phones.map((phone, index) => {
				return <div key={index} itemProp={'telephone'} className={styles.phone}>
					<a href={`tel:+${phone}`}>{formatPhone(phone)}</a>
				</div>;
			})}
			<div itemProp={'email'} className={classNames([styles.email, styles.info])}>
				{block.email}
			</div>
			<div>
				<div itemProp={'address'} className={classNames([styles.email, styles.info])}>{block.address}</div>
				<div className={classNames([styles.schedule, styles.info])}>{block.schedule}</div>
			</div>
			{!!block.comments?.length && <div className={classNames([styles.comment, styles.info])}>
				{block.comments}
			</div>}
		</div>
	</div>;
}

export const Contacts = (props) => {

	const page = props.content;
	const vars = page.templateVars.pageVars;

	return <div className={styles.container}>
		<h1 className={styles.title}>{page.title}</h1>
		<div className={styles.blocks}>
			{vars.blocks.map((block, index) => <ContactsBlock key={index} block={block}/>)}
		</div>
	</div>;
};
