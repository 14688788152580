import { Route } from 'react-router-dom';
import React, { useRef } from 'react';
import Modal from '../Modal/Modal';

export const BackContext = React.createContext({});

function HashRouteModal({ hash, back, backOnClose = false, children, ...modalProps }) {

	const innerBackHandler = useRef(null);

	const onClose = (routerProps) => {
		if (backOnClose) {
			routerProps.history.goBack();
		} else {
			routerProps.history.push('#');
		}
	};

	const onBack = (routerProps) => {
		if (innerBackHandler.current) {
			innerBackHandler.current();
			return;
		}
		if (typeof back === 'function') {
			back();
		} else {
			routerProps.history.replace(back);
		}
	};

	return <Route>
		{(routerProps) => (
			<Modal
				back={back ? () => onBack(routerProps) : undefined}
				onClose={() => onClose(routerProps)}
				{...modalProps}
				isOpen={routerProps.location.hash === hash}
			>
				<BackContext.Provider value={innerBackHandler}>
					{children}
				</BackContext.Provider>
			</Modal>
		)}
	</Route>;
}

export default HashRouteModal;
