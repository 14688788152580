import servicePurpose1 from '../assets/service-purpose-1.svg';
import servicePurpose2 from '../assets/service-purpose-2.svg';
import servicePurpose3 from '../assets/service-purpose-3.svg';
import servicePurpose10 from '../assets/services-icons-no-box/brows.svg';

export default (type) => {
	switch (type) {
		default:
		case 1:
		case 8:
			return servicePurpose1;
		case 2:
		case 9:
			return servicePurpose2;
		case 3:
		case 7:
			return servicePurpose3;
		case 10:
			return servicePurpose10;
	}
};
