import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';

import Button from '@/components/Button/Button';
import Arrow from '@/components/Arrow/Arrow';
import Time from './Time';

import Step from './Step';

import {
	getAppointmentAvailableTime,
	getAppointmentComment,
	getAppointmentDate,
	getAppointmentSalon,
	getAppointmentServicesFull,
	getAppointmentTime,
	getCities,
	getSelectedCity,
	isAvailableTimeLoading,
} from '@/store/selectors';

import Popup from '@/components/Popup/Popup';
import Loader from '@/components/Loader/Loader';

import CityPicker from '@/components/CityPicker/CityPicker';

import { ClientSummary } from '../ClientsSummary';

import styles from './AppointmentForm.module.scss';
import {
	removeAppointmentServiceAction,
	setAppointmentCommentAction,
	setAppointmentDateAction,
	setAppointmentServicesAction,
	setAppointmentTimeAction,
	setSelectedCityIdAction,
	unsetAppointmentServicesAction,
} from '@/store/actions';


const dayPickerModifiers = {
	days: { daysOfWeek: [1, 2, 3, 4, 5, 6, 7] },
	weekend: { daysOfWeek: [0, 6] },
};

const dayPickerModifiersStyles = {
	weekend: {
		color: '#EF4136',
	},
	selected: {
		color: 'inherit',
		backgroundColor: 'transparent',
		fontWeight: 'initial',
	},
	today: {
		color: 'inherit',
		fontWeight: 'initial',
	},
	disabled: {
		color: '#DCE0E0 !important',
	},
};

function AppointmentForm({ onSubmit, onChooseSalon, onChooseServices }) {

	const [isDatePickerActive, setDatePickerActive] = React.useState(false);
	const [isCityPickerActive, setCityPickerActive] = React.useState(false);

	const dispatch = useDispatch();
	const selectedCity = useSelector(getSelectedCity);
	const salon = useSelector(getAppointmentSalon);
	const clients = useSelector(getAppointmentServicesFull);
	const date = useSelector(getAppointmentDate);
	const availableTime = useSelector(getAppointmentAvailableTime);
	const availableTimeLoading = useSelector(isAvailableTimeLoading);
	const time = useSelector(getAppointmentTime);
	const cities = useSelector(getCities);
	const comment = useSelector(getAppointmentComment);

	const showCityPicker = useCallback((e) => {
		e.stopPropagation();
		setCityPickerActive(true);
	}, []);

	const hideCityPicker = useCallback(() => {
		setCityPickerActive(false);
	}, []);

	const showDayPicker = useCallback((e) => {
		e.stopPropagation();
		setDatePickerActive(true);
	}, []);

	const hideDayPicker = useCallback(() => {
		setDatePickerActive(false);
	}, []);

	const handleSelectCity = useCallback((cityId, event) => {
		event.preventDefault();
		dispatch(setSelectedCityIdAction(cityId));
	}, [dispatch]);

	const handleSelectTime = useCallback((time) => {
		dispatch(setAppointmentTimeAction(time));
	}, [dispatch]);

	const handleRemoveService = useCallback((clientIndex, serviceId) => {
		dispatch(removeAppointmentServiceAction(serviceId, clientIndex));
	}, [dispatch]);

	const handleRemoveClient = useCallback((clientIndex) => {
		dispatch(unsetAppointmentServicesAction(clientIndex));
	}, [dispatch]);

	const handleChangeDate = useCallback((date) => {
		dispatch(setAppointmentDateAction(date));
	}, [dispatch]);

	const handleDayClick = useCallback((day, { disabled }) => {
		if (disabled) {
			return;
		}
		const date = moment(day).format('YYYY-MM-DD');
		handleChangeDate(date);
		hideDayPicker();
	}, [hideDayPicker, handleChangeDate]);

	const handleChangeComment = useCallback((event) => {
		dispatch(setAppointmentCommentAction(event.target.value));
	}, [dispatch]);

	const handleAddClient = useCallback((clientIndex) => {
		dispatch(setAppointmentServicesAction(clientIndex, []));
	}, [dispatch]);

	const filteredTime = useMemo(() => {
		if (availableTime === null) {
			return null;
		}
		return availableTime.filter((time) => time.isFree);
	}, [availableTime]);

	const momentDate = date ? moment(date) : moment();
	const formattedDate = momentDate.format('DD.MM.YYYY');
	const uppedDateLimit = new Date();
	uppedDateLimit.setMonth(uppedDateLimit.getMonth() + 2);
	const clientsArr = Object.values(clients);
	if (!clientsArr.length) {
		clientsArr.push([]);
	}

	const commentTooLong = comment.length > 140;
	const notEmptyClients = clientsArr.filter((services) => !!services.length);
	const submitDisabled = !salon || !notEmptyClients.length || !date || !time || commentTooLong;

	let TimeComponent;

	if (availableTime === null || salon === null) {
		TimeComponent =
			<div className={styles.timeEmpty}>Выберите салон, услуги и дату, чтобы увидеть доступное время</div>;
	} else {
		const hasBrows = Object.values(clients).some((client) => client.some((service) => service.divisions === 2));
		const salonHasBrows = salon.divisions.includes(2);
		if (hasBrows && !salonHasBrows) {
			TimeComponent = <div className={styles.timeEmpty}>В выбранном салоне недоступны услуги бровиста</div>;
		} else if (!filteredTime.length) {
			TimeComponent = <div className={styles.timeEmpty}>Нет доступного времени на выбранную дату</div>;
		} else {
			TimeComponent = <Time time={filteredTime} onSelect={handleSelectTime} selected={time}/>;
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.steps}>
				<Step name={'city'} title={'Выберите город'}>
					<div className={styles.cityPicker}>
						<div className={styles.appointmentCityPicker}>
							<div
								className={styles.cityName}
								onClick={showCityPicker}
							>
								{selectedCity ? selectedCity.city.name : ''}
							</div>
							<Popup
								backDropClassName={styles.popupBackDrop}
								className={styles.popup}
								active={isCityPickerActive}
								onClose={hideCityPicker}
							>
								<CityPicker
									cities={cities}
									selectedCityId={selectedCity ? selectedCity.id : null}
									onSelectCity={handleSelectCity}
								/>
							</Popup>
						</div>
						<div className={styles.pickerContainer}>
							<Arrow direction={'right'}/>
						</div>
					</div>
				</Step>
				<Step name={'salon'} title={'Выберите салон'}>
					<div className={styles.salonPicker} onClick={onChooseSalon}>
						<div className={styles.salonName}>
							{salon ?
								(<span>
									<span className={styles.salonTitle}>
										{salon.meta?.templateVars?.pageVars?.salonName}
									</span>
									<span> | </span>
									<span className={styles.salonAddress}>
										{salon.meta?.templateVars?.pageVars?.salonAddress}
									</span>
								</span>)
								: 'Не выбран'}
						</div>
						<div className={styles.pickerContainer}>
							<Arrow direction={'right'}/>
						</div>
					</div>
				</Step>
				<Step name={'service'} title={'Выберите услуги'}>
					<div className={styles.clients}>
						{clientsArr.map((services, index) => {
							return <div key={index} className={styles.client}>
								<div className={styles.summary}>
									{clientsArr.length > 1 &&
										<div className={styles.clientHeader}>
											<span>Гость №{index + 1}</span>
										</div>}
									{!!services.length &&
										<ClientSummary
											index={index}
											editable
											services={services}
											onItemDelete={(serviceId) => handleRemoveService(index, serviceId)}
										/>}
								</div>
								<div className={styles.clientActions}>
									<div className={styles.action} data-action={'add'} onClick={() => onChooseServices(index)}>
										<i className={styles.actionIcon}/>
										<div className={styles.actionTitle}>Добавить услугу</div>
									</div>
									{clientsArr.length === 1
										? (
											<div className={styles.action} data-action={'add'} onClick={() => handleAddClient(index + 1)}>
												<i className={styles.actionIcon}/>
												<div className={styles.actionTitle}>Добавить гостя</div>
											</div>
										) : (
											<div className={styles.action} data-action={'delete'} onClick={() => handleRemoveClient(index)}>
												<i className={styles.actionIcon}/>
												<div className={styles.actionTitle}>Удалить гостя</div>
											</div>
										)
									}
								</div>
							</div>;
						})}
					</div>
				</Step>
				<Step name={'date'} title={'Выберите дату'}>
					<div className={styles.datePicker} onClick={showDayPicker}>
						<span className={styles.date}>{formattedDate}</span>
						<div className={styles.pickerContainer}>
							<Arrow direction={isDatePickerActive ? 'down' : 'right'}/>
							<Popup
								active={isDatePickerActive}
								onClose={hideDayPicker}
							>
								<div className={styles.dayPicker}>
									<DayPicker
										initialMonth={date ? new Date(date) : new Date()}
										selectedDays={date ? new Date(date) : new Date()}
										localeUtils={MomentLocaleUtils}
										locale={'ru'}
										modifiers={dayPickerModifiers}
										modifiersStyles={dayPickerModifiersStyles}
										onDayClick={handleDayClick}
										disabledDays={[
											{
												after: uppedDateLimit,
												before: new Date(),
											},
										]}
									/>
								</div>
							</Popup>
						</div>
					</div>
				</Step>
				<Step name={'time'} title={'Выберите время'}>
					<div className={styles.stepContent}>
						<div className={styles.timeContainer}>
							{availableTimeLoading ? (<Loader/>) : TimeComponent}
						</div>
					</div>
				</Step>
				<Step name={'comment'} title={'Оставьте комментарий'}>
					<div className={styles.stepContent}>
						<div className={classNames([styles.commentContainer, { [styles.error]: commentTooLong }])}>
							<textarea
								className={styles.comment}
								value={comment}
								onChange={handleChangeComment}
							/>
							<div className={styles.commentLength}>{comment.length} / 140</div>
						</div>
					</div>
				</Step>
			</div>
			<div className={styles.actions}>
				<Button disabled={submitDisabled} onClick={onSubmit}>Продолжить</Button>
			</div>
		</div>
	);
}

export default React.memo(AppointmentForm);
