import { combineReducers } from 'redux';
import AppointmentReducer from './AppointmentReducer';
import DataReducer from '@/store/reducers/DataReducer';
import ProfileReducer from '@/store/reducers/ProfileReducer';

const reducer = combineReducers({
	appointment: AppointmentReducer,
	data: DataReducer,
	profile: ProfileReducer,
});

export default reducer;
