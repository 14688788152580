import React from 'react';
import { useSelector } from 'react-redux';

import { getProfile } from '@/store/selectors';
import Popup from '../Popup/Popup';
import AccountPreview from '../AccountPreview/AccountPreview';
import Avatar from '../Avatar/Avatar';

import Arrow from '../Arrow/Arrow';
import styles from './Account.module.scss';

function Account({ onClick, previewActive, onClosePreview, onClickProfile, onClickSignOut, onClickAppointment }) {

	const user = useSelector(getProfile);
	const name = user ? [user.firstName, user.lastName].filter((f) => f).join(' ') : '';

	const handleClick = (e) => {
		e.stopPropagation();
		onClick();
	};

	return (
		<div className={styles.account} onClick={handleClick}>
			<Avatar className={styles.avatar} user={user}/>
			<div className={styles.accountText}>{user ? name : 'Войти'}</div>
			<div className={styles.previewContainer}>
				<Arrow className={styles.previewArrow} direction={previewActive ? 'down' : 'right'}/>
				<Popup
					active={previewActive}
					onClose={onClosePreview}>
					<AccountPreview
						onClickProfile={onClickProfile}
						onClickSignOut={onClickSignOut}
						onClickAppointment={onClickAppointment}
						user={user}
					/>
				</Popup>
			</div>
		</div>
	);
}

export default Account;
