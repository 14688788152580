import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';

import closest from '../../utils/closest';

import styles from './Popup.module.scss';


function Popup({ active, children, className, backDropClassName, onClose }) {

	const container = useRef();

	const handleClose = useCallback((e) => {
		if (container.current) {
			const onContent = closest(e.target, (el) => {
				return el === container.current;
			});
			if (!onContent) {
				onClose();
			}
		}
	}, [onClose, active]);

	useEffect(() => {
		if (active) {
			document.addEventListener('click', handleClose);
		} else {
			document.removeEventListener('click', handleClose);
		}
		return () => {
			document.removeEventListener('click', handleClose);
		};
	}, [active, handleClose]);

	if (!active) {
		return null;
	}

	return (
		<>
			<div
				ref={container}
				className={classNames([styles.content, className])}
				onClick={(e) => e.stopPropagation()}
			>
				<i className={styles.exit} onClick={() => {
					onClose();
				}}/>
				{children}
			</div>
			<div className={classNames([styles.backDrop, backDropClassName])}/>
		</>
	);
}

export default Popup;
