import React from 'react';

import MenuItem from '../MenuItem/MenuItem';
import Menu from '../Menu/Menu';

import styles from './MobileMenu.module.css';
import logo from '@/assets/logo/logo.svg';

function MobileMenu({ topMenu, bottomMenu, onClose }) {
	return (
		<div className={styles.container}>
			<div className={styles.mainLogoContainer}>
				<img className={styles.mainLogo} src={logo} alt="icon"/>
			</div>
			<Menu place={'header'}>
				{topMenu.map((el, index) => (
					<MenuItem key={index} item={el} onClick={onClose}/>
				))}
			</Menu>

			<Menu place={'extra'}>
				{bottomMenu.map((el, index) => (
					<MenuItem key={index} item={el} onClick={onClose}/>
				))}
			</Menu>
		</div>);
}

export default MobileMenu;
