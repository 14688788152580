export class Storage {

	static prefix = '@palchiki-site';

	static composeKey(key) {
		return `${Storage.prefix}:${key}`;
	}

	static get isDefined() {
		return typeof localStorage !== 'undefined';
	}

	static setItem(key, item, exp = null) {
		if (Storage.isDefined) {
			localStorage.setItem(Storage.composeKey(key), JSON.stringify({
				value: item,
				exp,
			}));
		}
	}

	static getItem(key) {
		if (Storage.isDefined) {
			const saved = localStorage.getItem(Storage.composeKey(key));
			if (!saved) {
				return null;
			}
			const item = JSON.parse(saved);
			if (item.exp && item.exp < Date.now()) {
				return null;
			}
			return item.value;
		}
		return null;
	}

	static deleteItem(key) {
		if (Storage.isDefined) {
			localStorage.removeItem(Storage.composeKey(key));
		}
	}

}
