import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Tab, Tabs } from '../Tabs/Tabs';
import ServiceInfo from '../ServiceInfo/ServiceInfo';
import { getActiveServices } from '@/store/selectors';
import Slider from '../Slider/Slider';
import Arrow from '../Arrow/Arrow';
import chunk from '../../utils/chunk';

import styles from './MainMenu.module.scss';
import AdditionalServicesActive from '../../assets/services-icons-no-box/additional-active.svg';
import DesignsActive from '../../assets/services-icons-no-box/designs-active.svg';
import HealthActive from '../../assets/services-icons-no-box/health-active.svg';
import PedicureActive from '../../assets/services-icons-no-box/pedicure-active.svg';
import ManicureActive from '../../assets/services-icons-no-box/manicure-active.svg';
import ComplexActive from '../../assets/services-icons-no-box/complex-active.svg';
import BrowsActive from '../../assets/services-icons-no-box/brows-active.svg';
import AdditionalServices from '../../assets/services-icons-no-box/additional.svg';
import Designs from '../../assets/services-icons-no-box/designs.svg';
import Health from '../../assets/services-icons-no-box/health.svg';
import Pedicure from '../../assets/services-icons-no-box/pedicure.svg';
import Complex from '../../assets/services-icons-no-box/complex.svg';
import Manicure from '../../assets/services-icons-no-box/manicure.svg';
import Brows from '../../assets/services-icons-no-box/brows.svg';
import { setAppointmentServicesAction } from '@/store/actions';

const categories = [
	{
		id: 3,
		name: 'complex',
		img: Complex,
		activeImg: ComplexActive,
		title: 'комплекс',
	},
	{
		id: 1,
		name: 'manicure',
		img: Manicure,
		activeImg: ManicureActive,
		title: 'маникюр',
	},
	{
		id: 2,
		name: 'pedicure',
		img: Pedicure,
		activeImg: PedicureActive,
		title: 'педикюр',
	},
	{
		id: 0,
		name: 'health',
		img: Health,
		activeImg: HealthActive,
		title: 'здоровье и уход',
		subMenu: [
			{
				id: 4,
				title: 'комплексный уход',
			},
			{
				id: 5,
				title: 'Увлажнение и питание',
			},
			{
				id: 6,
				title: 'укрепление ногтей',
			},
			{
				id: 7,
				title: 'очищение',
			},
		],
	},
	{
		id: 9,
		name: 'designs',
		img: Designs,
		activeImg: DesignsActive,
		title: 'дизайны',
	},
	{
		id: 8,
		name: 'additionalServices',
		img: AdditionalServices,
		activeImg: AdditionalServicesActive,
		title: 'дополнительные услуги',
	},
	{
		id: 10,
		name: 'brows',
		img: Brows,
		activeImg: BrowsActive,
		title: 'брови и ресницы',
	},
];

const categoriesShort = [
	{
		id: 1,
		name: 'manicure',
		img: Manicure,
		activeImg: ManicureActive,
		title: 'маникюр',
	},
	{
		id: 2,
		name: 'pedicure',
		img: Pedicure,
		activeImg: PedicureActive,
		title: 'педикюр',
	},
	{
		id: 3,
		name: 'complex',
		img: Complex,
		activeImg: ComplexActive,
		title: 'комплекс',
	},
];

function MainMenu(props) {

	const { defaultCategory = 3, onChangeCategory } = props;

	const servicesContainerRef = useRef(null);
	const disableSlideTransition = useRef(false);
	const dispatch = useDispatch();
	const allServices = useSelector(getActiveServices);
	const menuItems = props.onlyMainCategories ? categoriesShort : categories;
	const [activeMenuItemId, setActiveMenuItemId] = useState(defaultCategory);
	const [activeSubMenuItemId, setActiveSubMenuItemId] = useState(4);
	const [page, setPage] = useState(0);
	const [servicesWidth, setServicesWidth] = useState(0);
	const activeMenuItem = useMemo(() => {
		return menuItems.find((item) => item.id === activeMenuItemId);
	}, [menuItems, activeMenuItemId]);

	const handleMenuChange = useCallback((id) => {
		disableSlideTransition.current = true;
		onChangeCategory?.(id);
		setPage(0);
		setActiveMenuItemId(id);
		disableSlideTransition.current = false;
	}, [onChangeCategory]);

	const handleSelectService = useCallback((serviceId) => {
		dispatch(setAppointmentServicesAction(0, [{ id: serviceId, amount: 1 }]));
	}, [dispatch]);

	const handleSubMenuChange = useCallback((id) => {
		disableSlideTransition.current = true;
		setPage(0);
		setActiveSubMenuItemId(id);
		disableSlideTransition.current = false;
	}, []);

	const activeServices = useMemo(() => {
		return allServices.filter((service) => {
			const siteType = service.meta.templateVars.pageVars.type;
			return (siteType === activeMenuItem.id && !activeMenuItem.subMenu)
				|| (siteType === activeSubMenuItemId && activeMenuItem.subMenu);
		});
	}, [allServices, activeMenuItem, activeSubMenuItemId]);

	const activeServicesChunks = useMemo(() => {
		return chunk(activeServices, 3);
	}, [activeServices]);

	const activeServicesPages = useMemo(() => {
		return activeServicesChunks.map((page, index) => {
			return <div key={index} className={styles.servicesPage}>
				{page.map((service) => {
					return (
						<div key={service.id} className={styles.servicesContainer}>
							<ServiceInfo className={styles.serviceInfo} service={service} onSelect={handleSelectService}/>
						</div>
					);
				})}
			</div>;
		});
	}, [activeServicesChunks, handleSelectService]);

	const isFirstPage = page === 0;

	const isLastPage = page >= activeServicesChunks.length - 1;

	const prevPage = useCallback(() => {
		if (isFirstPage) {
			return;
		}
		setPage((page) => page - 1);
	}, [isFirstPage]);

	const nextPage = useCallback(() => {
		if (isLastPage) {
			return;
		}
		setPage((page) => page + 1);
	}, [isLastPage]);

	const handleWindowResize = useCallback(() => {
		Promise.resolve().then(() => {
			const width = servicesContainerRef.current.clientWidth;
			setServicesWidth(width);
		});
	}, []);

	useEffect(() => {
		if (servicesContainerRef.current) {
			const width = servicesContainerRef.current.clientWidth;
			setServicesWidth(width);
			if (typeof window !== 'undefined') {
				window.addEventListener('resize', handleWindowResize, { passive: true });
			}
			return () => {
				if (typeof window !== 'undefined') {
					window.removeEventListener('resize', handleWindowResize);
				}
			};
		}
	}, [handleWindowResize]);

	const onMobile = servicesWidth <= 428;
	const servicesSliderWidth = onMobile
		? servicesWidth
		: Math.min(Math.max(servicesWidth, 1024 - 60), 1200 - 60);

	return (
		<div className={styles.container} ref={servicesContainerRef} data-block={'services'}>
			<Tabs
				selected={activeMenuItemId}
				onChange={handleMenuChange}
				tabsClassName={styles.mainMenuBox}
			>
				{menuItems.map((value) => {
					const imageSrc = (value.id === activeMenuItemId)
						? value.activeImg
						: value.img;
					return <Tab
						selectedClassName={styles.active}
						className={styles.menuBox}
						key={value.id}
						value={value.id}
					>
						<div className={styles.menuItem}>
							{imageSrc ? <img src={imageSrc} className={styles.imgContainer} alt={value.title}/> :
								<div className={styles.imgPlaceholder}/>}
							<span className={styles.menuTitle}>{value.title}</span>
						</div>
					</Tab>;
				})}
			</Tabs>

			{!!activeMenuItem.subMenu && <div className={styles.subMenuContainer}>
				{activeMenuItem.subMenu.map((value, index) => {
					const subMenuClass = classNames({
						[styles.contentText]: true,
						[styles.contentTextHover]: activeSubMenuItemId === value.id,
					});

					return <div
						className={styles.contentBox}
						key={index}
						onClick={() => handleSubMenuChange(value.id)}>
						<span className={subMenuClass}>{value.title}</span>
					</div>;
				})}
			</div>}

			<div className={styles.menuWrapper}>
				<div className={styles.arrows}>
					{!isFirstPage && <Arrow
						onClick={prevPage}
						className={styles.arrowLeft}
						bigOne
						direction={'left'}
					/>}
					{!isLastPage && <Arrow
						onClick={nextPage}
						className={styles.arrowRight}
						bigOne
						direction={'right'}
					/>}
				</div>
				<div className={styles.menuContainer}>
					{onMobile ? (
						activeServicesPages
					) : (
						<Slider
							itemWidth={servicesSliderWidth}
							page={page}
							onPageChange={setPage}
							disableTransition={disableSlideTransition.current}
						>
							{activeServicesPages}
						</Slider>
					)}
				</div>
			</div>
		</div>
	);
}

export default MainMenu;
