import React, { useCallback, useEffect, useState } from 'react';
import classes from './Cookie.module.scss';
import { Storage } from '@/utils/storage';
import Button from '@/components/Button/Button';
import SmartLink from '@/components/SmartLink';

export const Cookie = () => {

	const [showCookie, setShowCookie] = useState(false);

	useEffect(() => {
		const acceptedCookie = Storage.getItem('cookieok');
		setShowCookie(!acceptedCookie);
	}, []);

	const accept = useCallback(() => {
		Storage.setItem('cookieok', 1);
		setShowCookie(false);
	}, []);

	if (!showCookie) {
		return null;
	}

	return (
		<div className={classes.container}>
			<div className={classes.constrains}>
				<div className={classes.text}>
					Мы используем cookie-файлы для наилучшего представления нашего сайта. <br/>
					Цели использования файлов cookie определены в <SmartLink target={'_blank'} href={'/personal'}>Политике
					обработки персональных данных.</SmartLink><br/>
					Если вы согласны и дальше получать файлы cookie, пожалуйста, нажмите кнопку «Принять»
				</div>
				<Button shadowColor={'yellow'} className={classes.button} onClick={accept}>
					Принять
				</Button>
			</div>
		</div>
	);
};
