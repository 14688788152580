import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import formatPhone from '../../utils/formatPhone';

import styles from './SalonsList.module.scss';
import { getActiveSalons, getSalons } from '@/store/selectors';
import chunk from '@/utils/chunk';


function SalonsListItem({ salon, onSelect }) {
	const meta = salon.meta;
	const linkToSalon = meta?.fullPath ?? null;
	const vars = meta?.templateVars?.pageVars;
	const name = vars?.salonName;
	const address = vars?.salonAddress;
	const businessHours = vars?.businessHours;
	const phone = vars?.salonPhones?.[0]?.phone ?? '';
	const options = vars.options;

	return <div className={styles.salonContainer} key={salon.id}>
		<span className={styles.salonName}>{name}</span>
		<span className={classNames([styles.salonText, styles.salonAddress])}>{address}</span>
		<span className={classNames([styles.salonText, styles.salonContacts])}>
			<a href={'tel:+' + phone} className={styles.salonPhone}>{formatPhone(phone)}</a>
			<span> | </span>
			<span className={styles.salonBusinessHours}>Ежедневно {businessHours}</span>
		</span>
		<div className={styles.salonTextLinkContainer}>
			<div className={styles.salonTextLinkFix}>
				<Link to={linkToSalon} className={styles.salonTextLink}>Подробнее</Link>
				<Link
					onClick={() => onSelect(salon.id)}
					className={styles.salonTextLink}
					to={'#appointment'}
				>
					Выбрать
				</Link>
			</div>
			<div className={styles.salonOptions}>
				<i
					data-icon={'kids'}
					title={'Детский уголок'}
					className={classNames([styles.salonOption, { [styles.salonOptionActive]: options?.hasWifi }])}
				/>
				<i
					data-icon={'coffee'}
					title={'Чай / Кофе'}
					className={classNames([styles.salonOption, { [styles.salonOptionActive]: options?.hasCoffee }])}
				/>
				<i
					data-icon={'ramp'}
					title={'Пандус для инвалидов'}
					className={classNames([styles.salonOption, { [styles.salonOptionActive]: options?.hasRamp }])}
				/>
				<i
					data-icon={'parking'}
					title={'Городская парковка'}
					className={classNames([styles.salonOption, { [styles.salonOptionActive]: options?.hasParking }])}
				/>
				<i
					data-icon={'wifi'}
					title={'Бесплатный WiFi'}
					className={classNames([styles.salonOption, { [styles.salonOptionActive]: options?.hasWifi }])}
				/>
			</div>
		</div>
	</div>;
}

function SalonsList(props) {
	const { perPage = 8, onSelectSalon, controls = true } = props;
	const salons = useSelector(controls ? getActiveSalons : getSalons);
	const [inputValue, setInputValue] = useState('');
	const [filterValue, setFilterValue] = useState('');
	const [page, setPage] = useState(0);

	const searchSalon = useCallback((e) => {
		e.preventDefault();
		setFilterValue(inputValue);
		setPage(0);
	}, [inputValue]);

	const setPageValue = useCallback((page) => {
		setPage(page);
	}, []);

	const filteredSalons = useMemo(() => {
		if (!controls) {
			return salons;
		}
		const lowerFilterValue = filterValue.toLowerCase();
		return salons.filter((s) => {
			const vars = s?.meta?.templateVars?.pageVars;
			return vars?.salonName?.toLowerCase()?.includes(lowerFilterValue) || vars.salonAddress?.toLowerCase()?.includes(lowerFilterValue);
		});
	}, [salons, filterValue, controls]);

	const salonsPages = useMemo(() => {
		if (!controls) {
			return [filteredSalons];
		}
		return chunk(filteredSalons, perPage);
	}, [filteredSalons, perPage, controls]);

	return (
		<div className={styles.container}>
			{controls && (
				<div className={styles.salonSearch}>
					<form className={styles.form}>
						<input
							id="search"
							type="text"
							className={styles.salonForm}
							value={inputValue}
							onChange={(e) => setInputValue(e.target.value)}/>
						<button
							type="submit"
							className={styles.salonFormBtn}
							onClick={searchSalon}
						>
							Найти
						</button>
					</form>

				</div>
			)}

			<div className={styles.salons}>
				{salonsPages[page]?.map((salon) => (
					<SalonsListItem key={salon.id} salon={salon} onSelect={onSelectSalon}/>
				))}
				{salonsPages.length === 0 && (
					<div>Салоны не найдены</div>
				)}
			</div>

			{salonsPages.length > 1 && <div className={styles.paginator}>
				{salonsPages.map((_, index) =>
					<div className={styles.paginatorContainer} key={index}>
						<span
							className={(page === index) ? styles.paginatorActivePage : styles.paginatorText}
							onClick={() => setPageValue(index)}
						>
							{index + 1}
						</span>
					</div>,
				)}
			</div>}
		</div>
	);
}

export default SalonsList;
