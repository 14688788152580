import React, { useCallback, useMemo, useState } from 'react';
import Modal from '@/components/Modal';
import ShadowInput from '@/components/ShadowInput/ShadowInput';
import Button from '@/components/Button/Button';
import styles from './NotificationDialog.module.scss';

export const NotificationDialog = (props) => {
	const { isOpen, onClose, onSubmit } = props;
	const [value, setValue] = useState('24');

	const error = useMemo(() => {
		if (value === '') {
			return [true];
		}
		if (!/^[0-9]+$/.test(value)) {
			return [true];
		}
		if (Number(value) > 24) {
			return [true, 'Максимум - 24 часа'];
		}
		if (Number(value) < 1) {
			return [true, 'Минимум 1 час'];
		}
		return [false];
	}, [value]);

	const handleSubmit = useCallback((e) => {
		e.preventDefault();
		if (error[0]) {
			return;
		}
		onSubmit(Number(value));
	}, [onSubmit, value, error]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			className={styles.modal}
			title={'Напомнить о записи'}
			center
		>
			<form onSubmit={handleSubmit} className={styles.container}>
				<div className={styles.inputContainer}>
					<div className={styles.prefix}>
						за
					</div>
					<ShadowInput
						error={error[0]}
						value={value}
						onChange={setValue}
						inputMode={'numeric'}
						className={styles.input}
						tooltip={error[1]}
					/>
					<div className={styles.suffix}>
						часа
					</div>
				</div>
				<Button type={'submit'}>
					Ок
				</Button>
			</form>
		</Modal>
	);
};
