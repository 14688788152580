import styles from './AppointmentForm.module.scss';
import classNames from 'classnames';
import React from 'react';

function Time({ time, onSelect, selected }) {
	return <div className={styles.slotsContainer}>
		{time.map((slot) => {
			const slotClassName = classNames([styles.slot, {
				[styles.free]: slot.isFree,
				[styles.selected]: slot.time === selected,
			}]);
			return <div
				key={slot.time}
				onClick={() => {
					if (slot.isFree) {
						onSelect(slot.time);
					}
				}}
				className={slotClassName}
			>
				{slot.time}
			</div>;
		})}
	</div>;
}

export default Time;
