import React from 'react';
import styles from './Cashback.module.scss';
import classNames from 'classnames';
import useFetch from '@/hooks/useFetch';
import Loader from '@/components/Loader';
import { fetchCashback } from '@/api';


const levels = [
	{
		name: 'BASIC',
	},
	{
		name: 'SILVER',
	},
	{
		name: 'GOLD',
	},
];

const Cashback = () => {

	const { response, isLoading } = useFetch(fetchCashback);

	if (isLoading || !response.result) {
		return <div className={styles.root}><Loader/></div>;
	}
	const progress = response.result.progress;
	const currentLevel = progress.currentLevel;
	const levelProgressData = progress.levels[currentLevel - 1];

	const level2PercentServices = Math.min(Math.round(progress.levels[1].services.actualValue * 100 / progress.levels[1].services.desiredValue), 100);
	const level2PercentGoods = Math.min(Math.round(progress.levels[1].goods.actualValue * 100 / progress.levels[1].goods.desiredValue), 100);

	const level3ercentServices = currentLevel < 2 ? 0 : Math.min(Math.round(progress.levels[2].services.actualValue * 100 / progress.levels[2].services.desiredValue), 100);
	const level3PercentGoods = currentLevel < 2 ? 0 : Math.min(Math.round(progress.levels[2].goods.actualValue * 100 / progress.levels[2].goods.desiredValue), 100);

	const servicesBackgroundWidth = (level2PercentServices + (level2PercentServices === 100 ? level3ercentServices : 0)) / 2;
	const goodsBackgroundWidth = (level2PercentGoods + (level2PercentGoods === 100 ? level3PercentGoods : 0)) / 2;

	return (
		<div className={styles.root}>
			<div className={styles.cashbackAmountContainer}>
				<div className={styles.stars}>
					<div className={classNames([styles.cashbackAmount, styles.circleBorderContainer])}>
						<div className={classNames([styles.cashbackAmountContent, styles.circleBorderContent])}>
							<div>Кешбэк</div>
							<div className={styles.amountNumber}>{response.result.amount}</div>
							<div>рублей</div>
						</div>
					</div>
				</div>
				<div className={styles.currentLevelContainer}>
					<div className={styles.currentLevelTitle}>
						{levels[currentLevel - 1].name}
					</div>
					<div className={styles.currentLevelValue}>
						Кешбэк {levelProgressData.cashbackAmount}%.
					</div>
				</div>
			</div>
			<div className={styles.progressContainer}>
				<div className={styles.progressPart}>
					<div className={styles.progressPartTitle}>
						Расходы на услуги
					</div>
					<div className={styles.progressLine}>
						<div className={styles.progressBackground} style={{ width: `${servicesBackgroundWidth}%` }}/>
						<div className={classNames([styles.circle, currentLevel === 1 && styles.active])}>
							<span>
								{progress.levels[0].cashbackAmount}
							</span>
							<span className={styles.progressPercent}>
								%
							</span>
						</div>
						<div className={classNames([styles.circle, currentLevel === 2 && styles.active])}>
							<span>
								{progress.levels[1].cashbackAmount}
							</span>
							<span className={styles.progressPercent}>
								%
							</span>
						</div>
						<div className={classNames([styles.circle, currentLevel === 3 && styles.active])}>
							<span>
								{progress.levels[2].cashbackAmount}
							</span>
							<span className={styles.progressPercent}>
								%
							</span>
						</div>
						<div className={styles.decorationBorder}/>
					</div>
					<div>
						<div className={styles.progressInfo}>
							За последние {progress.levels[1].services.daysRestriction} дней
							потрачено {progress.levels[1].services.actualValue}₽{'\u00A0'}/{'\u00A0'}{progress.levels[1].services.desiredValue}₽
						</div>
						<div className={styles.progressInfo}>
							За последние {progress.levels[2].services.daysRestriction} дней
							потрачено {progress.levels[2].services.actualValue}₽{'\u00A0'}/{'\u00A0'}{progress.levels[2].services.desiredValue}₽
						</div>
					</div>
				</div>

				<div className={styles.progressPart}>
					<div className={styles.progressPartTitle}>
						Расходы на товары
					</div>
					<div className={styles.progressLine}>
						<div className={styles.progressBackground} style={{ width: `${goodsBackgroundWidth}%` }}/>
						<div className={classNames([styles.circle, currentLevel === 1 && styles.active])}>
							<span>
								{progress.levels[0].cashbackAmount}
							</span>
							<span className={styles.progressPercent}>
								%
							</span>
						</div>
						<div className={classNames([styles.circle, currentLevel === 2 && styles.active])}>
							<span>
								{progress.levels[1].cashbackAmount}
							</span>
							<span className={styles.progressPercent}>
								%
							</span>
						</div>
						<div className={classNames([styles.circle, currentLevel === 3 && styles.active])}>
							<span>
								{progress.levels[2].cashbackAmount}
							</span>
							<span className={styles.progressPercent}>
								%
							</span>
						</div>
						<div className={styles.decorationBorder}/>
					</div>
					<div>
						<div className={styles.progressInfo}>
							За последние {progress.levels[1].goods.daysRestriction} дней
							потрачено {progress.levels[1].goods.actualValue}₽{'\u00A0'}/{'\u00A0'}{progress.levels[1].goods.desiredValue}₽
						</div>
						<div className={styles.progressInfo}>
							За последние {progress.levels[2].goods.daysRestriction} дней
							потрачено {progress.levels[2].goods.actualValue}₽{'\u00A0'}/{'\u00A0'}{progress.levels[2].goods.desiredValue}₽
						</div>
					</div>
				</div>
			</div>
			<div className={styles.levelsDescr}>
				<div className={styles.level}>
					<div className={styles.levelTitle}>
						<div className={classNames([styles.circle, styles.checkmark, currentLevel === 1 && styles.active])}>
							<i width={16} height={16}/>
						</div>
						<div>
							<span className={styles.levelName}>
								{levels[0].name}
							</span>
							<span className={styles.levelPercent}>
								({progress.levels[0].cashbackAmount}%)
							</span>
						</div>
					</div>
					<div className={styles.levelDescr}>
						Базовый уровень. Начисление происходит с первого визита.
					</div>
				</div>
				<div className={styles.level}>
					<div className={styles.levelTitle}>
						<div className={classNames([styles.circle, styles.checkmark, currentLevel === 2 && styles.active])}>
							<i width={16} height={16}/>
						</div>
						<div>
							<span className={styles.levelName}>
								{levels[1].name}
							</span>
							<span className={styles.levelPercent}>
								({progress.levels[1].cashbackAmount}%)
							</span>
						</div>
					</div>
					<div className={styles.levelDescr}>
						<div>
							<strong>Кешбэк от суммы чека {progress.levels[1].cashbackAmount}%.<br/></strong>
							Переход на данный уровень происходит автоматически
							при сумме расходов в
							течение <strong>{progress.levels[1].services.daysRestriction} дней</strong>:<br/>
							• {progress.levels[1].services.desiredValue}₽ на услуги<br/>
							• {progress.levels[1].goods.desiredValue}₽ на товары
						</div>
					</div>
				</div>
				<div className={styles.level}>
					<div className={styles.levelTitle}>
						<div className={classNames([styles.circle, styles.checkmark, currentLevel === 3 && styles.active])}>
						</div>
						<div>
							<span className={styles.levelName}>
								{levels[2].name}
							</span>
							<span className={styles.levelPercent}>
								({progress.levels[2].cashbackAmount}%)
							</span>
						</div>
					</div>
					<div className={styles.levelDescr}>
						<div>
							<strong>Кешбэк от суммы чека {progress.levels[2].cashbackAmount}%.<br/></strong>
							Переход на данный уровень происходит автоматически
							при сумме расходов в
							течение <strong>{progress.levels[2].services.daysRestriction} дней</strong>:<br/>
							• {progress.levels[2].services.desiredValue}₽ на услуги<br/>
							• {progress.levels[2].goods.desiredValue}₽ на товары
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cashback;
