import React, { memo, useCallback, useRef, useState } from 'react';
import QuickSignUpForm from '@/components/Forms/QuickSignUpForm';
import { confirmPhone, quickSignUp } from '@/api';
import Modal from '@/components/Modal';
import ConfirmPhoneForm from '@/components/Forms/ConfirmPhoneForm';
import StatusModal from '@/components/StatusModal/StatusModal';
import { useSelector } from 'react-redux';
import { getSelectedCityId } from '@/store/selectors';

function QuickSignUpController({ onSubmit, onCancel }) {
	const [stage, setStage] = useState('form'); // form, confirm
	const [submitError, setSubmitError] = useState(null);
	const [isSendingCode, setSendingCode] = useState(false);
	const [isConfirmingPhone, setConfirmingPhone] = useState(false);
	const [codeExpiresAt, setCodeExpiresAt] = useState(null);
	const formData = useRef();
	const cityId = useSelector(getSelectedCityId);

	const sendConfirmCode = useCallback(async () => {
		setSendingCode(true);
		try {
			const codeData = await confirmPhone({ phone: formData.current.phone, purpose: 'sign-up' });
			setCodeExpiresAt(codeData.expires_at);
		} catch (e) {
			setSubmitError(e.message);
		} finally {
			setSendingCode(false);
		}
	}, []);

	const handleFormSubmit = useCallback(async (data) => {
		data.phone = data.phone.replace(/^8/, '7'); // fix form autocomplete
		formData.current = data;
		await sendConfirmCode();
		setStage('confirm');
	}, [sendConfirmCode]);

	const handleResend = useCallback(async () => {
		if (formData.current) {
			await sendConfirmCode();
			setStage('confirm');
		}
	}, [sendConfirmCode]);

	const handleConfirm = useCallback(async ({ code }) => {
		if (!formData.current) {
			return;
		}
		const { phone, firstName, lastName } = formData.current;

		setConfirmingPhone(true);
		try {
			setConfirmingPhone(false);
			const signUpToken = await quickSignUp({
				firstName,
				lastName,
				phone,
				defaultCityId: cityId,
				code,
			});
			onSubmit(signUpToken);
		} catch (e) {
			setSubmitError(e.message);
			setConfirmingPhone(false);
		}
	}, [cityId, onSubmit]);

	let content;

	switch (stage) {
		case 'form':
			content = (
				<Modal
					isOpen
					onClose={onCancel}
					back={onCancel}
				>
					<QuickSignUpForm
						data={formData.current}
						onSubmit={handleFormSubmit}
						isSubmitting={isSendingCode}
					/>
				</Modal>
			);
			break;
		case 'confirm':
			content = (
				<Modal
					isOpen
					back={() => setStage('form')}
					onClose={onCancel}
				>
					<ConfirmPhoneForm
						onResend={handleResend}
						onSubmit={handleConfirm}
						isSubmitting={isConfirmingPhone}
						reclaimAt={codeExpiresAt}
					/>
				</Modal>
			);
			break;
	}

	return <>
		{content}
		<StatusModal
			isOpen={submitError !== null}
			modalText={submitError}
			status={'error'}
			buttonText={'ОК'}
			onClose={() => setSubmitError(null)}
			onButtonClick={() => setSubmitError(null)}
		/>
	</>;
}

export default memo(QuickSignUpController);
