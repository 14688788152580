import styles from '@/components/AppointmentForm/AppointmentForm.module.scss';
import React from 'react';

function Step({ name, title, children }) {
	return <div className={styles.step} data-step={name}>
		<i className={styles.stepIcon}/>
		<div className={styles.stepInfo}>
			<div className={styles.stepTitle}>
				{title}:
			</div>
			<div className={styles.stepContent}>
				{children}
			</div>
		</div>
	</div>;
}

export default Step;
