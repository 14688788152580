import React from 'react';
import classNames from 'classnames';

import Arrow from '../Arrow/Arrow';

import styles from './ServicesCategory.module.scss';

function ServicesCategory({ name, image, expanded, collapsed, isExpanded, onExpand, onCollapse }) {

	const containerClassName = classNames({
		[styles.container]: true,
		[styles.expanded]: isExpanded,
		[styles.withChildren]: (isExpanded && expanded.length) || (!isExpanded && collapsed.length),
	});

	const hasActiveChildren = !!((isExpanded && React.Children.count(expanded)) || (!isExpanded && React.Children.count(collapsed)));

	return (
		<div className={containerClassName}>
			<div className={styles.header} onClick={isExpanded ? onCollapse : onExpand}>
				<i className={styles.image} style={{ backgroundImage: `url(${image})` }}/>
				<div className={styles.headerText}>
					<span className={styles.name}>{name}</span>
					<Arrow
						className={styles.arrow}
						direction={isExpanded ? 'down' : 'right'}
					/>
				</div>
			</div>
			{hasActiveChildren && <div className={styles.children}>
				{isExpanded ? expanded : collapsed}
			</div>}
		</div>
	);
}

export default ServicesCategory;
