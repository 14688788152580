import React, { useCallback, useEffect, useRef, useState } from 'react';
import UserInfo from '../UserInfo/UserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Tab, Tabs } from '../Tabs/Tabs';
import styles from './UserProfile.module.scss';
import Gifts from '../Gifts/Gifts';
import ProfileEditForm from '../Forms/ProfileEditForm';
import Cashback from '@/components/Cashback/Cashback';
import { getProfile } from '@/store/selectors';
import { signOutAction } from '@/store/actions';
import { Visits } from '@/components/Visits/Visits';

function UserProfile() {

	const [activeTab, setActiveTab] = useState('gifts');
	const [reverseTabs, setReverseTabs] = useState(false);
	const servicesContainerRef = useRef(null);
	const profile = useSelector(getProfile);
	const history = useHistory();
	const dispatch = useDispatch();

	const handleResize = useCallback(() => {
		if (servicesContainerRef.current) {
			if (servicesContainerRef.current.clientWidth <= 428) {
				setReverseTabs(true);
			} else {
				setReverseTabs(false);
			}
		}
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', handleResize, { passive: true });
		}
		handleResize();
		return () => {
			if (typeof window !== 'undefined') {
				window.removeEventListener('resize', handleResize);
			}
		};
	}, [handleResize]);

	const handleSignOut = useCallback(() => {
		history.push('#');
		dispatch(signOutAction());
	}, [dispatch, history]);

	let Content;

	switch (activeTab) {
		default:
		case 'visits': {
			Content = (
				<>
					<Visits/>
				</>
			);
			break;
		}
		case 'gifts':
			Content = <Gifts/>;
			break;
		case 'cashback':
			Content = <Cashback/>;
			break;
		case 'personal':
			Content = <ProfileEditForm/>;
	}

	if (!profile) {
		return null;
	}

	return (
		<div className={styles.container} ref={servicesContainerRef}>
			<UserInfo user={profile}/>
			<Tabs
				reverseMove={reverseTabs}
				className={styles.tabsContainer}
				selected={activeTab}
				onChange={setActiveTab}
				tabsClassName={styles.tabs}
				indicatorClassName={styles.indicator}
			>
				<Tab className={styles.tabContainer} selectedClassName={styles.selected} value={'visits'}>
					<div className={styles.tab}>Мои записи {profile.appointmentsCount > 0 &&
						<sup>{profile.appointmentsCount}</sup>}</div>
				</Tab>
				<Tab className={styles.tabContainer} selectedClassName={styles.selected} value={'cashback'}>
					<div className={styles.tab}>Мой кешбэк</div>
				</Tab>
				<Tab className={styles.tabContainer} selectedClassName={styles.selected} value={'gifts'}>
					<div className={styles.tab}>Мои подарки</div>
				</Tab>
				<Tab className={styles.tabContainer} selectedClassName={styles.selected} value={'personal'}>
					<div className={styles.tab}>Личные данные</div>
				</Tab>
				<div className={styles.tabContainer} onClick={handleSignOut}>
					<div className={styles.tab}>
						Выход
					</div>
				</div>
			</Tabs>
			<div className={styles.content}>
				{Content}
			</div>
		</div>
	);
}


export default React.memo(UserProfile);
