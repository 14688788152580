import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCities, getSelectedCity } from '@/store/selectors';
import Cookies from 'js-cookie';

import Popup from '../Popup/Popup';
import CityPicker from '../CityPicker/CityPicker';
import Arrow from '../Arrow/Arrow';

import styles from './CurrentCity.module.scss';
import { setSelectedCityIdAction } from '@/store/actions';

export const CurrentCity = (props) => {
	const { onClick, active, onClosePicker } = props;
	const dispatch = useDispatch();
	const selectedCity = useSelector(getSelectedCity);
	const cities = useSelector(getCities);

	const handleSelect = useCallback((id) => {
		if (typeof document !== 'undefined') {
			Cookies.set('cityId', id);
		}
		dispatch(setSelectedCityIdAction(id));
		onClosePicker();
	}, [dispatch, onClosePicker]);

	const handleClick = (e) => {
		e.stopPropagation();
		onClick();
	};

	return (
		<div className={styles.container} onClick={handleClick}>
			<div className={styles.text}>
				<span className={styles.mobileText}>Ваш город </span>
				<span className={styles.cityNameContainer}>
					<span className={styles.cityName}>
						{selectedCity?.city?.name ?? '...'}
					</span>
					<Popup
						backDropClassName={styles.popupBackDrop}
						className={styles.popup}
						place={'headerNav'}
						active={active}
						onClose={onClosePicker}>
						<CityPicker
							cities={cities}
							selectedCityId={selectedCity?.city?.id ?? null}
							onSelectCity={handleSelect}/>
					</Popup>
				</span>
			</div>
			<Arrow className={styles.arrow} direction={(active) ? 'down' : 'right'}/>
		</div>
	);
};
