import React from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import getServiceTypeIcon from '../../utils/getServiceTypeIcon';

import Button from '../Button/Button';

import styles from './ServiceInfo.module.scss';
import parseHtml from '@/utils/htmlParserWithCustomLinks';

function ServiceInfo(props) {
	const {
		service,
		onSelect,
		minimal = false,
		noLink = false,
		asPageInfo = false,
		meta = service.meta,
		className,
	} = props;
	const pageVars = meta?.templateVars?.pageVars ?? null;
	const description = pageVars ? parseHtml(pageVars.description || '') : '';
	const altDescription = pageVars ? parseHtml(pageVars.altDescription || '') : '';
	const linkToServices = meta ? meta.fullPath : '';
	const history = useHistory();
	return (
		<div data-type={pageVars.type} data-id={service.id} className={classNames([styles.container, className])}>
			<div className={styles.info}>
				<div className={styles.name}>
					{asPageInfo ? (
						<h1 className={styles.title}>{meta ? meta.title : service.name}</h1>
					) : (
						<span className={styles.title}>{meta ? meta.title : service.name}</span>
					)}
					<span/>
					<span className={styles.typeIcon}>
						<img src={getServiceTypeIcon(service.type)} alt={''}/>
					</span>
				</div>
				{!minimal && <div className={styles.mainInfo}>
					<div className={styles.priceContainer}>
						<span className={styles.price}>{service.price}</span>
						<span className={styles.currency}>₽</span>
					</div>
					<div className={styles.durationContainer}>
						<i className={styles.durationIcon}/>
						<div className={styles.duration}>{`${service.duration} мин`}</div>
					</div>
				</div>}
				<div className={styles.descriptionContainer}>
					{(!!description && !asPageInfo) && <div className={classNames([styles.description, 'text-content'])}>
						{description}
					</div>}
					{(!!altDescription && asPageInfo) && <div className={classNames([styles.altDescription, 'text-content'])}>
						{altDescription}
					</div>}
					{!minimal && !noLink && <Link to={linkToServices} className={styles.linkToService}>
						Подробнее
					</Link>}
				</div>
			</div>
			<div className={styles.action}>
				{minimal ?
					<Button onClick={() => history.push(linkToServices)}>Подробнее</Button>
					:
					<Button
						onClick={() => {
							onSelect(service.id);
							history.push('#appointment');
						}}>
						Записаться</Button>
				}
			</div>
		</div>
	);
}

export default ServiceInfo;
