import React from 'react';
import classNames from 'classnames';

import formatPhone from '../../utils/formatPhone';

import styles from './SalonInfo.module.scss';
import Button from '@/components/Button/Button';

function SalonInfo(props) {

	const { salon, meta } = props;

	const vars = meta?.templateVars?.pageVars ?? {};

	const {
		options,
		salonName,
		salonAddress,
		salonPhones,
	} = vars;

	const hasOptions = Object.values(options).some((o) => !!o);

	return (
		<div className={styles.container} itemScope itemType={'http://schema.org/Organization'}>
			<div className={styles.addressContainer}>
				<i className={styles.icon}/>
				<div className={styles.info}>
					<div className={styles.name} itemProp="name">
						{salonName}
					</div>
					<div className={styles.address} itemProp="address">
						{salonAddress}
					</div>
				</div>
			</div>

			<div className={classNames([styles.props, styles.businessHours])}>
				<div className={styles.title}>
					Время работы
				</div>
				<div className={styles.value}>
					{salon.businessHours}
				</div>
			</div>
			<div className={classNames([styles.props, styles.contacts])}>
				<div className={styles.title}>
					Контакты
				</div>
				{salonPhones.map(({ phone }) => {
					return (
						<div key={phone} className={styles.value} itemProp="telephone">
							<a href={`tel:+${phone}`}>
								{formatPhone(phone)}
							</a>
						</div>
					);
				})}
			</div>
			<div className={classNames([styles.props, styles.callButton])}>
				<a href={`tel:+${salonPhones?.[0]?.phone}`}>
					<Button shadowColor={'yellow'}>
						Позвонить
					</Button>
				</a>
			</div>
			{hasOptions && <div className={classNames([styles.props, styles.options])}>
				<ul className={styles.value}>
					{options.hasKids && <li
						data-icon={'kids'}
						title={'Детский уголок'}
						className={classNames([styles.option, { [styles.active]: options.hasKids }])}
					>
						Детский уголок
					</li>}
					{options.hasCoffee && <li
						data-icon={'coffee'}
						title={'Чай / Кофе'}
						className={classNames([styles.option, { [styles.active]: options.hasCoffee }])}
					>
						Чай / Кофе
					</li>}
					{options.hasRamp && <li
						data-icon={'ramp'}
						title={'Пандус для инвалидов'}
						className={classNames([styles.option, { [styles.active]: options.hasRamp }])}
					>
						Пандус для инвалидов
					</li>}
					{options.hasParking && <li
						data-icon={'parking'}
						title={'Городская парковка'}
						className={classNames([styles.option, { [styles.active]: options.hasParking }])}
					>
						Городская парковка
					</li>}
					{options.hasWifi && <li
						data-icon={'wifi'}
						title={'Бесплатный WiFi'}
						className={classNames([styles.option, { [styles.active]: options.hasWifi }])}
					>
						Бесплатный WiFi
					</li>}
					{options.hasBrows && <li
						data-icon={'brows'}
						title={'Услуги бровиста'}
						className={classNames([styles.option, { [styles.active]: options.hasBrows }])}
					>
						Услуги бровиста
					</li>}
				</ul>
			</div>}
		</div>
	);
}

export default SalonInfo;
