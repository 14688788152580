import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import Back from '../Back';

import styles from './Modal.module.scss';

ReactModal.setAppElement('#root');

class Modal extends React.PureComponent {

	static defaultProps = {
		isOpen: false,
	};

	handleBackButton = (e) => {
		if (this.props.onClose && this.props.isOpen) {
			e.cancel();
			this.props.onClose();
		}
	};

	handleCloseClick = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	render() {
		return (
			<ReactModal
				overlayClassName={styles.overlay}
				className={classNames([styles.modal, { [styles.center]: this.props.center }])}
				isOpen={this.props.isOpen}
				shouldCloseOnOverlayClick={false}
			>
				<div className={styles.content}>
					<div className={classNames([this.props.className, styles.contentContainer])}>
						{this.props.title && <div className={styles.title}>{this.props.title}</div>}
						<i className={styles.closeButton} onClick={this.handleCloseClick}/>
						{this.props.back && <Back className={styles.back} onClick={this.props.back}/>}
						{this.props.children}
					</div>
				</div>
			</ReactModal>
		);
	}
}

export default Modal;
