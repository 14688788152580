import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import { signIn } from '@/api';
import { getIsAuthenticated } from '@/store/selectors';

import HashRouteModal from '../HashRouteModal/HashRouteModal';
import { RecoveryForm, SignInForm, SignUpForm } from '../Forms';
import UserProfile from '../UserProfile/UserProfile';
import ReviewForm from '../Forms/ReviewForm';
import { setTokenAction } from '@/store/actions';


class AuthController extends React.Component {

	protectedPaths = ['#profile', '#review'];

	state = {
		citiesPickerActive: false,
	};

	handleSignIn = async (token) => {
		this.props.onToken(token);
		const query = qs.parse(this.props.location.search.replace('?', ''));
		this.props.history.push('#' + (query.to || ''));
	};

	handleSignUp = async ({ phone, password }) => {
		try {
			const token = await signIn({ phone, password });
			this.props.onToken(token);
			this.props.history.push('#');
		} catch (e) {
			this.props.history.push('#signin');
		}
	};

	handleReviewSuccess = () => {
		this.props.history.push('#');
	};

	handleRecover = async () => {
		this.props.history.push('#');
	};

	protectPaths = () => {
		if (this.protectedPaths.includes(this.props.location.hash) && !this.props.isAuthenticated) {
			const destanation = this.props.location.hash.replace('#', '');
			this.props.history.replace(`?to=${destanation}#signin`);
		}
	};

	componentDidUpdate(prevProps) {
		if (prevProps.location.hash !== this.props.location.hash) {
			this.protectPaths();
		}
	}

	componentDidMount() {
		this.protectPaths();
	}

	render() {
		if (this.props.isAuthenticated) {
			return (
				<>
					<HashRouteModal
						hash={'#profile'}
						title={'Личный кабинет'}
					>
						<UserProfile/>
					</HashRouteModal>
					<HashRouteModal
						hash={'#review'}
						title={'Оставить отзыв'}
					>
						<ReviewForm onSuccess={this.handleReviewSuccess}/>
					</HashRouteModal>
				</>
			);
		} else {
			return (
				<>
					<HashRouteModal
						hash={'#signin'}
						title={'Вход'}
					>
						<SignInForm onSuccess={this.handleSignIn}/>
					</HashRouteModal>
					<HashRouteModal
						hash={'#signup'}
						back={'#signin'}
						title={'Регистрация'}
					>
						<SignUpForm onSuccess={this.handleSignUp}/>
					</HashRouteModal>
					<HashRouteModal
						hash={'#recover'}
						back={'#signin'}
						title={'Восстановление пароля'}
					>
						<RecoveryForm onSuccess={this.handleRecover}/>
					</HashRouteModal>
				</>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		isAuthenticated: getIsAuthenticated(state),
	};
};

const mapDispathToProps = (dispatch) => ({
	onToken: (token) => dispatch(setTokenAction(token)),
});

export default withRouter(connect(mapStateToProps, mapDispathToProps)(AuthController));
