import React from 'react';
import { Link, Route } from 'react-router-dom';
import { URLParser } from '@/utils/parseUrl';

function SmartLink({ path, ...rest }) {
	if (!path) {
		return <a {...rest}/>;
	}
	if (path.indexOf('tel:') === 0) {
		return (
			<a href={path} {...rest}/>
		);
	}

	const url = URLParser.parse(path);
	const hasExt = url.pathname && url.pathname.indexOf('.') !== -1;

	return <Route>
		{({ staticContext: context }) => {
			let host = '';
			if (context?.origin) {
				host = URLParser.parse(context.origin).hostname;
			} else if (typeof window !== 'undefined') {
				host = window.location.hostname;
			}

			if (hasExt || (url.hostname && url.hostname !== host)) {
				return (<a {...rest} href={path} target={'_blank'} rel="noopener noreferrer"/>);
			} else {
				const relativePath = path.replace(/^https?:\/\//, '').replace(url.hostname, '');
				return <Link {...rest} to={relativePath}/>;
			}
		}}
	</Route>;
}

export default SmartLink;
