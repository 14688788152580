import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import ShadowInput from '../ShadowInput/ShadowInput';
import Button from '../Button/Button';
import StatusModal from '../StatusModal/StatusModal';
import formStyles from './RecoveryForm.module.scss';
import { changePassword, checkClientIsRegistered, confirmPhone } from '@/api';

import styles from './Form.module.scss';
import { focusMaskStart, phoneInputMask } from '@/utils/masks';
import ConfirmPhoneForm from '@/components/Forms/ConfirmPhoneForm';
import useObject from '@/hooks/useObject';
import { BackContext } from '@/components/HashRouteModal/HashRouteModal';
import moment from 'moment';
import { conformToMask } from 'react-text-mask';

function RecoveryForm({ onSubmit }) {

	const [formError, setFormError] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [formData, setFormData] = useState({ phone: '' });

	const setFormField = useCallback((field, value) => {
		setFormData((data) => ({ ...data, [field]: value }));
	}, []);

	const handlePhoneInputBlur = useCallback((event) => {
		if (event.target.value.replace(/\D/g, '') === '7') {
			setFormField('phone', '');
		}
	}, [setFormField]);

	const handlePhoneInputChange = useCallback((value) => {
		setFormField('phone', value);
	}, [setFormField]);

	const handlePhoneInputPaste = useCallback((e) => {
		e.preventDefault();
		const value = e.clipboardData.getData('Text').replace(/\D/g, '');
		if (value[0] === '7') {
			const { conformedValue } = conformToMask('+' + value, phoneInputMask);
			setFormField('phone', conformedValue);
		} else if (value[0] === '8') {
			const { conformedValue } = conformToMask('+7' + value.slice(1), phoneInputMask);
			setFormField('phone', conformedValue);
		} else {
			const { conformedValue } = conformToMask(value, phoneInputMask);
			setFormField('phone', conformedValue);
		}
	}, []);

	const dismissErrorModal = useCallback(() => {
		setFormError(null);
	}, []);

	const handleSubmit = useCallback(async (event) => {

		event.preventDefault();

		const phone = formData.phone.replace(/\D/g, '');

		if (!phone) {
			setFormError('Не указан номер телефона');
			return;
		}

		setLoading(true);

		try {
			await checkClientIsRegistered(phone);
			setLoading(false);
			onSubmit(formData);
		} catch (error) {
			setFormError(error.message);
			setLoading(false);
		}
	}, [formData, onSubmit]);

	return (
		<form noValidate onSubmit={handleSubmit} className={styles.form}>
			<div className={styles.fields}>
				<ShadowInput
					className={styles.field}
					type={'tel'}
					value={formData.phone}
					onFocus={focusMaskStart}
					onBlur={handlePhoneInputBlur}
					onChange={handlePhoneInputChange}
					onPaste={handlePhoneInputPaste}
					mask={phoneInputMask}
					shadowColor={'satin'}
					placeholder={'Телефон'}/>
			</div>
			<div className={styles.actions}>
				<Button
					type={'submit'}
					className={classNames([styles.action, formStyles.action])}
					disabled={isLoading}
				>
					Восстановить пароль
				</Button>
			</div>
			<StatusModal
				status={'error'}
				modalText={formError}
				buttonText={'ОК'}
				onClose={dismissErrorModal}
				onButtonClick={dismissErrorModal}
				isOpen={formError !== null}
			/>
		</form>
	);
}

function NewPasswordForm({ onSubmit }) {
	const [formError, setFormError] = useState(null);
	const [formData, setFormField] = useObject({ password: '', passwordConfirm: '' });

	const handleSubmit = useCallback((event) => {
		event.preventDefault();
		if (formData.password.length < 6) {
			setFormError('Пароль должен содержать минимум 6 символов');
			return;
		}
		if (formData.password !== formData.passwordConfirm) {
			setFormError('Пароли не совпадают');
			return;
		}
		onSubmit({ password: formData.password });
	}, [formData, onSubmit]);

	const dismissErrorModal = useCallback(() => {
		setFormError(null);
	}, []);

	return (
		<form noValidate onSubmit={handleSubmit} className={styles.form}>
			<div className={styles.fields}>
				<ShadowInput
					className={styles.field}
					type={'password'}
					value={formData.password}
					onChange={(value) => setFormField('password', value)}
					shadowColor={'satin'}
					placeholder={'Новый пароль'}
					autoComplete={'new-password'}
				/>
				<ShadowInput
					className={styles.field}
					type={'password'}
					value={formData.passwordConfirm}
					onChange={(value) => setFormField('passwordConfirm', value)}
					shadowColor={'satin'}
					placeholder={'Повторите пароль'}
					autoComplete={'new-password'}
				/>
			</div>
			<div className={styles.actions}>
				<Button
					type={'submit'}
					className={classNames([styles.action, formStyles.action])}
				>
					Изменить пароль
				</Button>
			</div>
			<StatusModal
				status={'error'}
				modalText={formError}
				buttonText={'ОК'}
				onClose={dismissErrorModal}
				onButtonClick={dismissErrorModal}
				isOpen={formError !== null}
			/>
		</form>
	);
}

function RecoveryController({ onSuccess }) {

	const [step, setStep] = useState('phone');

	const backContext = useContext(BackContext);

	const recoveryData = useRef({
		phone: null,
		code: null,
		password: null,
	});

	const [confirmCodeTime, setConfirmCodeTime] = useState(null);

	const [isCodeSending, setCodeSending] = useState(false);

	const [submitError, setSubmitError] = useState(null);

	const sendCode = useCallback(async () => {
		setCodeSending(true);
		try {
			const time = await confirmPhone({
				phone: recoveryData.current.phone,
				purpose: 'change-password',
			});
			setConfirmCodeTime(time.expires_at);
		} catch (e) {
			console.error(e);
			// ignore error about code time
		} finally {
			setCodeSending(false);
		}
	}, []);

	const handlePhoneSubmit = useCallback(async ({ phone }) => {
		recoveryData.current.phone = phone;
		await sendCode();
		setStep('code');
	}, [sendCode]);

	const handleCodeSubmit = useCallback(async ({ code }) => {
		try {
			recoveryData.current.code = code;
			setStep('new-password');
		} catch (e) {
			setSubmitError(e.message);
		}
	}, []);

	const handlePasswordSubmit = useCallback(async ({ password }) => {
		recoveryData.current.password = password;
		try {
			await changePassword(recoveryData.current);
			setStep('success');
		} catch (e) {
			setSubmitError(e.message);
		}
	}, []);

	useEffect(() => {
		switch (step) {
			case 'success':
			case 'phone':
				backContext.current = null;
				break;
			case 'code':
			case 'new-password':
				backContext.current = () => setStep('phone');
				break;
		}
		return () => backContext.current = null;
	}, [step, backContext]);

	let content;

	if (step === 'success') {
		return (
			<StatusModal
				isOpen={true}
				modalText={'Пароль успешно изменён!'}
				status={'success'}
				buttonText={'ОК'}
				onClose={onSuccess}
				onButtonClick={onSuccess}
			/>
		);
	}

	switch (step) {
		case 'phone': {
			content = (
				<RecoveryForm
					onSubmit={handlePhoneSubmit}
				/>
			);
			break;
		}
		case 'code': {
			content = (
				<ConfirmPhoneForm
					onResend={sendCode}
					onSubmit={handleCodeSubmit}
					isSubmitting={isCodeSending}
					reclaimAt={moment(confirmCodeTime).format('YYYY-MM-DD HH:mm:ss')}
				/>
			);
			break;
		}
		case 'new-password': {
			content = (
				<NewPasswordForm
					onSubmit={handlePasswordSubmit}
				/>
			);
			break;
		}
	}

	return (
		<>
			{content}
			<StatusModal
				isOpen={submitError !== null}
				modalText={submitError}
				status={'error'}
				buttonText={'ОК'}
				onClose={() => setSubmitError(null)}
				onButtonClick={() => setSubmitError(null)}
			/>
		</>
	);
}

export default RecoveryController;
