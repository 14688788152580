import React from 'react';
import classNames from 'classnames';

import { Summary } from '../../components/ClientsSummary';
import Arrow from '../../components/Arrow/Arrow';
import DateInfo from '../../components/VisitInfo/DateInfo';
import SalonInfo from '../../components/VisitInfo/SalonInfo';

import styles from './Visit.module.scss';

class CurrentVisit extends React.PureComponent {

	state = {
		expanded: false,
	};

	toggle = () => {
		this.setState({ expanded: !this.state.expanded });
	};

	handleCancelClick = (e) => {
		this.props.onClickCancel(e);
	};

	handleRouteClick = (e) => {
		this.props.onClickRoute(e);
	};

	handleCallClick = (e) => {
		this.props.onClickCall(e);
	};

	handleChangeClick = (e) => {
		this.props.onClickChange(e);
	};

	render() {
		const {
			services,
			date,
			time,
			salon,
			className,
		} = this.props;
		const containerClassName = classNames([
			styles.container,
			styles.future,
			className,
		]);

		return (
			<div className={containerClassName}>
				<div className={styles.header} onClick={this.toggle}>
					<i className={styles.dateInfoIcon}/>
					<div className={styles.headerInfo}>
						<DateInfo
							className={styles.dateInfo}
							date={date}
							time={time}
						/>
						<SalonInfo
							className={styles.salonInfo}
							salon={salon}
						/>
					</div>
					<div className={styles.expandButton}>
						<Arrow
							direction={this.state.expanded ? 'down' : 'right'}
							isDark
							isSmall
						/>
					</div>
				</div>
				{this.state.expanded && (
					<div className={styles.moreInfo}>
						<Summary
							className={styles.summary}
							itemClassName={styles.clientSummary}
							clientsObject={{ 0: services }}
							totalTitle={'Итого'}
							currency={'₽'}
							durationMinutes={'мин'}
							durationHours={'час'}
						/>
						<div className={styles.actions}>
							<div className={styles.action} data-name="change" onClick={this.handleChangeClick}>
								<i className={styles.actionIcon}/>
								<div className={styles.actionTitle}>Изменить</div>
							</div>
							<div className={styles.action} data-name="call" onClick={this.handleCallClick}>
								<i className={styles.actionIcon}/>
								<div className={styles.actionTitle}>Позвонить</div>
							</div>
							<div className={styles.action} data-name="cancel" onClick={this.handleCancelClick}>
								<i className={styles.actionIcon}/>
								<div className={styles.actionTitle}>Отменить запись</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default CurrentVisit;
