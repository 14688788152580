import React from 'react';
import { connect } from 'react-redux';

import { getActiveServices, getAppointmentSalon } from '@/store/selectors';

import ServicesCategory from '../../components/ServicesCategory';
import ServicesListItem from '../../components/ServicesListItem';

import servicesType1 from '../../assets/service-type-1.svg';
import servicesType2 from '../../assets/service-type-2.svg';
import servicesType3 from '../../assets/service-type-3.svg';
import servicesType4 from '../../assets/service-type-4.svg';
import servicesType8 from '../../assets/service-type-8.svg';
import servicesType9 from '../../assets/service-type-9.svg';
import servicesType12 from '../../assets/service-type-12.svg';
import servicesType10 from '../../assets/services-icons-no-box/brows-active.svg';

import styles from './ServiceList.module.scss';
import ServicesSubCategory from '../ServicesSubCategory/ServicesSubCategory';

class ServiceList extends React.PureComponent {

	static defaultProps = {
		selectedServicesTypes: [],
		selectedServices: [],
		services: [],
	};

	static categories = [
		{
			name: 'Комплекс',
			color: 'pink',
			image: servicesType3,
			type: 3,
		},
		{
			name: 'Маникюр',
			color: 'yellow',
			image: servicesType1,
			type: 1,
		},
		{
			name: 'Педикюр',
			color: 'blue',
			image: servicesType2,
			type: 2,
		},
		{
			name: 'Здоровье и уход',
			color: 'satin',
			image: servicesType4,
			subcategories: [
				{
					name: 'Комплексный уход',
					type: 4,
				},
				{
					name: 'Увлажнение и питание',
					type: 5,
				},
				{
					name: 'Укрепление ногтей',
					type: 6,
				},
				{
					name: 'Очищение',
					type: 7,
				},
			],
		},
		{
			name: 'Дизайны',
			color: 'pink',
			image: servicesType9,
			type: 9,
		},
		{
			name: 'Доп. услуги',
			color: 'blue',
			image: servicesType8,
			type: 8,
		},
		{
			name: 'Брови и ресницы',
			color: 'pink',
			image: servicesType10,
			type: 10,
		},
		{
			name: 'Chevelure',
			color: 'pink',
			image: servicesType12,
			type: 12,
		},
	];

	state = {
		expandedCategory: null,
		expandedSubcategories: {},
	};

	onExpand = (categoryIndex) => {
		this.setState({ expandedCategory: categoryIndex });
	};

	onCollapse = () => {
		this.setState({ expandedCategory: null });
	};

	onExpandSub = (parentIdx, subIdx) => {
		this.setState({
			expandedSubcategories: {
				...this.state.expandedSubcategories,
				[parentIdx]: subIdx,
			},
		});
	};

	onCollapseSub = (parentIdx) => {
		this.setState({
			expandedSubcategories: {
				...this.state.expandedSubcategories,
				[parentIdx]: null,
			},
		});
	};

	renderService = (service, isShortView = false) => {
		let isButtonDisabled = false;
		const isServiceSelected = !!this.props.selectedServices.find((selectedService) => selectedService.id === service.id);
		const type = service.meta?.templateVars?.pageVars?.type;
		switch (type) {
			case 1:
				isButtonDisabled = this.props.selectedServicesTypes.some((el) => [1, 3].includes(el)) && !isServiceSelected;
				break;
			case 2:
				isButtonDisabled = this.props.selectedServicesTypes.some((el) => [2, 3].includes(el)) && !isServiceSelected;
				break;
			case 3:
				isButtonDisabled = this.props.selectedServicesTypes.some((el) => [1, 2, 3].includes(el)) && !isServiceSelected;
				break;
			default:
				break;
		}

		const handleButtonClick = (amount) => {
			if (this.props.onServiceSelect) {
				this.props.onServiceSelect(service.id, amount, isServiceSelected);
			}
		};

		const handleAmountChange = (amount) => {
			if (this.props.onServiceAmountChange) {
				this.props.onServiceAmountChange(service.id, amount, isServiceSelected);
			}
		};

		return (
			<ServicesListItem
				key={service.id}
				service={service}
				isButtonDisabled={isButtonDisabled}
				isButtonSecondary={isServiceSelected}
				buttonTitle={isServiceSelected ? 'Отменить' : 'Выбрать'}
				isSelected={isServiceSelected}
				onButtonClick={handleButtonClick}
				onAmountChange={handleAmountChange}
				isShortView={isShortView}
				isAmountChangeable={!!service.isAmountChangeable}
			/>
		);
	};

	getServicesByType = (type) => {
		return this.props.services.filter((service) => {
			const siteType = service?.meta?.templateVars?.pageVars.type;
			const division = service.division;
			return siteType === type && (!this.props.appointmentSalon || this.props.appointmentSalon.divisions.includes(division));
		});
	};

	getSelectedServicesByType = (type) => {
		return this.props.selectedServices.filter((service) => Array.isArray(type)
			? type.includes(service?.meta?.templateVars?.pageVars.type)
			: type === service?.meta?.templateVars?.pageVars.type);
	};

	getCategories = () => {
		return this.constructor.categories.filter((category) => {
			return category.subcategories?.length > 0 || this.getServicesByType(category.type).length > 0;
		});
	};

	render() {
		return (
			<div className={styles.container}>
				{this.getCategories().map((category, idx) => (
					<ServicesCategory
						key={`category-${idx}`}
						name={category.name}
						color={category.color}
						image={category.image}
						isExpanded={this.state.expandedCategory === idx}
						onCollapse={() => this.onCollapse(idx)}
						onExpand={() => this.onExpand(idx)}
						expanded={
							category.subcategories
								? category.subcategories.map((sub, subIdx) => (
									<ServicesSubCategory
										key={`sub-${subIdx}`}
										name={sub.name}
										isExpanded={this.state.expandedSubcategories[idx] === subIdx}
										onCollapse={() => this.onCollapseSub(idx)}
										onExpand={() => this.onExpandSub(idx, subIdx)}
										expanded={
											this.getServicesByType(sub.type).map((service) => this.renderService(service, false))
										}
										collapsed={
											this.getSelectedServicesByType(sub.type).map((service) => this.renderService(service, true))
										}
									/>
								))
								: this.getServicesByType(category.type).map((service) => this.renderService(service, false))
						}
						collapsed={
							this.getSelectedServicesByType(category.subcategories ? category.subcategories.map((sub) => sub.type) : category.type).map((service) => this.renderService(service, true))
						}
					/>
				))}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		services: getActiveServices(state),
		appointmentSalon: getAppointmentSalon(state),
	};
};

export default connect(mapStateToProps)(ServiceList);
