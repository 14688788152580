import { useLocation } from 'react-router';

function HashRoute({ hash, children }) {
	const location = useLocation();
	if (location.hash === hash) {
		return children;
	}
	return null;
}

export default HashRoute;
