import React from 'react';
import classNames from 'classnames';

import { Summary } from '../../components/ClientsSummary';
import Arrow from '../../components/Arrow/Arrow';
import { DateInfo, SalonInfo } from '../../components/VisitInfo';

import styles from './Visit.module.scss';

class PastVisit extends React.PureComponent {

	state = {
		expanded: false,
	};

	toggle = () => {
		this.setState({ expanded: !this.state.expanded });
	};

	onClickReview = (e) => {
		e.stopPropagation();
		if (this.props.reviewDisabled || this.props.rating !== null) {
			return;
		}
		this.props.onClickReview(e);
	};

	onClickRepeat = (e) => {
		e.stopPropagation();
		this.props.onClickRepeat(e);
	};

	render() {
		const { date, time, salon, services, className, rating, reviewDisabled, discountAmount } = this.props;
		const containerClassName = classNames([styles.container, styles.past, className]);
		const ratingIconClass = classNames({
			[styles.ratingIcon]: true,
			[styles.hasRating]: rating !== null,
			[styles.disabled]: reviewDisabled,
		});

		return (
			<div className={containerClassName}>
				<div className={styles.header} onClick={this.toggle}>
					<i className={ratingIconClass} onClick={this.onClickReview}/>
					<div className={styles.headerInfo}>
						<DateInfo
							className={styles.dateInfo}
							date={date}
							time={time}
						/>
						<SalonInfo
							className={styles.salonInfo}
							salon={salon}
						/>
					</div>
					<div className={styles.expandButton}>
						<Arrow
							direction={this.state.expanded ? 'down' : 'right'}
							isDark
							isSmall
						/>
					</div>
				</div>
				{this.state.expanded && (
					<div className={styles.moreInfo}>
						<Summary
							className={styles.summary}
							itemClassName={styles.clientSummary}
							clientsObject={{ 0: services }}
							totalTitle={'Итого'}
							currency={'₽'}
							durationMinutes={'мин'}
							durationHours={'час'}
							discountAmount={discountAmount}
						/>
						<div className={styles.actions}>
							<div className={styles.action} data-name="repeat" onClick={this.onClickRepeat}>
								<i className={styles.actionIcon}/>
								<div className={styles.actionTitle}>Повторить</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default PastVisit;
