import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { editProfile } from '@/api';
import ShadowInput from '../ShadowInput/ShadowInput';
import formStyles from './ProfileEditForm.module.scss';
import Button from '../Button/Button';
import StatusModal from '../StatusModal/StatusModal';
import { getCities, getProfile } from '@/store/selectors';
import ShadowSelect from '../ShadowSelect/ShadowSelect';
import { validateEmail } from '@/utils/validateEmail';
import Modal from '../Modal/Modal';
import ChangePasswordForm from './ChangePasswordForm';

import styles from './Form.module.scss';
import { dateMask, focusMaskStart, phoneInputMask } from '@/utils/masks';
import { updateProfileFieldAction } from '@/store/actions';


class ProfileEditForm extends React.Component {

	getProfileFromProps = () => {
		return Object.assign({
			defaultCityId: null,
			firstName: '',
			lastName: '',
			sex: 1,
			phone: '',
			email: '',
			birthday: '',
		}, {
			...this.props.profile,
			birthday: this.props.profile.birthday ? moment(this.props.profile.birthday).format('DD.MM.YYYY') : '',
		});
	};

	state = {
		formData: this.getProfileFromProps(),
		errors: {
			firstName: '',
			phone: '',
			email: '',
			birthday: '',
		},
		changePasswordModalIsOpen: false,
		changePasswordSuccessModalIsOpen: false,
	};

	debounceTimers = {};

	setFormData = (field, value) => {
		this.setState({
			formData: {
				...this.state.formData,
				[field]: value,
			},
		});
		clearTimeout(this.debounceTimers[field]);
		this.clearError(field);
		this.debounceTimers[field] = setTimeout(async () => {
			try {
				this.validateField(field, value);
				if (field === 'birthday') {
					const realValue = value ? moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
					await editProfile({
						[field]: realValue,
					});
					this.props.onUpdateProfile(field, realValue);
				} else {
					await editProfile({
						[field]: value,
					});
					this.props.onUpdateProfile(field, value);
				}
			} catch (e) {
				this.setError(field, e.message);
			}
		}, 300);
	};

	setError = (field, message) => {
		this.setState({
			errors: {
				...this.state.errors,
				[field]: message,
			},
		});
	};

	clearError = (field) => {
		this.setError(field, '');
	};

	validateField = (field, value) => {
		if (field === 'firstName') {
			if (!value) {
				throw new Error('Обязательное поле');
			}
		}
		if (field === 'phone') {
			if (value.length !== 11) {
				throw new Error('Неверный формат номера');
			}
		}
		if (field === 'email') {
			if (value.length && !validateEmail(value)) {
				throw new Error('Неверный формат email');
			}
		}
		if (field === 'birthday') {
			if (value.length) {
				if (value.replace(/\D/g, '').length !== 8) {
					throw new Error('Неверный формат даты');
				}
				if (!moment(value, 'DD.MM.YYYY').isValid()) {
					throw new Error('Неверный формат даты');
				}
				if (moment(value, 'DD.MM.YYYY').isAfter(moment())) {
					throw new Error('Неверная дата рождения');
				}
			}
		}
	};

	focusMaskStart = (e) => {
		e.persist();
		setTimeout(() => {
			const startIndex = e.target.value.indexOf('_');
			if (startIndex !== -1) {
				e.target.setSelectionRange(startIndex, startIndex);
			}
		}, 0);
	};

	handlePhoneInputBlur = (e) => {
		if (e.target.value.replace(/\D/g, '') === '7') {
			this.setFormData('phone', '');
		}
	};

	handlePhoneInputChange = (value) => {
		this.setFormData('phone', value.replace(/\D/g, ''));
	};

	openPasswordChangeModal = () => {
		this.setState({
			changePasswordModalIsOpen: true,
		});
	};

	dismissPasswordChangeModal = () => {
		this.setState({
			changePasswordModalIsOpen: false,
		});
	};

	openPasswordSuccessModal = () => {
		this.setState({
			changePasswordSuccessModalIsOpen: true,
		});
	};

	dismissPasswordSuccessModal = () => {
		this.setState({
			changePasswordSuccessModalIsOpen: false,
		});
	};

	handlePasswordChangeSuccess = () => {
		this.dismissPasswordChangeModal();
		this.openPasswordSuccessModal();
	};

	componentDidUpdate(prevProps) {
		if (prevProps.profile !== this.props.profile) {
			this.setState({
				formData: this.getProfileFromProps(),
			});
		}
	}

	render() {
		return (
			<div className={styles.form}>
				<div className={styles.fields}>
					<ShadowSelect
						onChange={(value) => this.setFormData('defaultCityId', value)}
						value={this.state.formData.defaultCityId}
						name={'city'}
						className={styles.field}
						shadowColor={'transparent'}
						placeholder={'Город*'}
						options={this.props.cities.map(({ city }) => city)}
					/>
					<ShadowInput
						onChange={(value) => this.setFormData('firstName', value)}
						value={this.state.formData.firstName}
						name={'firstName'}
						className={styles.field}
						shadowColor={'transparent'}
						placeholder={'Имя*'}
						error={!!this.state.errors.firstName}
						tooltip={this.state.errors.firstName}
					/>
					<ShadowInput
						onChange={(value) => this.setFormData('lastName', value)}
						value={this.state.formData.lastName}
						name={'lastName'}
						className={styles.field}
						shadowColor={'transparent'}
						placeholder={'Фамилия'}
						error={!!this.state.errors.lastName}
						tooltip={this.state.errors.lastName}
					/>
					<ShadowSelect
						onChange={(value) => this.setFormData('sex', value)}
						value={this.state.formData.sex}
						name={'sex'}
						className={styles.field}
						shadowColor={'transparent'}
						placeholder={'Пол'}
						options={[{ id: 2, name: 'Мужской' }, { id: 3, name: 'Женский' }]}
					/>
					<ShadowInput
						onFocus={focusMaskStart}
						onBlur={this.handlePhoneInputBlur}
						onChange={this.handlePhoneInputChange}
						value={this.state.formData.phone.replace(/^7/, '')}
						type={'tel'}
						name={'phone'}
						mask={phoneInputMask}
						className={styles.field}
						shadowColor={'transparent'}
						placeholder={'Мобильный телефон *'}
						error={!!this.state.errors.phone}
						tooltip={this.state.errors.phone}
					/>
					<ShadowInput
						onChange={(value) => this.setFormData('email', value)}
						value={this.state.formData.email}
						type={'email'}
						name={'email'}
						className={styles.field}
						shadowColor={'transparent'}
						placeholder={'Email *'}
						error={!!this.state.errors.email}
						tooltip={this.state.errors.email}
					/>
					<ShadowInput
						onFocus={focusMaskStart}
						onChange={(value) => this.setFormData('birthday', value)}
						value={this.state.formData.birthday}
						mask={dateMask}
						type={'text'}
						name={'birthday'}
						className={styles.field}
						shadowColor={'transparent'}
						placeholder={'Дата рождения'}
						error={!!this.state.errors.birthday}
						tooltip={this.state.errors.birthday}
					/>
					<Button
						className={formStyles.changePassword}
						onClick={this.openPasswordChangeModal}
					>
						Изменить пароль
					</Button>
				</div>
				<Modal
					className={formStyles.changePasswordModal}
					isOpen={this.state.changePasswordModalIsOpen}
					onClose={this.dismissPasswordChangeModal}
				>
					<ChangePasswordForm onSuccess={this.handlePasswordChangeSuccess}/>
				</Modal>
				<StatusModal
					isOpen={this.state.changePasswordSuccessModalIsOpen}
					status={'success'}
					modalText={'Пароль успешно изменен'}
					onClose={this.dismissPasswordSuccessModal}
					onButtonClick={this.dismissPasswordSuccessModal}
					buttonText={'OK'}
				/>

			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		cities: getCities(state),
		profile: getProfile(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateProfile: (field, value) => dispatch(updateProfileFieldAction(field, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditForm);
