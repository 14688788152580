import React from 'react';
import ReactDOM from 'react-dom/client';

import moment from 'moment';
import 'moment/locale/ru';
import { App } from './App';

moment.locale('ru');

const root = document.getElementById('root');
// eslint-disable-next-line no-undef
if (IS_DEV_SERVER) {
	ReactDOM.createRoot(root).render(<App/>);
} else {
	ReactDOM.hydrateRoot(root, <App/>);
}
