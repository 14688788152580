import React from 'react';
import classNames from 'classnames';

import styles from './ServicesSummaryItem.module.scss';

const ServicesSummaryItem = (props) => {
	const { service, className } = props;
	const name = service?.meta?.title ?? 'Услуга недоступна';
	const isAmountChangeable = service?.isAmountChangeable ?? false;
	const duration = (service.amount * service.duration) || 0;
	const cost = (service.amount * service.price) || 0;
	return <div className={classNames([styles.container, className])}>
		<div className={styles.nameContainer}>
			<span className={styles.name}>{name}</span>
			{!!isAmountChangeable && <span className={styles.amount}>({service.amount})</span>}
		</div>
		<div className={styles.attributes}>
			<div className={styles.durationContainer}>
				<span className={styles.duration}>{duration}</span>
				<span className={styles.durationUnits}>мин</span>
			</div>
			<div className={styles.priceContainer}>
				<div className={styles.priceBg}>
					<span className={styles.price}>{cost}</span>
					<span className={styles.currency}>₽</span>
				</div>
			</div>
		</div>
	</div>;
};

export default ServicesSummaryItem;
