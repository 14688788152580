import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import styles from './VisitInfo.module.scss';

const DateInfo = ({ date, time, icon, className }) => (
	<div className={classNames([styles.info, className])}>
		{icon && <div className={styles.icon}>
			{icon}
		</div>}
		<div className={styles.dateContainer}>
			<div className={styles.date}>{moment(date).format('D MMMM')}</div>
			<div className={styles.time}>{time}</div>
		</div>
	</div>
);

export default DateInfo;
