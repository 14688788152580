import axios from 'axios';
import qs from 'qs';

import RequestError from './RequestError';
import NetworkError from './NetworkError';

import config from '../config.json';

const request = axios.create({
	baseURL: config.api,
	paramsSerializer: (params) => qs.stringify(params),
});


request.interceptors.response.use((response) => {
	const result = response.data;
	if (result.error) {
		return Promise.reject(new RequestError(result.errorText, result.errorCode));
	}
	return result.data;
}, (error) => {
	return Promise.reject(new NetworkError(error.message));
});

export default request;
