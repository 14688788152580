export const phoneInputMask = ['+', '7', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const codeInputMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];

export const focusMaskStart = (e) => {
	e.persist();
	setTimeout(() => {
		const startIndex = e.target.value.indexOf('_');
		if (startIndex !== -1) {
			e.target.setSelectionRange(startIndex, startIndex);
		}
	}, 0);
};
