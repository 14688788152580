import React from 'react';

import Button from '../Button/Button';
import ShadowInput from '../ShadowInput/ShadowInput';
import { editProfile } from '../../api';

import styles from './Form.module.scss';

class ChangePasswordForm extends React.Component {

	state = {
		formData: {
			password: '',
			passwordConfirm: '',
		},
		passwordsEmpty: false,
		loading: false,
	};

	setFormData = (field, value) => {
		this.setState({
			formData: {
				...this.state.formData,
				[field]: value,
			},
			passwordsEmpty: false,
		});
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		if (!this.state.formData.password.length || !this.state.formData.passwordConfirm.length) {
			this.setState({
				passwordsEmpty: true,
			});
			return;
		}
		this.setLoading(true);
		try {
			await editProfile({
				password: this.state.formData.password,
				passwordConfirm: this.state.formData.passwordConfirm,
			});
			this.setLoading(false);
			this.props.onSuccess();
		} catch (e) {
			this.setLoading(false);
			this.props.onError(e);
		}
	};

	setLoading = (loading) => {
		this.setState({
			loading,
		});
	};


	render() {
		const password = this.state.formData.password;
		const passwordConfirm = this.state.formData.passwordConfirm;
		const passwordErrorMismatch = !!(password.length && passwordConfirm.length && password !== passwordConfirm);

		return (
			<div className={styles.form}>
				<div className={styles.fields}>
					<ShadowInput
						className={styles.field}
						type={'password'}
						lang={'en'}
						onChange={(value) => this.setFormData('password', value)}
						value={this.state.formData.password}
						shadowColor={'transparent'}
						placeholder={'Новый пароль'}
						error={passwordErrorMismatch || this.state.passwordsEmpty}
					/>
					<ShadowInput
						className={styles.field}
						type={'password'}
						lang={'en'}
						onChange={(value) => this.setFormData('passwordConfirm', value)}
						value={this.state.formData.passwordConfirm}
						shadowColor={'transparent'}
						placeholder={'Подтверждение пароля'}
						error={passwordErrorMismatch || this.state.passwordsEmpty}
					/>
				</div>
				<div className={styles.actions}>
					<Button
						onClick={this.handleSubmit}
						disabled={passwordErrorMismatch || this.state.loading}
					>
						Изменить пароль
					</Button>
				</div>
			</div>
		);
	}
}

export default ChangePasswordForm;
