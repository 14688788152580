import React, { useMemo } from 'react';
import SmartLink from '@/components/SmartLink';
import { Helmet } from 'react-helmet-async';

function RawPicture(props) {
	const { mobileImage, mobileLow, desktopImage, className, lazy, alt, ...rest } = props;
	return (
		<picture>
			{mobileImage && (
				<source media={'(max-width:480px)'} srcSet={mobileLow ? `${encodeURI(mobileLow.path)}, ${encodeURI(mobileImage.path)} 2x` : encodeURI(mobileImage.path)}/>
			)}
			{desktopImage && (
				<source media={'(min-width:481px)'} srcSet={encodeURI(desktopImage.path)}/>
			)}
			<img
				loading={lazy ? 'lazy' : undefined}
				alt={alt || (mobileLow ?? mobileImage ?? desktopImage).name}
				title={alt || (mobileLow ?? mobileImage ?? desktopImage).name}
				src={encodeURI((mobileLow ?? mobileImage ?? desktopImage).path)}
				{...rest}
				className={className}
			/>
		</picture>
	);
}

export function Picture(props) {
	const { desktopType, mobileType, link, image, className, imageClassName, lazy, onClick, preload, ...rest } = props;
	const [desktopImage, mobileImage, mobileLow] = useMemo(() => {
		let desktop = null;
		let mobile = null;
		let mobileLow = null;
		if (image) {
			for (const img of image) {
				if (img.type === desktopType) {
					desktop = img;
				} else if (img.type === mobileType) {
					mobile = img;
				} else if (img.type === mobileType + '-lowres') {
					mobileLow = img;
				}
			}
		}
		return [desktop, mobile, mobileLow];
	}, [image, desktopType, mobileType]);

	if (!desktopImage && !mobileImage) {
		return (
			<div className={className}>
				<div className={'empty-image'} onClick={onClick}/>
			</div>
		);
	}

	if (link) {
		return (
			<div className={className}>
				{preload && (
					<Helmet>
						{mobileLow && (
							<link rel={'preload'} as={'image'} href={encodeURI(mobileLow.path)}/>
						)}
						{mobileImage && (
							<link rel={'preload'} as={'image'} href={encodeURI(mobileImage.path)}/>
						)}
						{desktopImage && (
							<link rel={'preload'} as={'image'} href={encodeURI(desktopImage.path)}/>
						)}
					</Helmet>
				)}
				<SmartLink path={link}>
					<RawPicture
						mobileImage={mobileImage}
						mobileLow={mobileLow}
						desktopImage={desktopImage}
						className={imageClassName}
						lazy={lazy}
						{...rest}
						onClick={onClick}
					/>
				</SmartLink>
			</div>
		);
	}
	return <div className={className}>
		{preload && (
			<Helmet>
				{mobileLow && (
					<link rel={'preload'} as={'image'} href={encodeURI(mobileLow.path)}/>
				)}
				{mobileImage && (
					<link rel={'preload'} as={'image'} href={encodeURI(mobileImage.path)}/>
				)}
				{desktopImage && (
					<link rel={'preload'} as={'image'} href={encodeURI(desktopImage.path)}/>
				)}
			</Helmet>
		)}
		<RawPicture
			mobileImage={mobileImage}
			mobileLow={mobileLow}
			desktopImage={desktopImage}
			className={imageClassName}
			lazy={lazy}
			{...rest}
			onClick={onClick}
		/>
	</div>;
}
