import React from 'react';
import styles from './SalonsListPage.module.scss';
import SalonsList from '@/components/SalonsList/SalonsList';

export const SalonsListPage = (props) => {
	const page = props.content;
	return (
		<div className={styles.container}>
			<h1 className={styles.title}>{page.title}</h1>
			<SalonsList controls={false}/>
		</div>
	);
};
