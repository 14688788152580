import React from 'react';
import classNames from 'classnames';

import Arrow from '../Arrow/Arrow';
import { withRouter } from 'react-router-dom';

import styles from './Back.module.scss';

const Back = ({ onClick, history, className }) => {
	const handleClick = () => {
		onClick(history);
	};

	return (
		<div className={classNames([styles.link, className])} onClick={handleClick}>
			<Arrow isDark isSmall direction={'left'} className={styles.arrow}/>
			<div className={styles.text}>Назад</div>
		</div>
	);
};

Back.defaultProps = {
	onClick: (history) => {
		history.goBack();
	},
};

export default withRouter(Back);
