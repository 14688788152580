import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import qs from 'qs';

import YmapAndSalons from '../../components/SalonsListController/SalonsListController';

import styles from './GiftCard.module.scss';
import { initCertificatesOrder } from '@/api';
import parseHtml from '@/utils/htmlParserWithCustomLinks';
import { useLocation } from 'react-router';
import GiftCardsList from '@/templates/GiftCard/GiftCardsList';
import GiftCardsCart from '@/templates/GiftCard/GiftCardsCart';
import GiftCardsStatus from '@/templates/GiftCard/GiftCardsStatus';
import visaLogo from '@/assets/visa.png';
import mastercardLogo from '@/assets/mastercard.png';
import mirLogo from '@/assets/mir.svg';
import SmartLink from '@/components/SmartLink';

function GiftCard(props) {

	const page = props.content;
	const vars = page.templateVars.pageVars;
	const [order, setOrder] = useState({
		items: [],
		discount: 0,
	});

	const [step, setStep] = useState('list'); // order, list
	const [orderSubmitting, setOrderSubmitting] = useState(false);
	const location = useLocation();
	const search = qs.parse(location.search.replace('?', ''));

	const increaseAmount = useCallback((itemIndex) => {
		setOrder((order) => {
			const newItems = [...order.items];
			newItems[itemIndex].amount++;
			return {
				...order,
				items: newItems,
			};
		});
	}, []);

	const reduceAmount = useCallback((itemIndex) => {
		setOrder((order) => {
			const newItems = [...order.items];
			newItems[itemIndex].amount = newItems[itemIndex].amount > 1
				? newItems[itemIndex].amount - 1
				: newItems[itemIndex].amount;
			return {
				...order,
				items: newItems,
			};
		});
	}, []);

	const removeItem = useCallback((itemIndex) => {
		setOrder((order) => {
			const newItems = [...order.items];
			newItems.splice(itemIndex, 1);
			return {
				...order,
				items: newItems,
			};
		});
	}, []);

	const handleSubmitOrder = useCallback(async () => {
		setOrderSubmitting(true);
		try {
			const items = order.items.map((item) => {
				const newItem = { ...item };
				newItem.certificateId = newItem.certificate.id;
				delete newItem.certificate;
				return newItem;
			});
			const response = await initCertificatesOrder({
				...order,
				items,
			});
			if (response.formUrl && window) {
				window.location.href = response.formUrl;
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setOrderSubmitting(false);
		}
	}, [order]);

	const handleSelectedItem = useCallback((preorder) => {
		setOrder((order) => ({
			...order,
			items: [...order.items, preorder],
		}));
		setStep('order');
	}, []);

	const handleEditRecipient = useCallback((itemIndex, data) => {
		setOrder((order) => {
			const newItems = [...order.items];
			newItems[itemIndex].recipient = data;
			return {
				...order,
				items: newItems,
			};
		});
	}, []);

	let content = null;

	if (search.orderId) {
		content = <GiftCardsStatus orderId={search.orderId}/>;
	} else {
		switch (step) {
			case 'list': {
				content = <GiftCardsList
					certificates={vars.cards}
					onSelected={handleSelectedItem}
				/>;
				break;
			}
			case 'order': {
				content = <GiftCardsCart
					order={order}
					isSubmitting={orderSubmitting}
					onSubmit={handleSubmitOrder}
					onAddMore={() => setStep('list')}
					onIncreaseAmount={increaseAmount}
					onReduceAmount={reduceAmount}
					onRemove={removeItem}
					onEditRecipient={handleEditRecipient}
				/>;
				break;
			}
		}
	}
	return <div className={styles.container}>
		<h1 className={styles.title}>{page.title}</h1>
		{content}
		<div className={styles.contentContainer}>
			<div className={styles.content}>
				<article className={classNames([styles.article, 'text-content'])}>
					{parseHtml(vars.content || '')}
				</article>
				{vars.rulesFile && (
					<SmartLink href={vars.rulesFile.link} target={'_blank'}>
						Условия использования электронных подарочных сертификатов «Пальчики»
					</SmartLink>
				)}
				{vars.personalFile && (
					<SmartLink href={vars.personalFile.link} target={'_blank'}>Согласие на обработку персональных
						данных</SmartLink>
				)}
				{vars.privacyFile && (
					<SmartLink href={vars.privacyFile.link} target={'_blank'}>Политика конфиденциальности</SmartLink>
				)}
				{vars.paymentFile && (
					<SmartLink href={vars.paymentFile.link} target={'_blank'}>Порядок оплаты</SmartLink>
				)}
				{vars.conditionsFile && (
					<SmartLink href={vars.conditionsFile.link} target={'_blank'}>Условия продажи товаров</SmartLink>
				)}
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<img style={{ width: 64, marginRight: 16 }} src={visaLogo} alt={'Visa'}/>
					<img style={{ width: 64, marginRight: 16 }} src={mastercardLogo} alt={'Mastercard'}/>
					<img style={{ width: 64 }} src={mirLogo} alt={'МИР'}/>
				</div>
			</div>
		</div>
		<YmapAndSalons withCityTitle/>
	</div>;
}

export default GiftCard;
