import React from 'react';
import SmartLink from '@/components/SmartLink';

function ImageLoader({ src, alt, title, onClick, className, imageClassName, manual, ready, link, ...rest }) {
	if (typeof window === 'undefined') {
		if (link) {
			return (
				<div className={className}>
					<SmartLink path={link}>
						<img
							src={manual ? ready ? src : undefined : src}
							loading={manual ? undefined : 'lazy'}
							alt={alt}
							title={title}
							{...rest}
							className={imageClassName}
						/>
					</SmartLink>
				</div>
			);
		}
		return <div className={className}>
			<img
				src={manual ? ready ? src : undefined : src}
				loading={manual ? undefined : 'lazy'}
				alt={alt}
				title={title}
				{...rest}
				className={imageClassName}
			/>
		</div>;
	}

	if (!src) {
		return <div className={className}>
			<div className={'empty-image'} onClick={onClick}/>
		</div>;
	}

	if (link) {
		return (
			<div className={className}>
				<SmartLink path={link}>
					<img
						src={manual ? ready ? src : undefined : src}
						loading={manual ? undefined : 'lazy'}
						title={title}
						alt={alt}
						{...rest}
						className={imageClassName}
						onClick={onClick}
					/>
				</SmartLink>
			</div>
		);
	}

	return (
		<div className={className}>
			<img
				src={manual ? ready ? src : undefined : src}
				loading={manual ? undefined : 'lazy'}
				title={title}
				alt={alt}
				{...rest}
				className={imageClassName}
				onClick={onClick}
			/>
		</div>
	);
}

export default ImageLoader;
