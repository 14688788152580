const initialState = {
	token: null,
	profile: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'SET_TOKEN': {
			return {
				...state,
				token: payload,
			};
		}
		case 'SET_PROFILE': {
			return {
				...state,
				profile: payload,
			};
		}
		case 'UPDATE_PROFILE_FIELD': {
			if (!state.profile) {
				return state;
			}
			return {
				...state,
				profile: {
					...state.profile,
					[payload.field]: payload.value,
				},
			};
		}
		case 'SIGN_OUT': {
			return {
				...initialState,
			};
		}
		default:
			return state;
	}
};
