import React from 'react';
import classNames from 'classnames';

import calcDuration from '../../utils/calcDuration';
import DateInfo from '../../components/VisitInfo/DateInfo';
import SalonInfo from '../../components/VisitInfo/SalonInfo';
import ServicesSummaryItem from '../../components/ServicesSummaryItem';

import styles from './ClientsSummary.module.scss';
import curring from '../../utils/curring';

export const ServicesShortList = ({ services, index = 0, className, editable = false, onItemDelete }) => {
	const clientServicesClassName = classNames([
		styles.services,
		className,
	]);
	return (
		<div className={clientServicesClassName}>
			{services.map((service) => (
				<div key={`${service.id}-${index}`} className={styles.service}>
					<ServicesSummaryItem service={service}/>
					{editable && <i className={styles.iconDelete} onClick={() => onItemDelete(service.id)}/>}
				</div>
			))}
		</div>
	);
};

export const ClientHeader = ({ title }) => {
	return <div className={styles.header}>{title}</div>;
};

export const Summary = (props) => {
	const {
		className,
		itemClassName,
		clientsObject,
		clientTitle,
		note,
		date,
		time,
		salon,
		totalTitle,
		currency,
		durationMinutes,
		durationHours,
		discountAmount,
	} = props;
	const clients = Object.values(clientsObject);
	const containerClassName = classNames([styles.container, className]);
	return (
		<div className={containerClassName}>
			{clients.map((services, index) => (
				<ClientSummary
					key={index}
					index={index}
					totalClients={clients.length}
					title={clientTitle ? clientTitle(index) : ''}
					services={services}
					totalTitle={totalTitle}
					note={note}
					date={date}
					time={time}
					salon={salon}
					currency={currency}
					durationMinutes={durationMinutes}
					durationHours={durationHours}
					className={itemClassName}
					discountAmount={discountAmount}
				/>
			))}
		</div>
	);
};

export const ClientSummary = (props) => {
	const {
		index,
		title,
		totalClients,
		services,
		note,
		date,
		time,
		salon,
		className,
		editable = false,
		onItemDelete,
		discountAmount,
	} = props;

	const isLast = totalClients === index + 1;
	const hasSiblings = totalClients > 1;
	const totalCost = services.reduce(
		(carry, service) => carry + (service.price || 0) * service.amount,
		0,
	);
	const duration = calcDuration(services);

	const minutes = duration % 60;
	const hours = (duration - (duration % 60)) / 60;
	let totalDuration = '';
	if (hours === 1 && minutes === 0) {
		totalDuration += '60 мин';
	} else {
		if (hours > 0) {
			totalDuration += `${hours} ${curring(hours, ['час', 'часа', 'часов'])} `;
		}
		totalDuration += `${minutes} мин`;
	}

	return (
		<div className={classNames(styles.item, className)}>
			{hasSiblings && <ClientHeader title={title}/>}
			<div className={styles.servicesContainer}>
				<ServicesShortList services={services} index={index} editable={editable} onItemDelete={onItemDelete}/>
			</div>
			{(discountAmount !== undefined && discountAmount > 0) && (
				<div className={styles.totals}>
					<div className={styles.totalsTitle}>Скидка</div>
					<div className={styles.totalsAttributes}>
						<div className={styles.totalsDuration}></div>
						<div className={styles.totalsPrice}>
							<span>{-discountAmount}</span>
							<span className={styles.currency}>₽</span>
						</div>
					</div>
				</div>
			)}
			<div className={styles.totals}>
				<div className={styles.totalsTitle}>Итого</div>
				<div className={styles.totalsAttributes}>
					<div className={styles.totalsDuration}>{totalDuration}</div>
					<div className={styles.totalsPrice}>
						<span>{totalCost - (discountAmount ? discountAmount : 0)}</span>
						<span className={styles.currency}>₽</span>
					</div>
				</div>
			</div>
			{isLast && (
				<React.Fragment>
					{note && <div className={styles.note}>{note}</div>}
					{date && time && salon && (
						<div className={styles.info}>
							<DateInfo
								className={styles.dateInfo}
								date={date}
								time={time}
								icon={<i className={styles.dateInfoIcon}/>}
							/>
							<SalonInfo
								className={styles.salonInfo}
								salon={salon}
								icon={<i className={styles.salonInfoIcon}/>}
							/>
						</div>
					)}
				</React.Fragment>
			)}
		</div>
	);
};
