import { useCallback, useState } from 'react';

export default function useObject(defaultValue) {
	const [value, setValue] = useState(defaultValue);
	const setField = useCallback((name, newValue) => {
		setValue((value) => ({ ...value, [name]: newValue }));
	}, []);

	return [value, setField, setValue];
}
