import React, { useCallback, useEffect, useMemo } from 'react';
import YmapAndSalons from '../../components/SalonsListController/SalonsListController';
import MainMenu from '../../components/MainMenu/MainMenu';

import styles from './Main.module.scss';
import MainSlides from '@/templates/Main/MainSlides';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { getSelectedCityId } from '@/store/selectors';

function Main(props) {

	const page = props.content;

	const slides = page.templateVars.pageVars.slides;

	const location = useLocation();

	const activeCityId = useSelector(getSelectedCityId);

	const scrollToHashes = useCallback(() => {

		if (typeof window === 'undefined') {
			return;
		}

		if (location.hash === '#services') {
			const servicesList = document.getElementById('serivces-list');
			if (servicesList) {
				// 60 is header height
				const scrollTop = servicesList.offsetTop - 60;
				window.scrollTo(0, scrollTop);
				return;
			}
		}

		if (location.hash === '#salons') {
			const salonsList = document.getElementById('salons-list');
			if (salonsList) {
				const scrollTop = salonsList.offsetTop;
				window.scrollTo(0, scrollTop);
				return;
			}
		}

		document.documentElement.scrollTop = 0;

	}, [location.hash]);

	useEffect(() => {
		scrollToHashes();
	}, [scrollToHashes]);

	const citySlides = useMemo(() => {
		return slides.filter((slide) => slide.cities.includes(activeCityId));
	}, [activeCityId, slides]);

	return (
		<div className={styles.container}>
			<MainSlides slides={citySlides}/>
			<div id={'serivces-list'} className={styles.servicesList}>
				<MainMenu/>
			</div>
			<div id={'salons-list'} className={styles.salonsList}>
				<YmapAndSalons withCityTitle/>
			</div>
		</div>
	);
}

export default React.memo(Main);
