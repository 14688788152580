import React from 'react';
import classNames from 'classnames';

import styles from './ServicesListItem.module.scss';
import Button from '../Button/Button';
import parseHtml from '@/utils/htmlParserWithCustomLinks';

class ServicesListItem extends React.PureComponent {

	static defaultProps = {
		durationUnits: 'мин',
		currency: '₽',
		buttonTitle: 'Выбрать',
		isShortView: false,
	};

	state = {
		amount: this.props.service.amount ?? 1,
	};

	reduceAmount = () => {
		if (this.state.amount - 1 <= 0) {
			return;
		}
		const newAmount = this.state.amount - 1;
		this.setState({ amount: newAmount });
		this.props.onAmountChange(newAmount);
	};

	increaseAmount = () => {
		if (this.state.amount + 1 > 10) {
			return;
		}
		const newAmount = this.state.amount + 1;
		this.setState({ amount: this.state.amount + 1 });
		this.props.onAmountChange(newAmount);
	};

	handleButtonClick = () => {
		this.props.onButtonClick(this.state.amount);
	};

	render() {
		const {
			service,
			durationUnits,
			isButtonDisabled,
			isButtonSecondary,
			isAmountChangeable,
			buttonTitle,
			isShortView,
			className,
			isSelected,
		} = this.props;

		const { duration, price } = service;
		const vars = service.meta?.templateVars?.pageVars;
		const name = service.meta?.title;

		const { amount } = this.state;

		const containerClassName = classNames({
			[styles.container]: true,
			[styles.short]: isShortView,
			[styles.selected]: isSelected,
			[className]: !!className,
		});

		const buttonClassName = classNames({
			[styles.button]: true,
		});

		let buttonShadowColor = 'pink';
		if (isButtonSecondary) {
			buttonShadowColor = 'transparent';
		}
		if (isButtonDisabled) {
			buttonShadowColor = 'transparent';
		}

		return (
			<div data-typeid={vars.type} className={containerClassName}>
				<div className={styles.mainInfo}>
					<div className={styles.name}>
						<span className={styles.title}>{name}</span>
					</div>
					<div className={styles.attributes}>
						<div className={styles.durationContainer}>
							<div className={styles.duration}>{duration} {durationUnits}</div>
						</div>
						<div className={styles.priceContainer}>
							<div className={styles.priceBg}>
								{isAmountChangeable && <span className={styles.currency}>1 / </span>}
								<span>{price}</span>
								<span className={styles.currency}>₽</span>
							</div>
						</div>
					</div>
				</div>
				{!isShortView &&
					<React.Fragment>
						<div className={styles.additionalInfo}>
							<div className={classNames([styles.description, 'text-content'])}>
								{parseHtml(vars.description)}
							</div>
						</div>
						{isAmountChangeable && <div className={styles.amountContainer}>
							<div className={styles.amountReduce} onClick={this.reduceAmount}></div>
							<div className={styles.amount}>{amount}</div>
							<div className={styles.amountIncrease} onClick={this.increaseAmount}></div>
						</div>}
						<div className={styles.buttonContainer}>
							<Button
								shadowColor={buttonShadowColor}
								disabled={isButtonDisabled}
								onClick={this.handleButtonClick}
								className={buttonClassName}
							>
								{buttonTitle}
							</Button>
						</div>
					</React.Fragment>
				}
			</div>
		);
	}
}

export default ServicesListItem;
