import React from 'react';
import styles from './NewSeasonInfo.module.css';
import NewSeasonContent from '../NewSeasonContent/NewSeasonContent';

class NewSeasonInfo extends React.Component {

	render() {
		return (
			<div className={styles.container}>
				<div className={styles.content}>
					<NewSeasonContent/>
				</div>
			</div>
		);
	}
}

export default NewSeasonInfo;
