import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import Loader from '../../components/Loader';
import CurrentVisit from './CurrentVisit';
import PastVisit from './PastVisit';
import CancelModal from './CancelModal';
import RateModal from './RateModal';

import styles from './Visits.module.scss';
import { cancelVisit, loadVisits, submitVisitReview } from '@/api';
import { Link } from 'react-router-dom';
import StatusModal from '../StatusModal/StatusModal';
import Button from '../Button/Button';
import { initAppointmentAction, setSelectedCityIdAction } from '@/store/actions';
import { useHistory } from 'react-router';
import { usePopulatedAppointments } from '@/hooks/usePopulatedAppointments';
import classNames from 'classnames';
import { usePagination } from '@/hooks/usePagination';

const perPage = 10;

export function Visits() {

	const now = moment();
	const dispatch = useDispatch();
	const history = useHistory();
	const [visits, setVisits] = useState([]);
	const [visitsPage, setVisitsPage] = useState(0);
	const [visitsCount, setVisitsCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [cancelVisitId, setCancelVisitId] = useState(null);
	const [cancelVisitSubmitting, setCancelVisitSubmitting] = useState(false);
	const [rateVisitId, setRateVisitId] = useState(null);
	const [rateVisitSubmitting, setRateVisitSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState(null);

	const populatedVisits = usePopulatedAppointments(visits);

	const futureVisits = useMemo(() => {
		return populatedVisits.filter((v) => {
			return v.status !== 3;
		});
	}, [populatedVisits]);

	const pastVisits = useMemo(() => {
		return populatedVisits.filter((v) => {
			return v.status === 3;
		});
	}, [populatedVisits]);

	const handleCancelClick = useCallback((e, visitId) => {
		setCancelVisitId(visitId);
	}, []);

	const handlePhoneClick = useCallback((e, salon) => {
		e.stopPropagation();
		document.location.href = `tel:+${salon.phone}`;
	}, []);

	const handleRateClick = useCallback((e, visitId) => {
		setRateVisitId(visitId);
	}, []);

	const handleChangeClick = useCallback((e, visit) => {
		dispatch(setSelectedCityIdAction(visit.cityId));
		dispatch(initAppointmentAction({
			id: visit.id,
			salonId: visit.salon ? visit.salonId : null,
			services: visit.services
				.filter((service) => !!service.meta)
				.map((service) => ({
					id: service.id,
					amount: service.amount,
					type: service.type,
				})),
			date: visit.date,
			time: visit.time,
			comment: visit.comment,
		}));
		history.push('#appointment');
	}, [dispatch, history]);

	const handleRepeatClick = useCallback((e, visit) => {
		dispatch(setSelectedCityIdAction(visit.cityId));
		dispatch(initAppointmentAction({
			salonId: visit.salonId,
			services: visit.services
				.filter((service) => !!service.meta)
				.map((service) => ({
					id: service.id,
					amount: service.amount,
				})),
		}));
		history.push('#appointment');
	}, [dispatch, history]);

	const handleCancelModalClose = useCallback(() => {
		setCancelVisitId(null);
	}, []);

	const loadAppointments = useCallback(() => {
		setLoading(true);
		loadVisits(visitsPage, 10)
			.then(({ appointments, total }) => {
				setVisitsCount(total);
				setVisits(appointments);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [visitsPage]);

	const handleCancelModalSubmit = useCallback(async ({ reason, comment }) => {
		setCancelVisitSubmitting(true);
		try {
			await cancelVisit(cancelVisitId, reason, comment);
			await loadAppointments();
			setCancelVisitId(null);
			setCancelVisitSubmitting(false);
		} catch (e) {
			setCancelVisitSubmitting(false);
			setSubmitError(e.message);
		}
	}, [cancelVisitId, loadAppointments]);

	const handleRateModalClose = useCallback(() => {
		setRateVisitId(null);
	}, []);

	const handleRateModalSubmit = useCallback(async ({ answers, comment }) => {
		setRateVisitSubmitting(true);
		try {
			await submitVisitReview(rateVisitId, answers, comment);
			await loadAppointments();
			setCancelVisitSubmitting(false);
			setRateVisitId(null);
		} catch (e) {
			setRateVisitSubmitting(false);
			setSubmitError(e.message);
		}
	}, [rateVisitId, loadAppointments]);

	const dismissSubmitError = useCallback(() => {
		setSubmitError(null);
	}, []);

	const renderPage = useCallback((pageIndex) => {
		const linkClassName = classNames([styles.pageLink, {
			[styles.active]: pageIndex === visitsPage,
		}]);
		return (
			<div key={pageIndex} className={styles.page}>
				<span className={linkClassName} onClick={() => setVisitsPage(pageIndex)}>
					{pageIndex + 1}
				</span>
			</div>
		);
	}, [visitsPage]);

	const pages = usePagination(renderPage, visitsCount, perPage, visitsPage);

	useEffect(() => {
		loadAppointments();
	}, [loadAppointments]);

	const hasVisits = futureVisits.length > 0 || pastVisits.length > 0;

	return (
		<div className={styles.container}>
			<div className={styles.allVisitsContainer}>
				{loading && <Loader/>}
				{!hasVisits && (
					<div className={styles.noVisits}>
						<div>У Вас ещё не было ни одной записи,<br/> но это легко исправить!</div>
						<br/>
					</div>
				)}
				{futureVisits.length > 0 && (
					<>
						<div className={styles.delimiter}>Предстоящие визиты</div>
						<div className={styles.visitsContainer}>
							{futureVisits.map((visit) => <CurrentVisit
								key={visit.id}
								onClickCancel={(e) => handleCancelClick(e, visit.id)}
								onClickCall={(e) => handlePhoneClick(e, visit.salon)}
								onClickChange={(e) => handleChangeClick(e, visit)}
								className={styles.currentVisit}
								{...visit}
							/>)}
						</div>
					</>
				)}
				{pastVisits.length > 0 && (
					<>
						<div className={styles.delimiter}>Прошедшие визиты</div>
						<div className={styles.visitsContainer}>
							{pastVisits.map((visit) => {
								return <PastVisit
									onClickRepeat={(e) => handleRepeatClick(e, visit)}
									onClickReview={(e) => handleRateClick(e, visit.id)}
									reviewDisabled={moment(visit.date).add(2, 'weeks').isBefore(now)}
									key={visit.id}
									{...visit}
								/>;
							})}
						</div>
					</>
				)}
				{(hasVisits && pages.length > 1) && (
					<div className={styles.pages}>
						{pages}
					</div>
				)}
			</div>
			<div className={styles.noVisits}>
				<Link to={'#appointment'}>
					<Button>Записаться</Button>
				</Link>
			</div>
			<CancelModal
				submitting={cancelVisitSubmitting}
				isOpen={cancelVisitId !== null}
				onClose={handleCancelModalClose}
				onSubmit={handleCancelModalSubmit}
			/>
			<RateModal
				submitting={rateVisitSubmitting}
				isOpen={rateVisitId !== null}
				onClose={handleRateModalClose}
				onSubmit={handleRateModalSubmit}
			/>
			<StatusModal
				isOpen={submitError !== null}
				modalText={submitError}
				status={'error'}
				buttonText={'ОК'}
				onClose={dismissSubmitError}
				onButtonClick={dismissSubmitError}
			/>
		</div>
	);
}
