export const setAppointmentSalonAction = (id) => ({ type: 'SET_APPOINTMENT_SALON', payload: id });
export const setAppointmentServicesAction = (clientIndex, services) => ({
	type: 'SET_APPOINTMENT_SERVICES',
	payload: { clientIndex, services },
});

export const addAppointmentServiceAction = (serviceId, amount, clientIndex = 0) => ({
	type: 'ADD_APPOINTMENT_SERVICE',
	payload: {
		clientIndex,
		service: {
			id: serviceId,
			amount,
		},
	},
});

export const removeAppointmentServiceAction = (serviceId, clientIndex = 0) => ({
	type: 'REMOVE_APPOINTMENT_SERVICE',
	payload: { clientIndex, service: serviceId },
});
export const unsetAppointmentServicesAction = (clientIndex) => ({
	type: 'UNSET_APPOINTMENT_SERVICES',
	payload: { clientIndex },
});
export const setAppointmentDateAction = (payload) => ({ type: 'SET_APPOINTMENT_DATE', payload });
export const setAppointmentTimeAction = (payload) => ({ type: 'SET_APPOINTMENT_TIME', payload });
export const setAppointmentAvailableTimeAction = (payload) => ({ type: 'SET_APPOINTMENT_AVAILABLE_TIME', payload });
export const setAppointmentAvailableTimeLoadingAction = (payload) => ({
	type: 'SET_APPOINTMENT_AVAILABLE_TIME_LOADING',
	payload,
});
export const unsetAppointmentAction = () => ({ type: 'UNSET_APPOINTMENT' });
export const initAppointmentAction = (payload) => ({ type: 'INIT_APPOINTMENT', payload });
export const setAppointmentCommentAction = (payload) => ({ type: 'SET_APPOINTMENT_COMMENT', payload });
export const setAppointmentNotification = (payload) => ({ type: 'SET_APPOINTMENT_NOTIFICATION', payload });
