import React from 'react';
import classNames from 'classnames';

import styles from './YandexMapHeader.module.css';
import { Link } from 'react-router-dom';

function YandexMapHeader(props) {

	const ListActiveMap = classNames({
		[styles.textContainerRight]: true,
		[styles.textContainerRightMap]: props.setClass === 'map',
	});

	const ListActivelist = classNames({
		[styles.textContainerRight]: true,
		[styles.textContainerRightList]: props.setClass === 'list',
	});

	return (
		<div className={styles.container}>
			<div className={styles.menuContainer}>
				<div className={styles.insideMenu}>
					<span className={ListActiveMap} onClick={props.onChangeMod}>карта</span>
					<span className={styles.rightContainerSybol}>|</span>
					<span className={styles.rightContainerSybolMobile}>|</span>
					<span className={ListActivelist} onClick={props.onChangeMod}>список</span>
					<span className={styles.rightContainerSybol}>|</span>
					<span className={styles.rightContainerSybolMobile}>|</span>
					<Link to={'/salons'} className={ListActivelist} onClick={props.onChangeMod}>все</Link>
				</div>
			</div>
		</div>
	);
}

export default YandexMapHeader;
