import React from 'react';
import { Radio, RadioGroup } from '@hastom/react-radio-group';

import Modal from '../../components/Modal';
import FlatButton from '../../components/Button/Button';

import styles from './Modals.module.scss';

const Field = ({ title, value }) => {
	return <label className={styles.field} htmlFor={`cancel-reason-${value}`} onClick={() => {
		document.getElementById(`cancel-reason-${value}`).click();
		//Fix fastclick behavior
	}}>
		<Radio className={styles.cancelModalRadio} value={value} id={`cancel-reason-${value}`}/>
		<span className={styles.cancelModalCheckmark}/>
		<span className={styles.cancelModalRadioTitle}>{title}</span>
	</label>;
};

class CancelModal extends React.Component {

	static defaultProps = {
		isOpen: false,
		submitting: false,
	};

	state = {
		reason: 1,
		comment: '',
	};

	handleReasonChange = (reason) => {
		this.setState({ reason });
	};

	handleCommentChange = (e) => {
		this.setState({ comment: e.target.value });
	};

	handleButtonClick = async () => {
		await this.props.onSubmit({ ...this.state });
		this.setState({ reason: 1, comment: '' });
	};

	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				className={styles.container}
				title={'Выберите, пожалуйста, причину отмены записи'}
				onClose={this.props.onClose}
			>
				<RadioGroup
					className={styles.fieldsContainer}
					selectedValue={this.state.reason}
					onChange={this.handleReasonChange}
					name={'reason'}
				>
					<Field value={1} title={'Записались по ошибке.'}/>
					<Field value={4} title={'Не успеваю, буду записываться на другое время.'}/>
					<Field value={6} title={'Воспользуюсь услугами другого салона.'}/>
					<Field value={2} title={'Другая причина.'}/>
					<div className={styles.field}>
						<textarea
							className={styles.textarea}
							value={this.state.comment}
							onChange={this.handleCommentChange}
							placeholder={'Комментарий'}
						/>
					</div>
					<FlatButton
						disabled={this.props.submitting}
						onClick={this.handleButtonClick}
						className={styles.button}
					>
						Отменить
					</FlatButton>
				</RadioGroup>
			</Modal>
		);
	}
}

export default CancelModal;
