const initialState = {
	services: [],
	salons: [],
	settings: null,
	page: null,
	selectedCityId: null,
	pageLoading: false,
	firstStart: false,
	news: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'SET_NEWS': {
			return {
				...state,
				news: payload,
			};
		}
		case 'SET_SERVICES': {
			return {
				...state,
				services: payload,
			};
		}
		case 'SET_SALONS': {
			return {
				...state,
				salons: payload,
			};
		}
		case 'SET_SETTINGS': {
			return {
				...state,
				settings: payload,
			};
		}
		case 'SET_SELECTED_CITY_ID': {
			if (payload === state.selectedCityId) {
				return state;
			}
			return {
				...state,
				selectedCityId: payload,
			};
		}
		case 'SET_PAGE_LOADING': {
			return {
				...state,
				pageLoading: payload,
			};
		}
		case 'SET_PAGE': {
			return {
				...state,
				page: payload,
			};
		}
		case 'SET_FIRST_START': {
			return {
				...state,
				firstStart: payload,
			};
		}
		case 'SET_APP_DATA': {
			return {
				...state,
				...payload,
			};
		}
		default:
			return state;
	}
};
