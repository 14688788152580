import React from 'react';
import styles from '@/components/Forms/Form.module.scss';
import ShadowInput from '@/components/ShadowInput/ShadowInput';
import Button from '@/components/Button/Button';
import { focusMaskStart, phoneInputMask } from '@/utils/masks';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import SmartLink from '@/components/SmartLink';

function QuickSignUpForm({ data = {}, onSubmit, isSubmitting }) {

	const [formData, setFormData] = React.useState({
		firstName: data.firstName || '',
		lastName: data.lastName || '',
		phone: data.phone || '',
		maskedPhone: '',
	});

	const [errors, setErrors] = React.useState({
		firstName: false,
		lastName: false,
		phone: false,
	});

	const handleSubmit = React.useCallback((e) => {
		e.preventDefault();

		const errors = {};
		let hasErrors = false;

		if (!formData.firstName.length) {
			errors.firstName = true;
			hasErrors = true;
		}

		if (formData.phone.length !== 11) {
			errors.phone = true;
			hasErrors = true;
		}

		setErrors(errors);

		if (hasErrors) {
			return;
		}

		onSubmit({ ...formData });
	}, [formData, onSubmit]);

	const setFormField = React.useCallback((name, value) => {
		setFormData((data) => ({ ...data, [name]: value }));
		setErrors((errors) => ({ ...errors, [name]: false }));
	}, []);

	const handlePhoneInputBlur = (e) => {
		if (e.target.value.replace(/\D/g, '') === '7') {
			setFormField('phone', '');
			setFormField('maskedPhone', '');
		}
	};

	const handlePhoneInputChange = (value) => {
		setFormField('maskedPhone', value);
		setFormField('phone', value.replace(/\D/g, ''));
	};

	const actionDisalbed = isSubmitting || !formData.phone.length || !formData.firstName.length;

	return (
		<form noValidate onSubmit={handleSubmit} className={styles.form}>
			<div className={styles.fields}>
				<ShadowInput
					onChange={(value) => setFormField('firstName', value)}
					value={formData.firstName}
					error={errors.firstName}
					className={styles.field}
					name={'first-name'}
					shadowColor={'pink'}
					placeholder={'Имя *'}
				/>
				<ShadowInput
					onChange={(value) => setFormField('lastName', value)}
					value={formData.lastName}
					error={errors.lastName}
					className={styles.field}
					shadowColor={'pink'}
					name={'last-name'}
					placeholder={'Фамилия'}
				/>
				<ShadowInput
					onFocus={focusMaskStart}
					onBlur={handlePhoneInputBlur}
					onChange={handlePhoneInputChange}
					value={formData.maskedPhone}
					error={errors.phone}
					type={'tel'}
					name={'phone'}
					mask={phoneInputMask}
					className={styles.field}
					shadowColor={'satin'}
					placeholder={'Мобильный телефон *'}
				/>
				<div className={classNames([styles.field, styles.personal])}>
					<div>
						<span>Нажимая на кнопку &quot;Продожить&quot; я даю своё согласие на обработку моей персональной информации на
							условиях, определенных </span>
						<SmartLink href={'/personal'} target={'_blank'} rel="noopener noreferrer">
							Политикой конфидициальности.
						</SmartLink>
					</div>
				</div>
			</div>
			<div className={styles.actions}>
				<Button
					type={'submit'}
					disabled={actionDisalbed}
					className={styles.action}
				>
					Продолжить
				</Button>
				<div className={styles.link}>
					<span>Есть аккаунт? </span>
					<Link to={'?to=appointment#signin'}>
						Войти
					</Link>
				</div>
			</div>
		</form>
	);
}

export default QuickSignUpForm;
