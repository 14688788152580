import { useCallback, useEffect, useState } from 'react';
import RequestError from '@/utils/RequestError';

export default function useFetch(fetchFn, deps = []) {

	const [isLoading, setLoading] = useState(false);
	const [response, setResponse] = useState({ error: null, result: undefined });

	const fetcher = useCallback(async () => {
		setLoading(true);
		try {
			const result = await fetchFn();
			setResponse({ result, error: null });
			setLoading(false);
		} catch (e) {
			if (e instanceof RequestError) {
				setResponse({ result: undefined, error: e });
				setLoading(false);
				return;
			}
			throw e;
		}
	}, [fetchFn]);

	useEffect(() => {
		fetcher();
	}, deps);

	return { isLoading, refetch: fetcher, response };
}
