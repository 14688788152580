export const waitRecaptcha = async () => {
	const exists = window.document.head.querySelector('[data-recaptcha]');
	if (!exists) {
		const tag = window.document.createElement('script');
		tag.src = 'https://www.google.com/recaptcha/api.js?render=6Lcug4waAAAAAAaKrIj0VQRvQMbQhhalzVl6uswq';
		tag.async = true;
		window.document.head.appendChild(tag);
		let waitTimeout;
		let waitInterval;
		await Promise.race([
			new Promise((resolve, reject) => {
				waitTimeout = setTimeout(() => {
					clearInterval(waitInterval);
					reject(new Error('timeout loading'));
				}, 5000);
			}),
			new Promise((resolve) => {
				waitInterval = setInterval(() => {
					if (window.grecaptcha) {
						clearTimeout(waitTimeout);
						clearInterval(waitInterval);
						resolve();
					}
				}, 50);
			}),
		]);
	}
	return new Promise((resolve) => {
		window.grecaptcha.ready(resolve);
	});
};
