import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { conformToMask } from 'react-text-mask';

import { signIn } from '../../api';

import ShadowInput from '../ShadowInput/ShadowInput';
import Button from '../Button/Button';
import StatusModal from '../StatusModal/StatusModal';

import formStyles from './SignInForm.module.scss';
import styles from './Form.module.scss';

class SignInForm extends React.Component {

	state = {
		formData: {
			phone: '',
			password: '',
		},
		formError: null,
		loading: false,
	};

	setFormData = (field, value) => {
		this.setState({
			formData: {
				...this.state.formData,
				[field]: value,
			},
		});
	};

	handlePhoneInputFocus = (e) => {
		e.persist();
		setTimeout(() => {
			const startIndex = e.target.value.indexOf('_');
			if (startIndex !== -1) {
				e.target.setSelectionRange(startIndex, startIndex);
			}
		}, 0);
	};

	handlePhoneInputBlur = (e) => {
		if (e.target.value.replace(/\D/g, '') === '7') {
			this.setFormData('phone', '');
		}
	};

	handlePhoneInputPaste = (e) => {
		e.preventDefault();
		const value = e.clipboardData.getData('Text').replace(/\D/g, '');
		if (value[0] === '7') {
			const { conformedValue } = conformToMask('+' + value, this.phoneInputMask);
			this.setFormData('phone', conformedValue);
		} else if (value[0] === '8') {
			const { conformedValue } = conformToMask('+7' + value.slice(1), this.phoneInputMask);
			this.setFormData('phone', conformedValue);
		} else {
			const { conformedValue } = conformToMask(value, this.phoneInputMask);
			this.setFormData('phone', conformedValue);
		}
	};

	handlePhoneInputChange = (value) => {
		this.setFormData('phone', value);
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		const phone = this.state.formData.phone.replace(/\D/g, '');
		if (!phone) {
			this.setState({ formError: 'Не указан номер телефона' });
			return;
		}
		if (!this.state.formData.password) {
			this.setState({ formError: 'Не указан пароль' });
			return;
		}
		this.setLoading(true);
		try {
			const token = await signIn({
				...this.state.formData,
				phone,
			});
			this.setLoading(false);
			this.props.onSuccess(token);
		} catch (e) {
			this.setState({ formError: e.message });
			this.setLoading(false);
		}
	};

	dismissErrorModal = () => {
		this.setState({
			formError: null,
		});
	};

	setLoading = (loading) => {
		this.setState({ loading });
	};

	phoneInputMask = ['+', '7', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

	render() {
		return (
			<form noValidate onSubmit={this.handleSubmit} className={styles.form}>
				<div className={styles.fields}>
					<ShadowInput
						className={styles.field}
						type={'tel'}
						value={this.state.formData.phone}
						onFocus={this.handlePhoneInputFocus}
						onBlur={this.handlePhoneInputBlur}
						onChange={this.handlePhoneInputChange}
						onPaste={this.handlePhoneInputPaste}
						mask={this.phoneInputMask}
						shadowColor={'yellow'}
						placeholder={'Телефон'}/>
					<ShadowInput
						lang={'en'}
						onChange={(value) => this.setFormData('password', value)}
						value={this.state.formData.password}
						name={'password'}
						type={'password'}
						className={styles.field}
						shadowColor={'pink'}
						placeholder={'Пароль'}
					/>
					<Link
						className={classNames([styles.link, formStyles.signUpLink])}
						to={'#signup'}>
						Регистрация
					</Link>
				</div>
				<div className={styles.actions}>
					<Button
						type={'submit'}
						className={styles.action}
					>
						Войти
					</Button>
					<Link
						className={classNames([styles.link, formStyles.recoverLink])}
						to={'#recover'}>
						Восстановить пароль
					</Link>
				</div>
				<StatusModal
					status={'error'}
					modalText={this.state.formError}
					buttonText={'ОК'}
					onClose={this.dismissErrorModal}
					onButtonClick={this.dismissErrorModal}
					isOpen={this.state.formError !== null}/>
			</form>
		);
	}
}

export default SignInForm;
