import React from 'react';

import NewsList from '../../components/NewsList/NewsList';

import styles from './News.module.scss';
import YmapAndSalons from '../../components/SalonsListController/SalonsListController';

export const News = (props) => {
	const page = props.content;
	return (
		<div className={styles.container}>
			<h1 className={styles.title}>{page.title}</h1>
			<div className={styles.news}>
				<NewsList/>
			</div>
			<YmapAndSalons withCityTitle/>
		</div>
	);
};
