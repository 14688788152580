import React from 'react';
import classNames from 'classnames';

import UnauthenticatedIcon from '../../assets/avatar-placeholder-empty.svg';
import Avatarplaceholder from '../../assets/avatar-placeholder-f.png';
import ImageLoader from '../ImageLoader/ImageLoader';

import styles from './Avatar.module.scss';


class Avatar extends React.Component {

	render() {
		const { user, className } = this.props;
		let imageScr = UnauthenticatedIcon;
		if (user) {
			imageScr = Avatarplaceholder;
			if (user.avatar) {
				imageScr = user.avatar;
			}
		}
		return <div className={classNames([styles.container, className])}>
			<ImageLoader src={imageScr} className={styles.image} title={'Профиль пользователя'} alt={'Аватар'}/>
		</div>;
	}
}

export default Avatar;
