import { Redirect, Route } from 'react-router';
import React from 'react';

function SSRRedirect({ from, to, status }) {
	return (
		<Route
			render={({ staticContext }) => {
				if (staticContext) {
					staticContext.status = status;
				}
				return <Redirect from={from} to={to}/>;
			}}
		/>
	);
}

export default SSRRedirect;
