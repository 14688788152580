const initialState = {
	id: null,
	salonId: null,
	services: {
		0: [],
	},
	comment: '',
	date: null,
	time: null,
	notification: null,
	availableTime: null,
	availableTimeLoading: false,
};

const addService = (state, payload) => {
	const { service, clientIndex } = payload;
	const { id } = service;
	const clientServices = [...state.services[clientIndex]] || [];
	const selectedItemIndex = clientServices.findIndex((item) => item.id === id);
	if (selectedItemIndex !== -1) {
		clientServices[selectedItemIndex] = service;
	} else {
		clientServices.push(service);
	}
	return {
		...state,
		services: {
			...state.services,
			[payload.clientIndex]: clientServices,
		},
	};
};

const removeService = (state, payload) => {
	const { clientIndex, service } = payload;
	const current = state.services[clientIndex] || [];
	const removeIndex = current.findIndex((item) => item.id === service);
	if (removeIndex === -1) {
		return state;
	}
	const newServices = [...current];
	newServices.splice(removeIndex, 1);
	let services = {
		...state.services,
		[payload.clientIndex]: newServices,
	};
	if (Object.keys(services).length === 1 && !services[0].length) {
		services = {
			0: [],
		};
	}
	return {
		...state,
		services,
	};
};

const unsetServices = (state, payload) => {
	let services = Object.entries(state.services).reduce((acc, [clientIndex, selectedServices]) => {
		if (Number(clientIndex) !== payload.clientIndex) {
			acc[Object.keys(acc).length] = selectedServices;
		}
		return acc;
	}, {});
	if (Object.keys(services).length === 1 && !services[0].length) {
		services = {
			0: [],
		};
	}
	return {
		...state,
		services,
	};
};

const setServices = (state, payload) => {
	return {
		...state,
		services: {
			...state.services,
			[payload.clientIndex]: payload.services,
		},
	};
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'SET_APPOINTMENT_SALON':
			if (state.salonId === payload) {
				return state;
			}
			return {
				...state,
				salonId: payload,
			};
		case 'SET_APPOINTMENT_SERVICES':
			return setServices(state, payload);
		case 'UNSET_APPOINTMENT_SERVICES':
			return unsetServices(state, payload);
		case 'ADD_APPOINTMENT_SERVICE':
			return addService(state, payload);
		case 'REMOVE_APPOINTMENT_SERVICE':
			return removeService(state, payload);
		case 'SET_APPOINTMENT_DATE':
			return {
				...state,
				date: payload,
			};
		case  'SET_APPOINTMENT_TIME':
			return {
				...state,
				time: payload,
			};
		case 'SET_APPOINTMENT_AVAILABLE_TIME_LOADING':
			return {
				...state,
				availableTimeLoading: payload,
			};
		case 'SET_APPOINTMENT_AVAILABLE_TIME':
			return {
				...state,
				availableTime: payload,
			};
		case 'SET_APPOINTMENT_COMMENT':
			return {
				...state,
				comment: payload,
			};
		case 'SET_APPOINTMENT_NOTIFICATION':{
			return {
				...state,
				notification: payload,
			};
		}
		case 'INIT_APPOINTMENT': {
			return {
				id: payload.id || null,
				salonId: payload.salonId || null,
				services: payload.services ? Array.isArray(payload.services) ? { 0: payload.services } : payload.services : {},
				date: payload.date || null,
				time: payload.time || null,
				notification: payload.notification || null,
				availableTime: null,
				comment: payload.comment,
			};
		}
		case 'SIGN_OUT': {
			return {
				...initialState,
			};
		}
		case 'UNSET_APPOINTMENT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};
