import React, { useCallback, useState } from 'react';
import styles from './GiftCard.module.scss';
import Button from '@/components/Button/Button';
import Modal from '@/components/Modal';
import CertificateRecipientForm from '@/components/Forms/CertificateRecipientForm';

function GiftCardsList(props) {
	const { onSelected, certificates } = props;
	const [preorder, setPreorder] = useState(null);

	const handleBuy = useCallback((certificate) => {
		const preorder = { certificate, amount: 1, recipient: { email: '', name: '', phone: '' } };
		setPreorder(preorder);
	}, []);

	const handleSubmitPreorder = useCallback((recipient) => {
		onSelected({ ...preorder, recipient });
		setPreorder(null);
	}, [preorder, onSelected]);


	return (
		<div className={styles.certificates}>
			{certificates.map(({ card: certificate }) => {
				return (
					<div
						key={certificate.id}
						className={styles.certificateContainer}
						onClick={() => handleBuy(certificate)}
					>
						<div className={styles.certificateImageContainer}>
							<img alt={certificate.name} className={styles.certificateImage} src={certificate.image}/>
						</div>
						<Button>Купить</Button>
					</div>
				);
			})}
			{preorder && (
				<Modal
					title={'Кому отправляем сертификат?'}
					isOpen
					onClose={() => setPreorder(null)}
				>
					<CertificateRecipientForm
						onSubmit={handleSubmitPreorder}
						data={preorder.recipient}
					/>
				</Modal>
			)}
		</div>
	);
}

export default GiftCardsList;
