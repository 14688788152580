import { useSelector } from 'react-redux';
import { getSalonsIndex, getServicesIndex } from '@/store/selectors';
import { useMemo } from 'react';

export const usePopulatedAppointments = (appointments) => {
	const servicesIndex = useSelector(getServicesIndex);
	const salonsIndex = useSelector(getSalonsIndex);
	return useMemo(() => {
		if (!servicesIndex || !salonsIndex) {
			return [];
		}
		return appointments.map((a) => {
			if (!salonsIndex[a.salonId]) {
				console.warn(`salon ${a.salonId} not found`);
			}
			return {
				...a,
				salon: salonsIndex[a.salonId] ?? null,
				services: a.services.map((s) => {
					if (!servicesIndex[s.id]) {
						console.warn(`service ${s.id} not found`);
					}
					return {
						...servicesIndex[s.id],
						...s,
					};
				}),
			};
		});
	}, [appointments, servicesIndex, salonsIndex]);
};
