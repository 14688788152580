const chunk = (arr, len) => {
	const chunks = [];
	let i = 0;
	const n = arr.length;

	while (i < n) {
		chunks.push(arr.slice(i, (i += len)));
	}

	return chunks;
};

export default chunk;
