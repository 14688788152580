const getCurrentPosition = () => {
	return new Promise((resolve, reject) => {
		window.navigator.geolocation.getCurrentPosition(resolve, reject);
	});
};

export const getBrowserGeolocation = async () => {
	if (typeof window !== 'undefined') {
		try {
			const { state } = await window.navigator.permissions.query({ name: 'geolocation' });
			switch (state) {
				case 'denied':
					return null;
				case 'prompt':
				case 'granted': {
					const geo = await getCurrentPosition();
					return [geo.coords.latitude, geo.coords.longitude];
				}
			}
		} catch (e) {
			return null;
		}
	}
};
