import React from 'react';

import styles from './Slider.module.scss';

class Slider extends React.PureComponent {

	static defaultProps = {
		sense: 20,
		maxTransitionDuration: 250,
		disableTransition: false,
		page: 0,
	};

	state = {
		translateX: 0,
		transition: 'none',
	};

	touchStartX = 0;

	startPos = 0;

	currentPage = 0;


	getPageByTranslate = (translateX) => {
		return Math.round(translateX / this.props.itemWidth);
	};

	getTranslateByPage = (page) => {
		return page * this.props.itemWidth;
	};

	calcContainerWidth = () => {
		return this.props.itemWidth * React.Children.count(this.props.children);
	};

	setTranslate = (translateX, withTransition = false, noEvent = false) => {
		let transition = 'none';
		if (withTransition) {
			const currentTranslate = this.state.translateX;
			const transitionDuration = ((this.props.maxTransitionDuration * (Math.abs(translateX - currentTranslate)) / this.props.itemWidth) / 1000).toFixed(2);
			transition = `transform ${transitionDuration}s linear`;
		}
		this.setState({ translateX, transition });
		if (this.props.onTranslateChange && !noEvent) {
			this.props.onTranslateChange(translateX, withTransition);
		}
	};

	handleTouchStart = (e) => {
		const touch = e.touches[0];
		this.touchStartX = touch.clientX;
		this.touchStartY = touch.clientY;
		this.startPos = this.state.translateX;
	};

	handleTouchMove = (e) => {
		const touch = e.touches[0];
		const diffX = this.touchStartX - touch.clientX;
		const diffY = this.touchStartY - touch.clientY;
		if (Math.abs(diffX) < 10 && Math.abs(diffY) > 20) {
			return;
		}
		let translateX = this.startPos + diffX;
		if (translateX < 0) {
			translateX = 0;
		}
		if (translateX > this.containerWidth - this.props.itemWidth) {
			translateX = this.containerWidth - this.props.itemWidth;
		}
		if (Math.round(translateX) !== this.state.translateX) {
			this.setTranslate(translateX);
		}
	};

	handleTouchEnd = () => {
		const translateX = this.state.translateX;
		const movedBy = translateX - this.startPos;
		let fixedTranslateX;
		if (movedBy < -this.props.sense) {
			fixedTranslateX = translateX - translateX % this.props.itemWidth;
		} else if (movedBy > this.props.sense) {
			fixedTranslateX = translateX + this.props.itemWidth - translateX % this.props.itemWidth;
		} else {
			fixedTranslateX = translateX - movedBy;
		}
		if (fixedTranslateX !== translateX) {
			this.setTranslate(fixedTranslateX, true);
		}
		const newPage = this.getPageByTranslate(fixedTranslateX);
		if (newPage !== this.currentPage) {
			this.currentPage = newPage;
			this.props.onPageChange(newPage);
		}
		this.startPos = this.state.translateX;
	};

	recalc = () => {
		this.containerWidth = this.calcContainerWidth();
		this.setTranslate(this.getTranslateByPage(this.props.page));
	};

	componentDidMount() {
		this.containerWidth = this.calcContainerWidth();
		if (this.props.page !== this.currentPage) {
			this.currentPage = this.props.page;
			this.setTranslate(this.getTranslateByPage(this.props.page));
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.children.length !== this.props.children.length || prevProps.itemWidth !== this.props.itemWidth) {
			this.recalc();
		}
		if (prevProps.page !== this.props.page && this.props.page !== this.currentPage) {
			this.currentPage = this.props.page;
			this.setTranslate(this.getTranslateByPage(this.props.page), true);
		}
	}

	render() {
		const containerWidth = this.calcContainerWidth();

		return (
			<div
				style={{
					transform: `translateX(${-this.state.translateX}px)`,
					transition: this.props.disableTransition ? 'none' : this.state.transition,
					width: `${containerWidth}px`,
					flex: `0 0 ${containerWidth}px`,
				}}
				onTouchEnd={this.handleTouchEnd}
				onTouchStart={this.handleTouchStart}
				onTouchMove={this.handleTouchMove}
				className={styles.scroller}>
				{this.props.children}
			</div>
		);
	}
}

export default Slider;
