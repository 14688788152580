import React from 'react';
import { useSelector } from 'react-redux';

import { getSettings } from '@/store/selectors';
import NewSeasonText from '../NewSeasonText/NewSeasonText';
import styles from './NewSeasonContent.module.css';

function NewSeasonContent() {
	const { footerBlock1, footerBlock2, footerBlock3 } = useSelector(getSettings);
	return (
		<div className={styles.container}>
			<NewSeasonText title={footerBlock1.title} content={footerBlock1.content} type={'one'}/>
			<NewSeasonText title={footerBlock2.title} content={footerBlock2.content} type={'two'}/>
			<NewSeasonText title={footerBlock3.title} content={footerBlock3.content} type={'three'}/>
		</div>
	);
}

export default NewSeasonContent;
