import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import SalonInfo from '@/components/SalonInfo/SalonInfo';
import YandexMap from '@/components/MapSalons/MapSalons';
import ImageLoader from '@/components/ImageLoader/ImageLoader';

import styles from './SalonDetails.module.scss';
import smallMap from '../../assets/small-map.png';
import Arrow from '@/components/Arrow/Arrow';
import parseHtml from '@/utils/htmlParserWithCustomLinks';
import MainMenu from '@/components/MainMenu/MainMenu';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { YMaps } from '@pbe/react-yandex-maps';
import { setAppointmentSalonAction } from '@/store/actions';
import { Picture } from '@/components/Picture';

function Metrika(props) {
	return (
		<Helmet>
			<script type="text/javascript">
				{`
(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
m[i].l=1*new Date();
for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
ym(${props.id}, "init", {
clickmap:true,
trackLinks:true,
accurateTrackBounce:true,
webvisor:true
});`}
			</script>
		</Helmet>
	);
}

function SalonDetails({ content, onSelectSalon }) {
	const location = useLocation();
	const search = useMemo(() => {
		return new URLSearchParams(location.search);
	}, [location.search]);
	const history = useHistory();
	const category = search.get('category');
	const defaultMenuCategory = useMemo(() => {
		if (!category) {
			return 1;
		}
		switch (category) {
			case 'complex': {
				return 3;
			}
			case 'manicure': {
				return 1;
			}
			case 'pedicure': {
				return 2;
			}
		}
		return undefined;
	}, [category]);
	const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);
	const [thumbsScrollState, setThumbsScrollState] = React.useState({
		atLeft: true,
		atRight: false,
		position: 0,
	});
	const thumbsContainerRef = React.useRef(null);
	const thumbsRef = React.useRef(null);
	const page = content;
	const vars = page.templateVars.pageVars;
	const { salon, images = [] } = vars;
	let salonImage;

	if (vars.images.length > 0) {
		const image = vars.images.find((i) => i.type === 'image');
		salonImage = image.image.find((i) => i.type === 'fullscreen-mobile');
	}

	const newArrImages = useMemo(() => {
		return [...images, {
			mediaId: 89,
			type: 'map',
			media: smallMap,
			imageAlt: 'smallMap',
			title: 'map',
			thumbnail: smallMap,
		}];
	}, [images]);

	const selectedImage = newArrImages[selectedImageIndex];
	let currentSlideComponent;
	if (selectedImage) {
		switch (selectedImage.type) {
			case 'image':
				currentSlideComponent =
					<Picture
						image={selectedImage.image}
						desktopType={'fullscreen-desktop'}
						mobileType={'fullscreen-mobile'}
						className={styles.currentSlide}
						imageClassName={styles.currentSlideImage}
						alt={selectedImage.imageAlt}
						preload={true}
					/>;
				break;
			case 'youtube':
				/* eslint-disable */
				const videoId = selectedImage.youtubeLink.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?(?:.*&)?v=|(?:embed|v)\/))([^\?&"'>]+)/);
				const src = `https://www.youtube.com/embed/${videoId[1]}?modestbranding=1&autohide=1&showinfo=0&disablekb=1&loop=1`;
				/* eslint-enable */
				currentSlideComponent =
					<div className={styles.currentSlide}>
						<iframe
							allow="autoplay; encrypted-media"
							title={selectedImage.title}
							className={styles.currentSlideImage}
							src={src}
						/>
					</div>;
				break;

			case 'map':
				currentSlideComponent =
					<YMaps version={'2.1'}>
						<YandexMap lazy={false} withBalloons={false} salon={vars}/>
					</YMaps>;
				break;

			default:
				break;

		}
	}

	const handleArrowClick = React.useCallback((direction) => {
		const containerWidth = thumbsContainerRef.current.clientWidth;
		const thumbsWidth = thumbsRef.current.clientWidth;
		setThumbsScrollState((state) => {
			const nextPosition = Math.max(containerWidth - thumbsWidth, Math.min(0, state.position - direction * containerWidth));
			return {
				atLeft: nextPosition === 0,
				atRight: nextPosition === containerWidth - thumbsWidth,
				position: nextPosition,
			};
		});
	}, []);

	const updateCategory = useCallback((id) => {
		let category = '';
		switch (+id) {
			case 1: {
				category = 'manicure';
				break;
			}
			case 2: {
				category = 'pedicure';
				break;
			}
			case 3: {
				category = 'complex';
				break;
			}
		}
		history.push({
			search: `category=${category}`,
		});
	}, [history]);

	React.useEffect(() => {
		const containerWidth = thumbsContainerRef.current.clientWidth;
		const thumbsWidth = thumbsRef.current.clientWidth;
		setThumbsScrollState({
			atLeft: true,
			atRight: containerWidth >= thumbsWidth,
			position: 0,
		});
	}, []);

	const {
		parking = false,
		coffee = false,
		wifi = false,
		ramp = false,
		kids = false,
		brows = false,
	} = salon;
	const hasOptions = parking || coffee || wifi || ramp || kids || brows;
	const descriptionParsed = parseHtml(vars.description);

	return (
		<div className={styles.container}>
			<Helmet>
				<meta property={'og:type'} content={'website'}/>
				{salonImage && (
					<meta property={'og:image'} content={salonImage.link}/>
				)}
				{salonImage && (
					<meta property={'og:image:width'} content={salonImage.resolution.split('x')[0]}/>
				)}
				{salonImage && (
					<meta property={'og:image:height'} content={salonImage.resolution.split('x')[1]}/>
				)}
			</Helmet>
			{salon.id === 91 && (
				<Metrika id={'91309700'}/>
			)}
			{salon.id === 43 && (
				<Metrika id={'91085603'}/>
			)}
			{salon.id === 87 && (
				<Metrika id={'91083006'}/>
			)}
			{salon.id === 97 && (
				<Metrika id={'92257989'}/>
			)}
			{salon.id === 142 && (
				<Metrika id={'96438552'}/>
			)}
			<div className={styles.mainInfo}>
				<div ref={thumbsContainerRef} className={styles.slider}>
					{currentSlideComponent}
					<div className={styles.thumbsContainer}>
						<div
							className={classNames([styles.thumbsScroller, { [styles.hidden]: thumbsScrollState.atLeft }])}
							data-direction={'left'}
							onClick={() => handleArrowClick(-1)}
						>
							<Arrow
								bigOne
								direction={'left'}
							/>
						</div>
						<div
							ref={thumbsRef}
							className={styles.thumbnails}
							style={{ transform: `translateX(${thumbsScrollState.position}px)`, transition: 'transform .1s linear' }}
						>
							{newArrImages.map((image, index) => {
								const thumbClassName = classNames([styles.thumb, {
									[styles.active]: index === selectedImageIndex,
								}]);
								if (image.type === 'image') {
									return (
										<Picture
											key={index}
											image={image.image}
											desktopType={'preview'}
											mobileType={'preview'}
											className={thumbClassName}
											imageClassName={styles.thumbImage}
											alt={image.imageAlt}
											onClick={() => setSelectedImageIndex(index)}
										/>
									);
								}
								if (image.type === 'youtube') {
									const videoId = image.youtubeLink.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?(?:.*&)?v=|(?:embed|v)\/))([^\?&"'>]+)/);
									const preview = videoId[1] ? `https://img.youtube.com/vi/${videoId[1]}/0.jpg` : '';
									return (
										<ImageLoader
											key={index}
											src={preview}
											className={thumbClassName}
											imageClassName={styles.thumbImage}
											onClick={() => setSelectedImageIndex(index)}
										/>
									);
								}
								return (
									<ImageLoader
										key={index}
										src={image.thumbnail}
										className={thumbClassName}
										imageClassName={styles.thumbImage}
										onClick={() => setSelectedImageIndex(index)}
									/>
								);
							})}
						</div>
						<div
							className={classNames([styles.thumbsScroller, { [styles.hidden]: thumbsScrollState.atRight }])}
							data-direction={'right'}
							onClick={() => handleArrowClick(1)}
						>
							<Arrow
								bigOne
								direction={'right'}
							/>
						</div>
					</div>
				</div>
				<div className={styles.titleContainer}>
					<h1 className={styles.title}>{page.title}</h1>
				</div>
				<div className={styles.salon}>
					<div className={styles.info}>
						<SalonInfo meta={page} salon={salon} onSelect={onSelectSalon}/>
					</div>
				</div>
			</div>
			<MainMenu onlyMainCategories defaultCategory={defaultMenuCategory} onChangeCategory={updateCategory}/>
			<div className={styles.additional}>
				<div className={styles.additionalTitle}>
					Дополнительная информация
				</div>
				{hasOptions && <div className={classNames([styles.props, styles.options])}>
					<ul className={styles.value}>
						{kids && <li
							data-icon={'kids'}
							title={'Детский уголок'}
							className={classNames([styles.option, { [styles.active]: kids }])}
						>
							Детский уголок
						</li>}
						{coffee && <li
							data-icon={'coffee'}
							title={'Чай / Кофе'}
							className={classNames([styles.option, { [styles.active]: coffee }])}
						>
							Чай / Кофе
						</li>}
						{ramp && <li
							data-icon={'ramp'}
							title={'Пандус для инвалидов'}
							className={classNames([styles.option, { [styles.active]: ramp }])}
						>
							Пандус для инвалидов
						</li>}
						{parking && <li
							data-icon={'parking'}
							title={'Городская парковка'}
							className={classNames([styles.option, { [styles.active]: parking }])}
						>
							Городская парковка
						</li>}
						{wifi && <li
							data-icon={'wifi'}
							title={'Бесплатный WiFi'}
							className={classNames([styles.option, { [styles.active]: wifi }])}
						>
							Бесплатный WiFi
						</li>}
						{brows && <li
							data-icon={'brows'}
							title={'Услуги бровиста'}
							className={classNames([styles.option, { [styles.active]: brows }])}
						>
							Услуги бровиста
						</li>}
					</ul>
				</div>}
				<div className={classNames([styles.props, styles.description])}>
					<div className={styles.value} itemProp="description">
						{descriptionParsed}
					</div>
				</div>
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		onSelectSalon: (id) => dispatch(setAppointmentSalonAction(id)),
	};
};

export default connect(null, mapDispatchToProps)(SalonDetails);
