import React from 'react';
import { Route } from 'react-router';

export default function PageNotFound() {
	return <Route render={({ staticContext }) => {
		if (staticContext) {
			staticContext.status = 404;
		}
		return <div className={'page-not-found'}>
			<h1>
				404
			</h1>
			<div>
				Страница не найдена
			</div>
		</div>;
	}
	}/>;
}
