import React from 'react';
import classNames from 'classnames';

import styles from './ServicesSubCategory.module.scss';

class ServicesSubCategory extends React.PureComponent {

	expand = () => {
		this.props.onExpand();
	};

	collapse = () => {
		this.props.onCollapse();
	};

	toggle = () => {
		this.props.isExpanded ? this.collapse() : this.expand();
	};

	render() {
		const { name, expanded, collapsed, isExpanded } = this.props;
		const containerClassName = classNames([styles.container, { [styles.expanded]: isExpanded }]);
		const hasActiveChildren = !!((isExpanded && React.Children.count(expanded)) || (!isExpanded && React.Children.count(collapsed)));
		return (
			<div className={containerClassName}>
				<div className={styles.header} onClick={this.toggle}>
					<span className={styles.name}>{name}</span>
					<i className={styles.arrow}/>
				</div>
				{hasActiveChildren && <div className={styles.children}>
					{isExpanded ? expanded : collapsed}
				</div>}
			</div>
		);
	}
}

export default ServicesSubCategory;
