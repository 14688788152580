import React from 'react';
import classNames from 'classnames';
import styles from './HeaderBtn.module.css';

function HeaderBtn({ onClick, className }) {
	return (
		<button onClick={onClick} className={classNames([styles.headerBtn, className])}>
			Записаться
		</button>
	);
}

export default HeaderBtn;
