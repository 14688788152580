import { createSelector } from 'reselect';
import { indexArray } from '@/utils/indexArray';

export const getSalons = (state) => state.data.salons;
export const getServices = (state) => state.data.services;
export const getSettings = (state) => state.data.settings;
export const getSelectedCityId = (state) => state.data.selectedCityId;
export const getPage = (state) => state.data.page;
export const getPageLoading = (state) => state.data.pageLoading;
export const getIsFirstStart = (state) => state.data.firstStart;
export const getCachedNews = (state) => state.data.news;

export const getCities = createSelector(
	[getSettings],
	(settings) => {
		if (settings) {
			return settings.cities;
		}
		return [];
	},
);

export const getCitiesIndex = createSelector(
	[getCities],
	(cities) => {
		if (cities.length === 0) {
			return null;
		}
		return indexArray(cities, (item) => {
			return item.city.id;
		});
	},
);

export const getSelectedCity = createSelector(
	[getSelectedCityId, getCitiesIndex],
	(selectedCityId, citiesIndex) => {
		if (!citiesIndex) {
			return null;
		}
		return citiesIndex[selectedCityId];
	},
);

export const getActiveSalons = createSelector(
	[getSelectedCityId, getSalons],
	(cityId, salons) => {
		return salons.filter((salon) => salon.cityId === cityId);
	},
);

export const getSalonsIndex = createSelector(
	[getSalons],
	(salons) => {
		if (salons.length === 0) {
			return null;
		}
		return indexArray(salons, 'id');
	},
);

export const getServicesIndex = createSelector(
	[getServices],
	(services) => {
		if (services.length === 0) {
			return null;
		}
		return indexArray(services, 'id');
	},
);

export const getActiveServices = createSelector(
	[getSelectedCityId, getSalonsIndex, getServices],
	(cityId, salonIndex, services) => {
		return services
			.filter((service) => {
				return service.salons.some((salonId) => {
					return salonIndex?.[salonId]?.cityId === cityId;
				});
			})
			.map((service) => {
				return {
					...service,
					price: service.prices[cityId],
				};
			});
	},
);

export const getActiveServicesIndex = createSelector(
	[getActiveServices],
	(services) => {
		if (services.length === 0) {
			return null;
		}
		return indexArray(services, 'id');
	},
);

export const getTopMenu = createSelector(
	[getSettings],
	(settings) => {
		return settings?.menu?.top ?? [];
	},
);

export const getBottomMenu = createSelector(
	[getSettings],
	(settings) => {
		return settings?.menu?.bottom ?? [];
	},
);
