import React from 'react';

import styles from './UserInfo.module.scss';
import Avatar from '../Avatar/Avatar';

function UserInfo({ user }) {

	const name = user ? [user.firstName, user.lastName].filter((f) => f).join(' ') : '';
	const email = user ? user.email : '';

	return (
		<div className={styles.userInfo}>
			<Avatar className={styles.avatar} user={user}/>
			<div className={styles.name}>
				{name}
			</div>
			<div className={styles.email}>
				{email}
			</div>
		</div>
	);
}

export default UserInfo;
