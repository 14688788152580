import React from 'react';
import { Link } from 'react-router-dom';

import styles from './CityPicker.module.scss';

function CityPicker({ cities, selectedCityId, onSelectCity }) {

	return (
		<div className={styles.container}>
			<div className={styles.nameContainer}>
				<span className={styles.headerText}>Выберете ваш город:</span>
			</div>
			<div className={styles.CityMobile}>
			</div>
			<div>
				<ul className={styles.ulStyle}>
					{cities.map(({ city }) =>
						<li key={city.id}>
							<Link
								to={'#'}
								className={(selectedCityId === city.id)
									? styles.ulSpanStyleActiveCity
									: styles.ulSpanStyle}
								onClick={(event) => onSelectCity(city.id, event)}
							>
								{city.name}
							</Link>
						</li>)}
				</ul>
			</div>
			<div className={styles.footerTextContainer}>
				<div className={styles.footerText}>
					Для отображения корректной информации на сайте,
					подтвердите своё местоположение. Спасибо.
				</div>
			</div>
		</div>);
}

export default CityPicker;
