import React from 'react';
import MaskedInput from 'react-text-mask';

import classNames from 'classnames';
import styles from './ShadowInput.module.scss';

class ShadowInput extends React.PureComponent {

	static defaultProps = {
		type: 'text',
		value: '',
		error: false,
		mask: false,
		dense: false,
	};

	touchMoveHappened = false;

	setTextInputRef = (element) => {
		if (element) {
			this._input = element.inputElement;
		}
	};

	handleChange = (e) => {
		const value = e.target.value;
		if (typeof this.props.onChange !== 'undefined') {
			this.props.onChange(value);
		}
	};

	handleDocumentClick = (e) => {
		if (!this.touchMoveHappened) {
			const targetIsTextField = e.target.tagName.toLowerCase() === 'input' || e.target.tagName.toLowerCase() === 'textarea';
			if (this._input && e.target !== this._input && !targetIsTextField) {
				this._input.blur();
			}

			if (!targetIsTextField
				&& e.target.tagName.toLowerCase() !== 'button'
				&& !e.target.closest('[data-active-element]')) {
				e.stopPropagation();
				e.preventDefault();
			}
			return;
		}
		this.touchMoveHappened = false;
	};
	handleDocumentTouchmove = () => {
		this.touchMoveHappened = true;
	};
	onFocus = (e) => {
		this.setState({ focused: true });
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
		setTimeout(() => {
			document.addEventListener('touchmove', this.handleDocumentTouchmove, {
				capture: true,
			});
			document.addEventListener('touchend', this.handleDocumentClick, {
				capture: true,
			});
		}, 0);
	};
	onBlur = (e) => {
		this.setState({ focused: false });
		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
		setTimeout(() => {
			document.removeEventListener('touchend', this.handleDocumentClick, {
				capture: true,
			});
			document.removeEventListener('touchmove', this.handleDocumentTouchmove, {
				capture: true,
			});
		}, 0);
	};

	constructor(props) {
		super(props);
		this.state = {
			focused: false,
		};
	}

	focus() {
		if (this._input) {
			this._input.focus();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('touchend', this.handleDocumentClick, {
			capture: true,
		});
		document.removeEventListener('touchmove', this.handleDocumentTouchmove, {
			capture: true,
		});
	}

	render() {
		const containerClassName = classNames([
			styles.inputContainer,
			this.props.className,
			{ [styles.error]: this.props.error, [styles.dense]: this.props.dense },
		]);

		return (
			<div className={containerClassName}>
				<MaskedInput
					lang={this.props.lang}
					keepCharPositions={false}
					ref={this.setTextInputRef}
					mask={this.props.mask}
					value={this.props.value}
					className={styles.input}
					type={this.props.type}
					placeholder={this.props.placeholder}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onChange={this.handleChange}
					onPaste={this.props.onPaste}
					required={this.props.required}
					showMask={this.state.focused}
					disabled={this.props.disabled}
					autoComplete={this.props.autoComplete}
					inputMode={this.props.inputMode}
				/>
				<div data-color={this.props.shadowColor} className={styles.shadow}/>
				<span className={styles.tooltip}>{this.props.tooltip}</span>
			</div>
		);
	}
}

export default ShadowInput;
