import React from 'react';

import styles from './About.module.scss';
import parseHtml from '@/utils/htmlParserWithCustomLinks';
import { Picture } from '@/components/Picture';

export const About = (props) => {

	const page = props.content;
	const vars = page.templateVars.pageVars;

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>{page.title}</h1>
			{vars.blocks.map((item, index) => {
				return <div key={index} data-index={index} className={styles.block}>
					<div className={styles.blockText}>
						<div className={styles.blockTitle}>{item.title}</div>
						<div className={styles.blockContent + ' text-content'}>{parseHtml(item.content || '')}</div>
					</div>
					<div className={styles.blockImage}>
						<div className={styles.blockImagesContainer}>
							{item.imageSmall && (
								<Picture
									className={styles.smallImage}
									image={item.imageSmall.image}
									desktopType={'fullscreen-desktop'}
									mobileType={'fullscreen-mobile'}
									alt={item.imageSmall.imageAlt}
								/>
							)}
							{item.image && (
								<Picture
									className={styles.mainImage}
									image={item.image.image}
									desktopType={'fullscreen-desktop'}
									mobileType={'fullscreen-mobile'}
									alt={item.image.imageAlt}
								/>
							)}
						</div>
					</div>
				</div>;
			})
			}
		</div>);
};
