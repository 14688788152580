import React, { useEffect, useState } from 'react';
import { checkCertificatesOrderStatus } from '@/api';
import Loader from '@/components/Loader';
import styles from './GiftCard.module.scss';
import RequestError from '@/utils/RequestError';

function GiftCardsStatus(props) {
	const { orderId } = props;
	const [pending, setPending] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (!orderId) {
			return;
		}
		setPending(true);
		checkCertificatesOrderStatus(orderId)
			.then(() => {
				setPending(false);
			})
			.catch((error) => {
				if (error instanceof RequestError) {
					switch (error.code) {
						case 'STATUS_FAILED': {
							setError('Не удалось получить статус заказа');
							break;
						}
						default: {
							setError(`Неизвестная ошибка при получении статуса, номер заказа в платежной системе ${orderId}`);
						}
					}
				}
				setError(`Неизвестная ошибка при получении статуса.\nНомер заказа:\n ${orderId}`);
				setPending(false);
			});
	}, [orderId]);

	if (!orderId) {
		return null;
	}

	if (error !== null) {
		return (

			<div className={styles.paymentStatusError}>
				<div className={styles.paymentErrorIconContainer}>
					<i className={styles.paymentErrorIcon}/>
				</div>
				<div className={styles.statusTextContainer}>
					{error}
				</div>
			</div>
		);
	}

	if (pending) {
		return (
			<div className={styles.paymentStatusPending}>
				<div className={styles.paymentLoaderContainer}>
					<Loader size={48}/>
				</div>
				<div>
					Проверяем статус оплаты
				</div>
			</div>
		);
	}

	return (
		<div className={styles.paymentStatusSuccess}>
			<div className={styles.paymentSuccessIconContainer}>
				<i className={styles.paymentSuccessIcon}/>
			</div>
			<div className={styles.statusTextContainer}>
				Оплата прошла успешно
			</div>
		</div>
	);
}

export default GiftCardsStatus;
