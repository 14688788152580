import React from 'react';
import classNames from 'classnames';

import styles from './Gifts.module.scss';
import useFetch from '@/hooks/useFetch';
import { fetchGifts } from '@/api';
import Loader from '@/components/Loader';
import moment from 'moment';
import parseHtml from '@/utils/htmlParserWithCustomLinks';

const Gifts = () => {
	const { response, isLoading } = useFetch(fetchGifts);

	if (isLoading || !response.result) {
		return <div className={styles.root}><Loader/></div>;
	}

	const gifts = response.result;

	return (
		<div className={styles.root}>
			<div className={styles.iconContainer}>
				<div className={styles.stars}>
					<div className={classNames([styles.giftIconContainer, styles.circleBorderContainer])}>
						<div className={classNames([styles.giftIcon, styles.circleBorderContent])}>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.giftsContainer}>
				{gifts.map((gift, index) => {
					return (
						<div key={index} className={styles.level}>
							<div className={styles.levelTitle}>
								<div
									className={classNames([styles.circle, styles.checkmark, gift.discounts?.length > 0 && styles.active])}>
								</div>
								<div>
									{gift.program.name}
								</div>
							</div>
							<div className={classNames([styles.levelDescr, 'text-content'])}>
								{parseHtml(gift.program.description)}
							</div>
							<div className={styles.issuedContainer}>
								{gift.discounts?.map((discount, index) => {
									return (
										<div key={index}>
											{index + 1}. Получен{' '}
											{moment(discount.issuedAt).format('DD.MM.YYYY')}{discount.expiresAt !== null ? ', истекает ' + moment(discount.expiresAt).format('DD.MM.YYYY') : ''}
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Gifts;
