import { loadPage, loadSalons, loadServices, loadSettings } from '@/api';
import { getPage, getSalons, getSelectedCityId, getServices, getSettings } from '@/store/selectors/DataSelectors';
import {
	setPageAction,
	setPageLoadingAction,
	setSalonsAction,
	setSelectedCityIdAction,
	setServicesAction,
	setSettingsAction,
} from '@/store/actions';
import { Storage } from '@/utils/storage';

export const initApp = async (store, cityId) => {
	const currentState = store.getState();
	const initPromises = [];

	const salons = getSalons(currentState);
	if (!salons || salons.length === 0) {
		const salonsPromise = loadSalons()
			.then((salons) => {
				store.dispatch(setSalonsAction(salons));
			});
		initPromises.push(salonsPromise);
	}

	const services = getServices(currentState);
	if (!services || services.length === 0) {
		const servicesPromise = loadServices()
			.then((services) => {
				store.dispatch(setServicesAction(services));
			});
		initPromises.push(servicesPromise);
	}

	const settings = getSettings(currentState);
	if (!settings || !Object.values(settings).length) {
		const settingsPromise = loadSettings()
			.then((settings) => {
				store.dispatch(setSettingsAction(settings));
				const initialCityId = cityId ?? getSelectedCityId(store.getState()) ?? settings.cities[0].city.id;
				const settingsHasCityId = settings.cities.some(({ city }) => city.id === initialCityId);
				if (settingsHasCityId) {
					store.dispatch(setSelectedCityIdAction(initialCityId));
				}
			});
		initPromises.push(settingsPromise);
	}

	await Promise.all(initPromises);
};

let loadingDebounceTimer;
let prevPath;


export const initPage = async (store, path, search) => {
	const currentPage = getPage(store.getState());

	if (search) {
		const searchParams = new URLSearchParams(search);
		const utm = {};
		for (const [key, value] of searchParams.entries()) {
			if (key.startsWith('utm')) {
				utm[key] = value;
			}
		}
		if (Object.keys(utm).length > 0) {
			Storage.setItem('utm', utm, Date.now() + 24 * 60 * 60 * 1000);
		}
	}

	if (prevPath === path || (currentPage && currentPage.fullPath === path)) {
		return;
	}
	const settings = getSettings(store.getState());
	prevPath = path;
	clearTimeout(loadingDebounceTimer);
	loadingDebounceTimer = setTimeout(() => {
		store.dispatch(setPageLoadingAction(true));
	}, 150);

	try {
		const redirect = settings.redirects.find((r) => r.url === path);
		if (redirect) {
			clearTimeout(loadingDebounceTimer);
			store.dispatch(setPageAction({
				template: 'redirect',
				status: redirect.status,
				to: redirect.linkedPage.fullPath,
			}));
			store.dispatch(setPageLoadingAction(false));
			return;
		}
		const page = await loadPage(path);
		if (page.template === 'salon-page') {
			const cityId = page.templateVars?.pageVars?.salon?.cityId;
			store.dispatch(setSelectedCityIdAction(cityId));
		}
		clearTimeout(loadingDebounceTimer);
		store.dispatch(setPageAction(page));
		store.dispatch(setPageLoadingAction(false));
	} catch (e) {
		clearTimeout(loadingDebounceTimer);
		store.dispatch(setPageLoadingAction(false));
		store.dispatch(setPageAction({
			fullPath: path,
		}));
	}
};

export const refreshPage = async (store) => {
	const currentPage = getPage(store.getState());
	if (currentPage?.fullPath) {
		const page = await loadPage(currentPage.fullPath);
		store.dispatch(setPageAction(page));
	}
};

export const initPageUnoptimized = async (store, path, cityId) => {
	try {
		const settings = getSettings(store.getState());
		const redirect = settings.redirects.find((r) => r.url === path);
		if (redirect) {
			clearTimeout(loadingDebounceTimer);
			store.dispatch(setPageAction({
				template: 'redirect',
				status: redirect.status,
				to: redirect.linkedPage.fullPath,
			}));
			store.dispatch(setPageLoadingAction(false));
			return;
		}
		const page = await loadPage(path, cityId);
		if (page.template === 'salon-page') {
			const cityId = page.templateVars?.pageVars?.salon?.cityId;
			store.dispatch(setSelectedCityIdAction(cityId));
		}
		store.dispatch(setPageAction(page));
	} catch (e) {
		if (e.code === 'PAGE_NOT_FOUND') {
			store.dispatch(setPageAction({}));
		} else {
			store.dispatch(setPageAction(null));
		}
		throw e;
	}
};
