import React from 'react';

import Modal from '../Modal';

import styles from './StatusModal.module.scss';
import Button from '../Button/Button';

class StatusModal extends React.PureComponent {

	render() {
		return (
			<Modal className={styles.container} isOpen={this.props.isOpen} onClose={this.props.onClose}>
				<div className={styles.content} data-status={this.props.status}>
					<div className={styles.icon}/>
					<div className={styles.text}>{this.props.modalText}</div>
					<Button onClick={this.props.onButtonClick}>{this.props.buttonText}</Button>
				</div>
			</Modal>
		);
	}
}

export default StatusModal;
