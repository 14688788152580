import React from 'react';
import {
	Clusterer,
	GeolocationControl,
	Map,
	Placemark,
	TrafficControl,
	TypeSelector,
	withYMaps,
} from '@pbe/react-yandex-maps';
import { connect } from 'react-redux';
import formatPhone from '@/utils/formatPhone';
import Loader from '@/components/Loader';

import './MapSalons.scss';
import styles from './MapSalons.module.scss';
import placemark1 from '@/assets/placemarks/placemark1.svg';
import placemark2 from '@/assets/placemarks/placemark2.svg';
import placemark3 from '@/assets/placemarks/placemark3.svg';
import placemark4 from '@/assets/placemarks/placemark4.svg';
import placemark12 from '@/assets/placemarks/placemark12.svg';
import placemark13 from '@/assets/placemarks/placemark13.svg';
import placemark14 from '@/assets/placemarks/placemark14.svg';
import placemark23 from '@/assets/placemarks/placemark23.svg';
import placemark24 from '@/assets/placemarks/placemark24.svg';
import placemark34 from '@/assets/placemarks/placemark34.svg';
import placemark123 from '@/assets/placemarks/placemark123.svg';
import placemark124 from '@/assets/placemarks/placemark124.svg';
import placemark134 from '@/assets/placemarks/placemark134.svg';
import placemark234 from '@/assets/placemarks/placemark234.svg';
import placemark1234 from '@/assets/placemarks/placemark1234.svg';
import customCluster from '../../assets/placemark-cluster.svg';
import { getSalons } from '@/store/selectors';

const divisionIcons = {
	'1': placemark1,
	'2': placemark2,
	'3': placemark3,
	'4': placemark4,
	'12': placemark12,
	'13': placemark13,
	'14': placemark14,
	'23': placemark23,
	'24': placemark24,
	'34': placemark34,
	'123': placemark123,
	'124': placemark124,
	'134': placemark134,
	'234': placemark234,
	'1234': placemark1234,
};

class MapSalons extends React.PureComponent {

	static defaultProps = {
		withBalloons: true,
		lazy: true,
	};

	state = {
		templateLayout: undefined,
		balloonLayout: undefined,
		loading: true,
		mapError: false,
		ready: false,
	};

	_container = React.createRef();

	loadingYmaps = (ymaps) => {
		if (ymaps !== undefined) {
			const self = this;
			this.setState({
				templateLayout: ymaps.templateLayoutFactory.createClass(
					'<div style="color: #414042;  font-size: 16px; font-weight: bold;' +
					'line-height: 35px;">{{ properties.geoObjects.length }}</div>',
				),
				balloonLayout: ymaps.templateLayoutFactory.createClass(
					'<div class="salonContainer">' +
					'<span class="salonName">{{ properties.value.name }}</span>' +
					'<span class="salonText salonAddress">{{ properties.value.address }}</span>' +
					'<span class="salonText salonContacts">' +
					'<a  href="tel: +{{ properties.value.rawPhone }}" class="salonPhone">{{ properties.value.phone }}</a>' +
					'<span> | </span>' +
					'<span class="salonBusinessHours">Ежедневно {{ properties.value.businessHours }}</span>' +
					'</span>' +
					'<span class="salonTextLinkContainer">' +
					'<span class="salonTextLinkFix">' +
					'{% if !properties.value.link %}' +
					'' +
					'{% else %}' +
					'   <a href={{ properties.value.link }} class="salonTextLink">Подробнее</a>' +
					'{% endif %}' +
					'   <a href="#appointment" class="salonTextLink appointMentLink">Выбрать</a>' +
					'</span>' +
					'<div class="salonOptions">' +
					'  <i data-icon="kids" title="Детский уголок" class="salonOption{% if properties.value.kids %} salonOptionActive{% endif %}"></i>' +
					'  <i data-icon="coffee" title="Чай / Кофе" class="salonOption{% if properties.value.coffee %} salonOptionActive{% endif %}"></i>' +
					'  <i data-icon="ramp" title="Пандус" class="salonOption{% if properties.value.ramp %} salonOptionActive{% endif %}"></i>' +
					'  <i data-icon="parking" title="Городская парковка" class="salonOption{% if properties.value.parking %} salonOptionActive{% endif %}"></i>' +
					'  <i data-icon="wifi" title="Бесплатный WiFi" class="salonOption{% if properties.value.wifi %} salonOptionActive{% endif %}"></i>' +
					'  <i data-icon="brows" title="Услуги бровиста" class="salonOption{% if properties.value.brows %} salonOptionActive{% endif %}"></i>' +
					'</div>' +
					'</span>' +
					'</div>',
					{
						build: function () {
							this.constructor.superclass.build.call(this);
							const parent = this.getParentElement();
							const link = parent.querySelector('.appointMentLink');
							link.addEventListener('click', (e) => {
								e.preventDefault();
								const templateProps = this.getData().properties.get('value');
								self.props.onSelectSalon(templateProps.id);
							});
						},
					},
				),
			});
		}
	};

	componentDidMount() {
		if (typeof window !== 'undefined') {
			if (this.props.lazy) {
				document.addEventListener('scroll', this.handleScroll, { passive: true });
				const containerTop = this._container.current.offsetTop;
				const screenBottom = document.documentElement.scrollTop + document.documentElement.clientHeight + 200;
				if (screenBottom > containerTop) {
					this.setState({ ready: true });
				}
			} else {
				this.setState({ ready: true });
			}
		}
	}

	componentWillUnmount() {
		if (this.props.lazy && typeof window !== 'undefined') {
			document.removeEventListener('scroll', this.handleScroll);
		}
	}

	handleScroll = (e) => {
		if (this._container.current && !this.state.ready) {
			const containerTop = this._container.current.offsetTop;
			const screenBottom = e.target.documentElement.scrollTop + e.target.documentElement.clientHeight + 200;
			if (screenBottom > containerTop) {
				this.setState({ ready: true });
			}
		}
	};

	render() {

		const { city, salon } = this.props;
		let zoom;
		let coords;

		if (city) {
			coords = city.coords.split(',');
			zoom = 13;
		} else if (salon) {
			coords = salon.salonCoords.split(',');
			zoom = 12;
		}

		const placemarks = city ? this.props.salons : [{
			id: this.props.salon.salon.id,
			meta: { templateVars: { pageVars: this.props.salon } },
		}];

		const userState = {
			width: '100%',
			height: 435,
			center: coords,
			zoom: zoom,
			controls: ['zoomControl', 'fullscreenControl'],
		};

		const mapModules = [
			'control.ZoomControl',
			'control.FullscreenControl',
			'templateLayoutFactory',
			'layout.ImageWithContent',
		];

		if (this.props.withBalloons) {
			mapModules.push('geoObject.addon.balloon');
		}

		const content = this.state.ready ? (
			<>
				{(this.state.loading) && <Loader/>}
				{(this.state.mapError) && <div className={styles.mapError}>Карта временно недостуна</div>}
				<Map
					state={userState}
					modules={mapModules}
					height={userState.height}
					width={userState.width}
					onLoad={(ymaps) => {
						this.loadingYmaps(ymaps);
						this.setState({
							loading: false,
						});
					}}
					onError={() => {
						this.setState({
							loading: false,
							mapError: true,
						});
					}}
				>
					<GeolocationControl
						options={{ float: 'left' }}
					/>
					<TrafficControl
						defaultState={{
							trafficShown: false,
							providerKey: 'traffic#archive',
						}}
					/>
					<TypeSelector defaultState={{ expanded: false }}/>
					<Clusterer
						options={{
							preset: 'islands#invertedVioletClusterIcons',
							groupByCoordinates: false,
							clusterIcons: [
								{
									href: customCluster,
									size: [40, 40],
									offset: [-20, -20],
								}],
							clusterIconContentLayout: this.state.templateLayout,
						}}>
						{placemarks.map((salon) => {
							const meta = salon.meta;
							const salonVars = meta?.templateVars?.pageVars;
							const divisions = salon.divisions ?? this.props.salons.find((s) => s.id === salon.id)?.divisions;
							if (!meta || !salonVars) {
								return;
							}
							return <Placemark
								defaultGeometry={salonVars.salonCoords.split(',')}
								options={{
									iconLayout: 'default#image',
									iconImageHref: divisionIcons[divisions.join('')],
									iconImageSize: [30, 42],
									iconImageOffset: [-5, -38],
									balloonContentLayout: this.state.balloonLayout,
									balloonMaxWidth: 465,
								}}
								defaultProperties={{
									value: {
										id: salon.id,
										name: salonVars.salonName,
										address: salonVars.salonAddress,
										rawPhone: salonVars.salonPhones?.[0]?.phone,
										phone: formatPhone(salonVars.salonPhones?.[0]?.phone ?? ''),
										businessHours: salon.businessHours,
										wifi: salonVars.options.hasWifi,
										kids: salonVars.options.hasKids,
										ramp: salonVars.options.hasRamp,
										coffee: salonVars.options.hasCoffee,
										parking: salonVars.options.hasParking,
										brows: salonVars.options.hasBrows,
										link: meta.fullPath,
									},
								}}
								key={salon.id}/>;
						})}
					</Clusterer>
				</Map>
			</>
		) : (
			<Loader/>
		);

		return (<div ref={this._container} className={styles.mapContainer}>
			{content}
		</div>);
	}
}


const mapStateToProps = (state) => {
	return {
		salons: getSalons(state),
	};
};

const ConnectedMap = connect(mapStateToProps)(MapSalons);

export default withYMaps(ConnectedMap, false, ['templateLayoutFactory', 'layout.ImageWithContent']);
