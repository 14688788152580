import React from 'react';

import styles from './Menu.module.scss';

export const MenuContext = React.createContext({ position: 'header' });

function Menu({ place = 'header', children }) {
	return (
		<MenuContext.Provider value={{ position: place }}>
			<div className={styles.container} data-position={place}>
				<nav className={styles.content}>
					<hr className={styles.border}/>
					<menu className={styles.menu}>
						{children}
					</menu>
					<hr className={styles.border}/>
				</nav>
			</div>
		</MenuContext.Provider>
	);
}

export default Menu;
