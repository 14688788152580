import React from 'react';
import styles from './Form.module.scss';
import Button from '@/components/Button/Button';
import ShadowInput from '@/components/ShadowInput/ShadowInput';
import Countdown from 'react-countdown-now';
import { codeInputMask, focusMaskStart } from '@/utils/masks';
import moment from 'moment';

function ConfirmPhoneForm({ reclaimAt, onSubmit, onResend, isSubmitting, confirmButtonText = 'Подтвердить' }) {
	const [code, setCode] = React.useState('');

	const handleSubmit = React.useCallback((e) => {
		e.preventDefault();
		onSubmit({ code });
	}, [code, onSubmit]);

	const countDownRenderer = React.useCallback(({ minutes, seconds, completed }) => {
		if (completed) {
			return <span onClick={(e) => {
				e.preventDefault();
				setCode('');
				onResend();
			}}>Получить код повторно</span>;
		} else {
			const minutesString = minutes.toString().padStart(2, '0');
			const secondsString = seconds.toString().padStart(2, '0');
			return <span>Запросить код повторно можно через {minutesString}:{secondsString}</span>;
		}
	}, [onResend]);

	return (
		<form noValidate onSubmit={handleSubmit} className={styles.form}>
			<div className={styles.fields}>
				<div className={styles.note}>
					Введите код из смс
				</div>
				<ShadowInput
					onFocus={focusMaskStart}
					mask={codeInputMask}
					onChange={(value) => setCode(value)}
					value={code}
					name={'code'}
					className={styles.field}
					shadowColor={'purple'}
				/>
				<div className={styles.resend}>
					<Countdown
						key={reclaimAt}
						date={moment(reclaimAt).toDate()}
						renderer={countDownRenderer}
					/>
				</div>
			</div>
			<div className={styles.actions}>
				<Button
					type={'submit'}
					disabled={isSubmitting}
					className={styles.action}
				>
					{confirmButtonText}
				</Button>
			</div>
		</form>
	);
}

export default ConfirmPhoneForm;
