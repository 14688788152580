import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAppointmentComment,
	getAppointmentDate,
	getAppointmentNotification,
	getAppointmentSalon,
	getAppointmentServicesFull,
	getAppointmentTime,
} from '@/store/selectors';


import { ClientSummary } from '../ClientsSummary';
import { DateInfo, SalonInfo } from '../VisitInfo';
import Button from '../Button/Button';

import styles from './AppointmentConfirm.module.scss';
import ReactSwitch from 'react-switch';
import { setAppointmentNotification } from '@/store/actions';
import { NotificationDialog } from '@/components/NotificationDialog/NotificationDialog';
import curring from '@/utils/curring';

function AppointmentConfirm({ onSubmit }) {

	const dispatch = useDispatch();
	const salon = useSelector(getAppointmentSalon);
	const clients = useSelector(getAppointmentServicesFull);
	const date = useSelector(getAppointmentDate);
	const time = useSelector(getAppointmentTime);
	const comment = useSelector(getAppointmentComment);
	const notification = useSelector(getAppointmentNotification);

	const [isNotificationDialogOpen, setNotificationDialogOpen] = useState(false);

	const clientsArr = Object.values(clients);
	if (!clientsArr.length) {
		clientsArr.push([]);
	}
	const notEmptyClients = clientsArr.filter((services) => !!services.length);

	const submitDisabled = !salon || !notEmptyClients.length || !date || !time;

	const handleNotificaion = useCallback((enable) => {
		if (enable) {
			setNotificationDialogOpen(true);
		} else {
			dispatch(setAppointmentNotification(null));
		}
	}, [dispatch]);

	const handleSubmitNotification = useCallback((value) => {
		dispatch(setAppointmentNotification(value));
		setNotificationDialogOpen(false);
	}, [dispatch]);

	return (
		<div className={styles.container}>
			<div className={styles.clients}>
				{clientsArr.map((services, index) => {
					return <div key={index} className={styles.client}>
						<div className={styles.summary}>
							{clientsArr.length > 1 &&
								<div className={styles.clientHeader}>
									<span>Гость №{index + 1}</span>
								</div>}
							{!!services.length &&
								<ClientSummary
									index={index}
									services={services}
								/>}
						</div>
					</div>;
				})}
			</div>
			<div className={styles.note}>
				* Уважаемые гости, в меню услуг указано среднее время выполнения процедуры. <br/>
				Время, необходимое для оказания услуг, индивидуальнодля каждого гостя и зависит от состояния ногтевой <br/>
				пластины, кутикулы, кожи рук и ног.
			</div>
			<div className={styles.info}>
				<DateInfo className={styles.dateInfo} date={date} time={time}/>
				<SalonInfo className={styles.salonInfo} withPhone salon={salon}/>
			</div>
			<label className={styles.notification}>
				<ReactSwitch
					checked={notification !== null}
					onChange={handleNotificaion}
					uncheckedIcon={false}
					checkedIcon={false}
					height={24}
					width={48}
				/>
				<span>{notification === null ? 'Напомнить о записи' : `Напомнить о записи за ${notification} ${curring(notification, ['час', 'часа', 'часов'])}`}</span>
			</label>
			{comment.length > 0 && (
				<div className={styles.comment}>
					<div className={styles.commentTitle}>
						Ваш комментарий:
					</div>
					<div>
						{comment}
					</div>
				</div>
			)}
			<div className={styles.actions}>
				<Button disabled={submitDisabled} onClick={onSubmit}>Подтвердить</Button>
			</div>
			<NotificationDialog
				isOpen={isNotificationDialogOpen}
				onClose={() => setNotificationDialogOpen(false)}
				onSubmit={handleSubmitNotification}
			/>
		</div>
	);
}

export default AppointmentConfirm;
