import React from 'react';
import classNames from 'classnames';

import styles from './Arrow.module.scss';

function Arrow(props) {
	const { onClick, direction, className, bigOne, isDark, isSmall, ...rest } = props;
	const arrowClassName = classNames([styles.container, className, {
		[styles.isBig]: bigOne,
	}]);
	return (
		<span
			onClick={onClick}
			data-direction={direction}
			className={arrowClassName}
			{...rest}
		>
			<i className={styles.arrow}/>
		</span>
	);
}

export default Arrow;
