import React from 'react';
import classNames from 'classnames';

import uid from '../../utils/uid';

import styles from './ShadowSelect.module.scss';

class ShadowSelect extends React.PureComponent {

	static defaultProps = {
		value: '',
		options: [],
		tabindex: 0,
	};

	timeoutId = null;

	_containerRef = React.createRef();

	handleChange = (value) => {
		const selectedOption = this.props.options.find((el) => el.id === value);
		const displayValue = selectedOption ? selectedOption.name : this.props.placeholder;
		this.setState({
			value,
			displayValue,
		});
		this.closeModal();
		if (typeof this.props.onChange !== 'undefined') {
			this.props.onChange(value);
		}
	};

	toggleModal = () => {
		this.state.modalIsOpen ? this.closeModal() : this.openModal();
	};

	openModal = () => {
		this.setState({
			modalIsOpen: true,
		});
		document.addEventListener('touchend', this.handleDocumentClick, true);
		document.addEventListener('touchmove', this.handleDocumentTouchmove, true);
	};

	closeModal = () => {
		this.setState({
			modalIsOpen: false,
		});
		document.removeEventListener('touchend', this.handleDocumentClick, true);
		document.removeEventListener('touchmove', this.handleDocumentTouchmove, true);
	};

	handleBackButton = (e) => {
		if (this.state.modalIsOpen) {
			e.cancel();
			this.closeModal();
		}
	};

	handleDocumentClick = (e) => {
		const clickedAway = !e.target.closest('#' + this.state.id);

		if (clickedAway && !this.touchMoveHappened) {
			this.timeoutId = setTimeout(this.closeModal, 0);
			if (e.target.tagName.toLowerCase() !== 'input'
				&& e.target.tagName.toLowerCase() !== 'button'
				&& !e.target.closest('[data-active-element]')) {
				e.stopPropagation();
				e.preventDefault();
			}
			return;
		}
		this.touchMoveHappened = false;
	};

	handleDocumentTouchmove = () => {
		this.touchMoveHappened = true;
	};

	handleFocus = () => {
		this.setState({
			focused: true,
		});
	};

	handleKeyPress = (e) => {
		if (e.which === 13) {
			this.openModal();
		}
		if (e.which === 40) {
			e.preventDefault();
		}

		if (e.which === 38) {
			e.preventDefault();
		}

	};

	handleBlur = () => {
		this.setState({
			focused: false,
			focusedItem: null,
		});
		this.closeModal();
	};

	focus = () => {
		if (this._containerRef.current) {
			this._containerRef.current.focus();
		}
	};

	constructor(props) {
		super(props);
		const value = this.props.value;
		const selectedOption = this.props.options.find((el) => el.id === value);
		const displayValue = selectedOption ? selectedOption.name : this.props.placeholder;
		this.state = {
			value,
			displayValue,
			focused: false,
			focusedItem: null,
			modalIsOpen: false,
			id: this.props.id || uid('select'),
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.options !== this.props.options) {
			const value = this.state.value;
			const selectedOption = this.props.options.find((el) => el.id === value);
			const displayValue = selectedOption ? selectedOption.name : this.props.placeholder;
			this.setState({
				displayValue,
			});
		}
		if (!this.state.value && this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timeoutId);
		document.removeEventListener('click', this.handleDocumentClick, false);
		document.removeEventListener('touchend', this.handleDocumentClick, true);
		document.removeEventListener('touchmove', this.handleDocumentTouchmove, true);
	}

	render() {
		const { placeholder, shadowColor, className, options, tabindex } = this.props;
		const { modalIsOpen, id, focused } = this.state;
		const containerClassName = classNames([styles.inputContainer, className, {
			[styles.focused]: focused,
		}]);
		const inputClassName = classNames([styles.input, {
			[styles.isOpen]: modalIsOpen,
		}]);

		return (
			<div
				ref={this._containerRef}
				id={id}
				className={containerClassName}
				data-active-element
				tabIndex={tabindex}
				onFocus={this.handleFocus}
				onBlur={this.handleBlur}
				onKeyDown={this.handleKeyPress}
			>
				<div className={inputClassName} onClick={this.openModal}>
					<div className={styles.inputPlaceholder}>
						{modalIsOpen ? '' : this.state.value ? this.state.displayValue : placeholder}
					</div>
					<div className={styles.optionsContainer}>
						{options.map((option) => (
							<div
								key={option.id}
								data-value={option.id}
								className={styles.option}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									this.handleChange(option.id);
								}}
							>
								{option.name}
							</div>
						))}
					</div>
				</div>
				<div data-color={shadowColor} className={styles.shadow}/>
			</div>
		);
	}
}

export default ShadowSelect;
