import { useMemo } from 'react';

export const usePagination = (render, total, perPage, page) => {
	return useMemo(() => {
		if (!total) {
			return [];
		}
		const totalPages = Math.ceil(total / perPage);
		const pages = [];

		let start = Math.max(0, page - 2);
		const end = Math.min(totalPages, start + 5);
		start += Math.min(totalPages - (start + 5), 0);
		start = Math.max(0, start);

		for (let pageIndex = start; pageIndex < end; pageIndex++) {
			pages.push(render(pageIndex));
		}
		return pages;
	}, [total, perPage, page, render]);
};
