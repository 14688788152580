import React from 'react';
import { useSelector } from 'react-redux';
import { getBottomMenu } from '@/store/selectors';
import Seasons from '../NewSeasonInfo/NewSeasonInfo';
import Menu from '../Menu/Menu';
import MenuItem from '../MenuItem/MenuItem';

import styles from './Footer.module.scss';

function Footer(props) {

	const { noSeasons } = props;

	const menu = useSelector(getBottomMenu);

	const date = new Date();

	return (
		<div className={styles.container}>
			{!noSeasons && (
				<div className={styles.seasons}>
					<Seasons/>
				</div>
			)}
			<div className={styles.menu}>
				<Menu place={'footer'}>
					{menu.map((el, index) => {
						return <MenuItem key={index} item={el}/>;
					})}
				</Menu>
			</div>
			<div className={styles.copyright}>
				<div className={styles.copyrightText}>
					© <span>2007-{date.getFullYear()}</span>
					<span> Сеть салонов маникюра и педикюра «Пальчики»</span>
				</div>
			</div>
		</div>
	);
}

export default Footer;
