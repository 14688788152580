import moment from 'moment';
import history from './history';
import StoreObserver from './StoreObserver';
import {
	getActiveServicesIndex,
	getAppointmentDate,
	getAppointmentSalon,
	getAppointmentSalonId,
	getAppointmentServices,
	getIsAuthenticated,
	getSelectedCityId,
} from '@/store/selectors';
import { initPage, refreshPage } from './initApp';
import store from '../store';
import { loadAvailableTime, loadProfile, refreshToken } from '@/api';
import {
	initAppointmentAction,
	setAppointmentAvailableTimeAction,
	setAppointmentAvailableTimeLoadingAction,
	setAppointmentDateAction,
	setAppointmentSalonAction,
	setAppointmentServicesAction,
	setProfileAction,
	setSelectedCityIdAction,
	setTokenAction,
	signOutAction,
} from '@/store/actions';
import { Storage } from '@/utils/storage';


const REFRESH_TIME = 60 * 60 * 1000;
let refreshIntervalId;

const refresh = async () => {
	try {
		const token = await refreshToken();
		store.dispatch(setTokenAction(token));
	} catch (e) {
		store.dispatch(signOutAction());
	}
};

const onLogin = async () => {
	await refresh();
	const profile = await loadProfile();
	store.dispatch(setProfileAction(profile));

	refreshIntervalId = setInterval(refresh, REFRESH_TIME);
};

const onLogout = () => {
	clearInterval(refreshIntervalId);
};

const onAuthChange = async (isAuthenticated) => {
	if (isAuthenticated) {
		await onLogin();
	} else {
		onLogout();
	}
};


export const createObservers = () => {

	let prevPath = history.location.pathname;
	history.listen((location) => {
		// scroll to top on page load
		if (prevPath !== location.pathname) {
			window.scrollTo(0, 0);
			prevPath = location.pathname;
		}

		// load page template and data
		initPage(store, location.pathname);
	});

	const savedState = Storage.getItem('state');
	if (savedState) {
		if (savedState.appointment) {
			store.dispatch(initAppointmentAction(savedState.appointment));
		}
		if (savedState.profile) {
			store.dispatch(setTokenAction(savedState.profile.token));
		}
		if (savedState.data) {
			store.dispatch(setSelectedCityIdAction(savedState.data.selectedCityId));
		}
	}

	const storeObserver = new StoreObserver(store);

	storeObserver.listen(getAppointmentDate, (date) => {
		if (!date || moment(date).isBefore(moment(), 'day')) {
			store.dispatch(setAppointmentDateAction(moment().format('YYYY-MM-DD')));
		}
	}, true);

	storeObserver.listen(getSelectedCityId, async (cityId) => {
		if (cityId) {
			await refreshPage(store);
		}
	});

	storeObserver.listen(
		[getAppointmentSalonId, getAppointmentServices, getAppointmentDate],
		async (salonId, services, date) => {
			const notEmptyClients = services ? Object.values(services).filter((services) => !!services.length) : [];
			if (salonId === null || (notEmptyClients.length === 0) || date === null) {
				store.dispatch(setAppointmentAvailableTimeAction(null));
				return;
			}
			store.dispatch(setAppointmentAvailableTimeLoadingAction(true));
			const time = await loadAvailableTime(salonId, services, date);
			store.dispatch(setAppointmentAvailableTimeAction(time));
			store.dispatch(setAppointmentAvailableTimeLoadingAction(false));
		}, true);

	storeObserver.listen(
		[getAppointmentSalon],
		(salon) => {
			if (salon) {
				store.dispatch(setSelectedCityIdAction(salon.cityId));
			}
		},
	);

	storeObserver.listen(
		[getSelectedCityId],
		(cityId) => {
			const appointmentSalon = getAppointmentSalon(store.getState());
			if (appointmentSalon && appointmentSalon.cityId !== cityId) {
				store.dispatch(setAppointmentSalonAction(null));
			}
		},
	);

	storeObserver.listen(
		[getActiveServicesIndex, getAppointmentServices],
		(servicesIndex, appointmentServices) => {
			if (!servicesIndex || !appointmentServices) {
				return;
			}
			for (const clientIndex in appointmentServices) {
				const clientServices = appointmentServices[clientIndex];
				const filteredServices = clientServices.filter((s) => servicesIndex[s.id] !== undefined);
				if (filteredServices.length !== clientServices.length) {
					store.dispatch(setAppointmentServicesAction(clientIndex, filteredServices));
				}
			}
		},
	);

	storeObserver.listen(getIsAuthenticated, onAuthChange, true);

	store.subscribe(() => {
		const { appointment, profile, data } = store.getState();
		Storage.setItem('state', {
			appointment: appointment,
			profile: profile,
			data: {
				selectedCityId: data.selectedCityId,
			},
		}, Date.now() + 24 * 60 * 60 * 1000);
	});
};
