import React from 'react';

import styles from './ServicesPicker.module.scss';

import ServiceList from '../ServiceList/ServiceList';
import { getAppointmentServicesFull, getAppointmentServicesTypes, getServices } from '@/store/selectors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '../Button/Button';
import { addAppointmentServiceAction, removeAppointmentServiceAction } from '@/store/actions';


class ServicesPicker extends React.Component {

	static defaultProps = {
		selectedServices: [],
		selectedServicesTypes: [],
	};

	addService = (id, amount) => {
		this.props.onSelectService(this.getClientIndex(), id, amount);
	};

	removeService = (id) => {
		this.props.onRemoveService(this.getClientIndex(), id);
	};

	handleServiceSelect = (serviceId, amount, isSelected) => {
		isSelected ? this.removeService(serviceId) : this.addService(serviceId, amount);
	};

	handleServiceAmountChange = (serviceId, amount, isSelected) => {
		if (isSelected) {
			this.addService(serviceId, amount);
		}
	};

	handleDone = () => {
		this.props.onSubmit();
	};

	getClientIndex = () => {
		return this.props.clientIndex;
	};

	render() {
		const clientIndex = this.getClientIndex();
		const currentServices = this.props.selectedServices[clientIndex];
		const currentServicesTypes = this.props.selectedServicesTypes[clientIndex];
		return (
			<div className={styles.container}>
				<div className={styles.categories}>
					<ServiceList
						selectedServicesTypes={currentServicesTypes}
						selectedServices={currentServices}
						onServiceSelect={this.handleServiceSelect}
						onServiceAmountChange={this.handleServiceAmountChange}
					/>
				</div>
				<div className={styles.actions}>
					<Button onClick={this.handleDone}>ОК</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		selectedServices: getAppointmentServicesFull(state),
		selectedServicesTypes: getAppointmentServicesTypes(state),
		services: getServices(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSelectService: (clientIndex, servicesId, amount) => dispatch(addAppointmentServiceAction(servicesId, amount, clientIndex)),
		onRemoveService: (clientIndex, servicesId) => dispatch(removeAppointmentServiceAction(servicesId, clientIndex)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServicesPicker));
