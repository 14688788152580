import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getProfile } from '@/store/selectors';
import Button from '../Button/Button';
import UserInfo from '../UserInfo/UserInfo';

import styles from './AccountPreview.module.scss';

export function AccountPreview(props) {
	const { onClickProfile, onClickAppointment, onClickSignOut } = props;

	const profile = useSelector(getProfile);

	const handleClickProfile = useCallback((e) => {
		e.stopPropagation();
		onClickProfile?.();
	}, [onClickProfile]);

	const handleClickAppointment = useCallback((e) => {
		e.stopPropagation();
		onClickAppointment?.();
	}, [onClickAppointment]);

	const handleClickSignOut = useCallback((e) => {
		e.stopPropagation();
		onClickSignOut?.();
	}, [onClickSignOut]);

	return (
		<div className={styles.container}>
			<UserInfo user={profile}/>
			<div className={styles.visitsInfo}>
				Активные записи: {profile.appointmentsCount} | Подарки: {profile.giftsCount}
			</div>
			<div className={styles.actions}>
				<Button onClick={handleClickProfile} className={styles.profile}>
					Личный кабинет
				</Button>
				<Button onClick={handleClickAppointment} className={styles.appointmentButton}>
					Записаться
				</Button>
				<button onClick={handleClickSignOut} className={styles.signOut}>
					Выход
				</button>
			</div>
		</div>
	);
}

export default AccountPreview;
