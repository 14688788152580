import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';

import styles from './Promo.module.scss';

import Button from '../../components/Button/Button';
import YmapAndSalons from '../../components/SalonsListController/SalonsListController';
import parseHtml from '@/utils/htmlParserWithCustomLinks';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAppointmentSalonAction } from '@/store/actions';
import { Picture } from '@/components/Picture';

export const Promo = (props) => {

	const page = props.content;
	const vars = page.templateVars.pageVars;
	const dispatch = useDispatch();
	const history = useHistory();

	const handleAppointmentClick = useCallback(() => {
		dispatch(setAppointmentSalonAction(Number(vars.salon.id)));
		history.push('#appointment');
	}, [dispatch, vars.salon.id, history]);

	return <div className={styles.container}>
		<Helmet>
			<meta name="robots" content="noindex, nofollow"/>
		</Helmet>
		<div className={styles.contentContainer}>
			<div className={styles.textContainer}>
				<div className={styles.content}>
					<h1 className={styles.title}>{page.title}</h1>
					<article className={'text-content'}>{parseHtml(vars.content || '')}</article>
				</div>
				<div className={styles.imageContainer}>
					{vars.image && (
						<Picture
							className={styles.img}
							image={vars.image.image}
							desktopType={'fullscreen-desktop'}
							mobileType={'fullscreen-mobile'}
							alt={vars.image.imageAlt}
						/>
					)}
				</div>
			</div>
			<div className={styles.links}>
				<Button className={styles.MobileBtns} onClick={handleAppointmentClick}>Записаться</Button>
				<a href={`tel:+${vars.salon.phone}`} target={'_blank'} rel="noopener noreferrer">
					<Button className={styles.MobileBtns}>Позвонить</Button>
				</a>
			</div>
		</div>
		<div className={styles.map}>
			<YmapAndSalons withCityTitle/>
		</div>
	</div>;
};
