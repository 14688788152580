import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { loadReiviewableVisits, submitAbstractReview, submitVisitReview } from '@/api';

import Button from '../Button/Button';
import StatusModal from '../StatusModal/StatusModal';
import Loader from '../Loader/Loader';
import ShadowSelect from '../ShadowSelect/ShadowSelect';
import { DateInfo, SalonInfo } from '../VisitInfo';
import StarsWithLabel from '../StarsWithLabel/StarsWithLabel';

import styles from './Form.module.scss';
import formStyles from './ReviewForm.module.scss';
import { compressImage } from '@/utils/compressImage';
import { usePopulatedAppointments } from '@/hooks/usePopulatedAppointments';


function ReviewForm(props) {

	const { onSuccess } = props;

	const [formData, setFormData] = useState({
		answer1: 5,
		answer2: 5,
		answer3: 5,
		answer4: 5,
		answer5: 5,
		comment: '',
		photos: [],
	});
	const [visits, setVisits] = useState([]);
	const [photosPreviews, setPhotosPreviews] = useState([]);
	const [loadingPreviews, setLoadingPreview] = useState(false);
	const [formError, setFormError] = useState(null);
	const [formSuccess, setFormSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [visitMode, setVisitMode] = useState(false);
	const [selectedVisitId, setSelectedVisitId] = useState(null);
	const populatedVisits = usePopulatedAppointments(visits);

	const updateFormData = useCallback((field, value) => {
		setFormData((formData) => ({ ...formData, [field]: value }));
	}, []);

	const handleSubmit = useCallback(async (e) => {
		e.preventDefault();
		if (!formData.comment) {
			setFormError('Не указан комментарий');
			return;
		}
		setLoading(true);
		try {
			if (visitMode) {
				const answers = {
					answer1: formData.answer1,
					answer2: formData.answer2,
					answer3: formData.answer3,
					answer4: formData.answer4,
					answer5: formData.answer5,
				};
				await submitVisitReview(selectedVisitId, answers, formData.comment, formData.photos);
			} else {
				await submitAbstractReview(formData.comment, formData.photos);
			}
			setFormSuccess(true);
		} catch (e) {
			setFormError(e.message);
		} finally {
			setLoading(false);
		}
	}, [formData, visitMode, selectedVisitId]);

	const handleAddPhotoClick = useCallback(() => {
		if (formData.photos.length >= 3) {
			return;
		}
		const input = document.createElement('input');
		input.type = 'file';
		input.multiple = true;
		input.accept = 'image/*';
		input.click();
		input.addEventListener('change', async () => {
			const addPhotos = Array.from(input.files).slice(0, Math.max(3 - formData.photos.length, 0));
			const compressed = await Promise.all(addPhotos.map(async (file) => {
				if (file.size < 5000000) {
					return file;
				}
				return compressImage(file, { quality: 0.5 });
			}));
			updateFormData('photos', [
				...formData.photos,
				...compressed,
			]);
		});
	}, [formData.photos, updateFormData]);

	const removePhoto = useCallback((index) => {
		const newPhotos = [...formData.photos];
		newPhotos.splice(index, 1);
		updateFormData('photos', newPhotos);
	}, [formData.photos, updateFormData]);

	const dismissErrorModal = useCallback(() => {
		setFormError(null);
	}, []);

	const dismissSuccessModal = useCallback(() => {
		setFormSuccess(false);
		onSuccess();
	}, [onSuccess]);

	const handleAnswerChange = useCallback((index, value, name) => {
		updateFormData(name, index);
	}, []);

	useEffect(() => {
		setLoadingPreview(true);
		const promises = formData.photos.map((blob) => {
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
					resolve(reader.result);
				};
			});
		});
		Promise.all(promises)
			.then((newPhotos) => {
				setPhotosPreviews(newPhotos);
				setLoadingPreview(false);
			});
	}, [formData.photos]);

	useEffect(() => {
		loadReiviewableVisits()
			.then(setVisits);
	}, []);

	const options = useMemo(() => populatedVisits.map((visit) => {
		return {
			id: visit.id,
			name: <div className={formStyles.visitInfo}>
				<DateInfo className={formStyles.dateInfo} date={visit.date} time={visit.time}/>
				<SalonInfo className={formStyles.salonInfo} salon={visit.salon}/>
			</div>,
		};
	}), [populatedVisits]);

	const hasPastVisitsForReview = !!populatedVisits.length;

	return (
		<div className={styles.form}>
			<div className={styles.fields}>
				{hasPastVisitsForReview && <div className={styles.fieldFullWidth}>
					<input
						className={styles.checkbox}
						type={'checkbox'}
						id={'about-visit'}
						name={'personal-data'}
						checked={visitMode}
						onChange={(e) => setVisitMode(e.target.checked)}
					/>
					<label
						className={styles.checkboxLabel}
						htmlFor={'about-visit'}
					>
						<span>О визите</span>
					</label>
				</div>}
				{visitMode && hasPastVisitsForReview && <div className={styles.fieldFullWidth}>
					<ShadowSelect
						value={selectedVisitId}
						placeholder={'Выберите визит'}
						onChange={setSelectedVisitId}
						options={options}
					/>
					<StarsWithLabel
						title={'Качество оказанной услуги'}
						name={'answer1'}
						value={formData.answer1}
						onChange={handleAnswerChange}
						className={formStyles.stars}
					/>
					<StarsWithLabel
						title={'Время обслуживания'}
						name={'answer2'}
						value={formData.answer2}
						onChange={handleAnswerChange}
						className={formStyles.stars}
						titleClassName={formStyles.starsTitle}
					/>
					<StarsWithLabel
						title={'Чистота в салоне'}
						name={'answer3'}
						value={formData.answer3}
						onChange={handleAnswerChange}
						className={formStyles.stars}
						titleClassName={formStyles.starsTitle}
					/>
					<StarsWithLabel
						title={'Атмосфера в салоне'}
						name={'answer4'}
						value={formData.answer4}
						onChange={handleAnswerChange}
						className={formStyles.stars}
						titleClassName={formStyles.starsTitle}
					/>
					<StarsWithLabel
						title={'Общее впечатление от посещения салона'}
						name={'answer5'}
						value={formData.answer5}
						onChange={handleAnswerChange}
						className={formStyles.stars}
						titleClassName={formStyles.starsTitle}
					/>
				</div>}
				<div className={styles.fieldFullWidth}>
					<textarea
						onChange={(e) => updateFormData('comment', e.target.value)}
						value={formData.comment}
						placeholder={'Комментарий'}
						className={formStyles.textarea}
					/>
				</div>
			</div>
			{(!!photosPreviews.length || loadingPreviews) &&
				<div className={formStyles.previews}>
					{loadingPreviews && <Loader/>}
					{photosPreviews.map((preview, index) => {
						return <div className={formStyles.preview} key={index}>
							<i className={formStyles.removePhotoIcon} onClick={() => removePhoto(index)}/>
							<img src={preview} alt={'icon'}/>
						</div>;
					})}
				</div>}
			<div className={styles.actions}>
				<Button
					onClick={handleAddPhotoClick}
					className={styles.action}
					disabled={formData.photos.length >= 3}
				>
					Добавить фото
				</Button>
				<Button
					onClick={handleSubmit}
					className={styles.action}
					disabled={loading}
				>
					Оставить отзыв
				</Button>
			</div>
			<StatusModal
				status={'error'}
				modalText={formError}
				buttonText={'ОК'}
				onClose={dismissErrorModal}
				onButtonClick={dismissErrorModal}
				isOpen={formError !== null}/>
			<StatusModal
				status={'success'}
				modalText={'Отзыв успешно отправлен'}
				buttonText={'ОК'}
				onClose={dismissSuccessModal}
				onButtonClick={dismissSuccessModal}
				isOpen={formSuccess}/>
		</div>
	);
}

export default ReviewForm;
