import React from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

function Button({ children, className, shadowColor, ...rest }) {
	return <button
		type={'button'}
		className={classNames([styles.button, className])}
		data-shadow={shadowColor}
		{...rest}
	>
		<span className={styles.content}>{children}</span>
	</button>;
}

export default Button;
