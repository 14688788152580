export const URLParser = {};
if (typeof window !== 'undefined') {
	URLParser.parse = (url) => {
		const a = document.createElement('a');
		a.href = url;
		return {
			href: a.href,
			protocol: a.protocol,
			hostname: a.hostname,
			port: a.port,
			pathname: a.pathname,
			search: a.search,
			hash: a.hash,
		};
	};
} else {
	URLParser.parse = require('url').parse;
}
