import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';

import Main from '../../templates/Main/Main';
import { News } from '@/templates/News/News';
import { Mobile } from '@/templates/Mobile/Mobile';
import GiftCard from '../../templates/GiftCard/GiftCard';
import { Contacts } from '@/templates/Contacts/Contacts';
import { About } from '@/templates/About/About';
import SalonDetails from '../../templates/SalonDetails/SalonDetails';
import SingleNews from '../../templates/SingleNews/SingleNews';
import { ServicePage } from '@/templates/ServicePage/ServicePage';
import Loader from '../Loader/Loader';
import { useSelector } from 'react-redux';
import PageNotFound from '@/components/PageNotFound';
import { Club } from '@/templates/Club/Club';
import SSRRedirect from '@/components/SSRRedirect';
import { Promo } from '@/templates/Promo/Promo';
import Footer from '@/components/Footer/Footer';
import { getPage, getPageLoading, getSettings } from '@/store/selectors';
import { Cookie } from '@/components/Cookie/Cookie';
import { TextPage } from '@/templates/TextPage/TextPage';
import { SalonsListPage } from '@/templates/SalonsList/SalonsListPage';

function PageContent({ staticContext }) {
	const loading = useSelector(getPageLoading);
	const page = useSelector(getPage);
	const settings = useSelector(getSettings);

	if (loading) {
		return <Loader/>;
	}

	let content;

	const template = page.template ?? '';

	const { href = page.fullPath, origin = '' } = staticContext || {};
	const contentClassName = classNames(['page-content', { 'loading': loading }]);

	switch (template) {
		case 'main-page':
			content = <Main content={page}/>;
			break;
		case 'news-list':
			content = <News content={page}/>;
			break;
		case 'mobile-page':
			content = <Mobile content={page}/>;
			break;
		case 'contacts-page':
			content = <Contacts content={page}/>;
			break;
		case 'about-page':
			content = <About content={page}/>;
			break;
		case 'gift-card-page':
			content = <GiftCard content={page}/>;
			break;
		case 'salon-page':
			content = <SalonDetails content={page}/>;
			break;
		case 'salons-list':
			content = <SalonsListPage content={page}/>;
			break;
		case 'service-page':
			content = <ServicePage content={page}/>;
			break;
		case 'news-page':
			content = <SingleNews content={page}/>;
			break;
		case 'club-page':
			content = <Club content={page}/>;
			break;
		case 'promo-page':
			content = <Promo content={page}/>;
			break;
		case 'text-page':
			content = <TextPage content={page}/>;
			break;
		case 'redirect': {
			return <SSRRedirect status={page.status} to={page.to}/>;
		}
		default:
			return <PageNotFound/>;
	}

	const { seo } = page.templateVars;

	return <div className={contentClassName}>
		<Helmet>
			<title>{page.name || 'Страница не найдена'}</title>
			<meta name={'description'} content={seo?.description}/>
			<meta name={'keywords'} content={seo?.keywords}/>
			{page.noIndex && (
				<meta name="robots" content="noindex, nofollow"/>
			)}
			<meta property={'og:site_name'} content="Пальчики"/>
			<meta property={'og:title'} content={page.name || 'Страница не найдена'}/>
			<meta property={'og:type'} content={'website'}/>
			<meta property={'og:description'} content={seo?.description}/>
			<meta property={'og:url'} content={href}/>
			<meta property={'og:image'} content={(origin || '') + '/static/share-image.png'}/>
			<meta property={'og:image:width'} content="1200"/>
			<meta property={'og:image:height'} content="1200"/>
			<link rel={'preconnect'} href={'https://api-maps.yandex.ru'}/>
			<link rel={'preconnect'} href={'https://www.google-analytics.com'}/>
			<link
				rel={'canonical'}
				href={'https://' + `${settings.domain}/${page.fullPath}`.replaceAll('//', '/').replace(/\/$/, '').replace(/(\?|\&)([^=]+)=?([^&]+)/g, '')}
			/>
		</Helmet>
		{content}
		<Footer noSeasons={template === 'salon-page'}/>
		<Cookie/>
	</div>;
}

export default withRouter(PageContent);
