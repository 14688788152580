import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import SmartLink from '@/components/SmartLink';

const parserOptions = {
	replace: (node) => {
		if (node.name === 'a') {
			const { href, ...props } = node.attribs;
			delete props.style;
			delete props.class;
			delete props.target;
			return <SmartLink path={href} {...props}>{domToReact(node.children, parserOptions)}</SmartLink>;
		}
	},
};

export default function parseHtml(html) {
	return parse(html.replaceAll('\n', ''), parserOptions);
}
