import React from 'react';

const useScroll = (element, callback) => {

	const handler = React.useCallback((e) => {
		const target = e.target instanceof HTMLDocument
			? e.target.documentElement
			: e.target;
		const newPosition = {
			x: target.scrollLeft,
			y: target.scrollTop,
		};
		callback(newPosition);
	}, [callback]);

	React.useEffect(() => {
		if (element) {
			element.addEventListener('scroll', handler, { passive: true });
		}
		return () => {
			if (element) {
				element.removeEventListener('scroll', handler);
			}
		};
	}, [element, handler]);

};

export default useScroll;
