import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import classNames from 'classnames';

import styles from './Stars.module.scss';

class Stars extends React.PureComponent {

	static defaultProps = {
		starsCount: 5,
	};

	render() {
		return (
			<StarRatingComponent
				name={this.props.name}
				starCount={this.props.starsCount}
				value={this.props.value}
				onStarClick={this.props.onChange}
				renderStarIcon={(index, value) => {
					const className = classNames({
						[styles.star]: true,
						[styles.active]: value >= index,
					});
					return <div className={className}/>;
				}}
			/>
		);
	}
}

export default Stars;
