import React from 'react';
import classNames from 'classnames';

import styles from './Social.module.css';

import facebookIcon from '../../assets/social-facebook.svg';
import vkIcon from '../../assets/social-vk.svg';
import instagramIcon from '../../assets/social-inst.svg';
import telegramIcon from '../../assets/social-tg.svg';


function Social(props) {

	let icon;

	const SocialClass = classNames({
		[styles.imgSettingsNavHeader]: props.place === 'header',
		[styles.imgSettingsNavFooter]: props.place === 'footer',
	});

	switch (props.type) {
		case 'facebook':
			icon = facebookIcon;
			break;

		case 'vk':
			icon = vkIcon;
			break;
		case 'instagram':
			icon = instagramIcon;
			break;
		case 'telegram':
			icon = telegramIcon;
			break;

		default: break;
	}

	return (
		<a href={props.link} target={'_blank'} className={styles.imgLink} rel="noopener noreferrer">
			<img src={icon} alt={props.type} className={SocialClass}/>
		</a>
	);
}

export default Social;
