import React from 'react';

import styles from './Mobile.module.scss';

import Button from '../../components/Button/Button';
import YmapAndSalons from '../../components/SalonsListController/SalonsListController';
import parseHtml from '@/utils/htmlParserWithCustomLinks';
import { Picture } from '@/components/Picture';

export const Mobile = (props) => {

	const page = props.content;
	const vars = page.templateVars.pageVars;

	return <div className={styles.container}>
		<div className={styles.contentContainer}>
			<div className={styles.textContainer}>
				<div className={styles.content}>
					<h1 className={styles.title}>{page.title}</h1>
					<article className={'text-content'}>{parseHtml(vars.content || '')}</article>
				</div>
				<div className={styles.imageContainer}>
					{vars.image && (
						<Picture
							className={styles.img}
							image={vars.image.image}
							desktopType={'original-webp'}
							mobileType={'original-webp'}
							alt={vars.image.imageAlt}
						/>
					)}
				</div>
			</div>
			<div className={styles.links}>
				<a href={vars.appstore} target={'_blank'} rel="noopener noreferrer">
					<Button className={styles.MobileBtns}>Apple App Store</Button>
				</a>
				<a href={vars.googleplay} target={'_blank'} rel="noopener noreferrer">
					<Button className={styles.MobileBtns}>Google Play</Button>
				</a>
			</div>
		</div>
		<div className={styles.map}>
			<YmapAndSalons withCityTitle/>
		</div>
	</div>;
};
