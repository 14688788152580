import React from 'react';
import classNames from 'classnames';

import Stars from '../Stars';

import styles from './StarsWithLabel.module.scss';

const StarsWithLabel = ({ title, name, value, onChange, className, titleClassName }) => (
	<div className={classNames([styles.container, className])}>
		<div className={classNames([styles.fieldTitle, titleClassName])}>{title}</div>
		<div className={styles.starsContainer}>
			<Stars
				name={name}
				value={value}
				onChange={onChange}
			/>
		</div>
	</div>
);

export default StarsWithLabel;
