import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SalonsListController.module.scss';

import SalonsList from '../SalonsList/SalonsList';
import YandexHeader from '../YandexMapHeader/YandexMapHeader';
import YandexMap from '../MapSalons/MapSalons';
import { useHistory } from 'react-router';
import { YMaps } from '@pbe/react-yandex-maps';
import { getSelectedCity } from '@/store/selectors';
import { setAppointmentSalonAction } from '@/store/actions';
import { InView } from 'react-intersection-observer';

function SalonsListController({ listPageSize = 8, lazy = true, withCityTitle = false, onSelectSalon }) {
	const [mode, setMode] = React.useState('map');
	const city = useSelector(getSelectedCity);
	const dispatch = useDispatch();
	const history = useHistory();

	const toggleMode = React.useCallback(() => {
		setMode((mode) => mode === 'list' ? 'map' : 'list');
	}, []);

	const handleSelectSalon = React.useCallback((salonId) => {
		dispatch(setAppointmentSalonAction(salonId));
		if (onSelectSalon) {
			onSelectSalon(salonId);
		} else {
			history.push('#appointment');
		}
	}, [onSelectSalon, dispatch, history]);

	const headerClassName = classNames([styles.yandexHeader, {
		[styles.withHeader]: withCityTitle,
	}]);

	return (
		<div className={styles.container}>
			<div className={headerClassName}>
				{withCityTitle &&
					<div className={styles.cityTitleContainer}>
						<span className={styles.cityTitle}>Салоны в г. {city?.city?.name ?? ''}</span>
					</div>
				}
				<div className={styles.modePicker}>
					<YandexHeader
						mode={mode}
						onChangeMod={toggleMode}
						setClass={mode === 'map' ? 'map' : 'list'}/>
				</div>
			</div>
			<div className={styles.yandexMapContainer}>
				{mode === 'list'
					? (<SalonsList onSelectSalon={handleSelectSalon} perPage={listPageSize}/>)
					: (
						<InView>
							{({ ref, inView }) => (
								<div ref={ref} style={{ width: '100%' }}>
									{inView && (
										<YMaps version={'2.1'}><YandexMap onSelectSalon={handleSelectSalon} lazy={lazy} city={city}/></YMaps>
									)}
								</div>
							)}
						</InView>
					)}
			</div>
		</div>
	);
}

export default React.memo(SalonsListController);
