import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { setAppointmentServicesAction } from '@/store/actions';

import chunk from '../../utils/chunk';

import ServiceInfo from '../../components/ServiceInfo/ServiceInfo';
import ImageLoader from '../../components/ImageLoader/ImageLoader';

import styles from './ServicePage.module.scss';
import MainMenu from '@/components/MainMenu/MainMenu';
import { getActiveServicesIndex } from '@/store/selectors';
import PageNotFound from '@/components/PageNotFound';
import { Picture } from '@/components/Picture';

export const ServicePage = (props) => {
	const page = props.content;
	const vars = page.templateVars.pageVars;
	const { service, images = [] } = vars;
	const [selectedImageIdx, setSelectedImageIdx] = useState(0);
	let servicesImage;
	const dispatch = useDispatch();
	const activeServicesIndex = useSelector(getActiveServicesIndex);
	const handleSelect = useCallback(() => {
		dispatch(setAppointmentServicesAction(0, [{ id: service.id, amount: 1 }]));
	}, [dispatch, service.id]);

	if (vars.images.length > 0) {
		const image = vars.images.find((i) => i.type === 'image');
		servicesImage = image.image.find((i) => i.type === 'fullscreen-mobile');
	}
	const defaultCategory = useMemo(() => {
		return [1, 2, 3].includes(service.type) ? service.type : 3;
	}, [service.type]);

	const activeService = useMemo(() => {
		return activeServicesIndex[vars.service.id];
	}, [vars, activeServicesIndex]);

	const perPage = 6;
	const thumbPages = chunk(images, perPage);
	const selectedImage = images[selectedImageIdx];
	let currentSlideComponent;
	if (selectedImage) {
		switch (selectedImage.type) {
			default:
			case 'image':
				currentSlideComponent =
					<div className={styles.currentSlide}>
						<Picture
							image={selectedImage.image}
							desktopType={'fullscreen-desktop'}
							mobileType={'fullscreen-mobile'}
							className={styles.currentSlide}
							alt={selectedImage.imageAlt}
						/>
						<div className={styles.descriptionImg}>{selectedImage.title}</div>
					</div>;
				break;
			case 'youtube':
				/* eslint-disable */
				const videoId = selectedImage.youtubeLink.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?(?:.*&)?v=|(?:embed|v)\/))([^\?&"'>]+)/);
				const src = `https://www.youtube.com/embed/${videoId[1]}?modestbranding=1&autohide=1&showinfo=0&disablekb=1&loop=1`;
				/* eslint-enable */
				currentSlideComponent =
					<div className={styles.currentSlide}>
						<iframe
							allow="autoplay; encrypted-media"
							title={selectedImage.title}
							src={src}
						/>
						<div className={styles.descriptionImg}>{selectedImage.title}</div>
					</div>;
				break;
		}
	}

	if (!activeService) {
		return <PageNotFound/>;
	}

	return (
		<div className={styles.container}>
			<Helmet>
				<meta property={'og:type'} content={'website'}/>
				{servicesImage && (
					<meta property={'og:image'} content={servicesImage.link}/>
				)}
				{servicesImage && (
					<meta property={'og:image:width'} content={servicesImage.resolution.split('x')[0]}/>
				)}
				{servicesImage && (
					<meta property={'og:image:height'} content={servicesImage.resolution.split('x')[1]}/>
				)}
			</Helmet>
			<div className={styles.content}>
				<div className={styles.slider}>
					<div className={styles.infoImgBox}>
						{currentSlideComponent}
					</div>
					{thumbPages.map((images, i) => (
						<div key={i} className={styles.thumbnails}>
							{images.map((image, j) => {
								const index = i * perPage + j;
								const thumbClassName = classNames([styles.thumb, {
									[styles.active]: index === selectedImageIdx,
								}]);
								if (image.type === 'image') {
									return (
										<Picture
											key={index}
											image={image.image}
											desktopType={'preview'}
											mobileType={'preview'}
											className={thumbClassName}
											onClick={() => setSelectedImageIdx(index)}
											alt={image.imageAlt}
										/>
									);
								}
								const videoId = image.youtubeLink.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?(?:.*&)?v=|(?:embed|v)\/))([^\?&"'>]+)/);
								const preview = videoId[1] ? `https://img.youtube.com/vi/${videoId[1]}/0.jpg` : '';
								return (
									<ImageLoader
										key={index}
										src={preview}
										className={thumbClassName}
										onClick={() => setSelectedImageIdx(index)}
									/>
								);
							})}
						</div>
					))}
				</div>
				<div className={styles.service}>
					<div className={styles.info}>
						<ServiceInfo
							noLink
							asPageInfo
							service={activeService}
							onSelect={handleSelect}
						/>
					</div>
				</div>
			</div>
			<MainMenu onlyMainCategories defaultCategory={defaultCategory}/>
		</div>
	);
};
