import React, { useCallback, useState } from 'react';
import styles from '@/templates/GiftCard/GiftCard.module.scss';
import Button from '@/components/Button/Button';
import Modal from '@/components/Modal';
import CertificateRecipientForm from '@/components/Forms/CertificateRecipientForm';
import formatPhone from '@/utils/formatPhone';

function GiftCardsCart(props) {
	const {
		order,
		isSubmitting,
		onRemove,
		onIncreaseAmount,
		onReduceAmount,
		onAddMore,
		onEditRecipient,
		onSubmit,
	} = props;

	const [editRecipientIndex, setEditRecipientIndex] = useState(null);

	const handleEditRecipient = useCallback((index) => {
		setEditRecipientIndex(index);
	}, []);

	const handleEditRecipientSubmit = useCallback((editRecipientIndex, data) => {
		onEditRecipient(editRecipientIndex, data);
		setEditRecipientIndex(null);
	}, [onEditRecipient]);

	if (order.items.length) {
		return (
			<div className={styles.order}>
				<div className={styles.cart}>
					<div className={styles.cartHeader}>
						<div className={styles.cartRow}>
							<div data-name={'image'} className={styles.cartCell}></div>
							<div data-name={'recipient'} className={styles.cartCell}>
								Получатель
							</div>
							<div data-name={'amount'} className={styles.cartCell}>
								Кол-во
							</div>
							<div data-name={'sum'} className={styles.cartCell}>
								Сумма
							</div>
							<div data-name={'actions'} className={styles.cartCell}>

							</div>
						</div>
					</div>
					<div className={styles.cartContent}>
						{order.items.map((item, index) => {
							return (
								<div key={index} className={styles.cartRow}>
									<div data-name={'image'} className={styles.cartCell}>
										<img className={styles.cartImage} alt={item.certificate.name} src={item.certificate.image}/>
									</div>
									<div data-name={'recipient'} className={styles.cartCell}>
										<div className={styles.cartRecipient}>
											<div className={styles.cartRecipientInfo}>
												<div className={styles.cartRecipientProp}>{item.recipient.name}</div>
												<div className={styles.cartRecipientProp}>{item.recipient.email}</div>
												<div className={styles.cartRecipientProp}>{formatPhone(item.recipient.phone)}</div>
											</div>
											<button onClick={() => handleEditRecipient(index)} className={styles.recipientChange}/>
										</div>
									</div>
									<div data-name={'amount'} className={styles.cartCell}>
										<div className={styles.cartAmount}>
											<button
												onClick={() => {
													onReduceAmount(index);
												}}
												className={styles.amountReduce}
											/>
											<div className={styles.amount}>{item.amount}</div>
											<button
												onClick={() => {
													onIncreaseAmount(index);
												}}
												className={styles.amountIncrease}
											/>
										</div>
									</div>
									<div data-name={'sum'} className={styles.cartCell}>
										{item.certificate.sum * item.amount} ₽
									</div>
									<div data-name={'actions'} className={styles.cartCell}>
										<button
											onClick={() => {
												onRemove(index);
											}}
											className={styles.cartItemRemove}
										/>
									</div>
								</div>
							);
						})}
					</div>
					<div className={styles.cartSummary}>
						<Button className={styles.cartAddMore} onClick={onAddMore}>Добавить еще карту</Button>
						<div className={styles.cartTotal}>
							Итого: {order.items.reduce((acc, item) => acc + item.certificate.sum * item.amount, 0) - order.discount} ₽
						</div>
						<Button
							className={styles.cartPayment}
							shadowColor={'pink'}
							disabled={isSubmitting}
							onClick={onSubmit}
						>
							Оплатить
						</Button>
					</div>
				</div>
				{editRecipientIndex !== null && (
					<Modal
						isOpen
						onClose={() => setEditRecipientIndex(null)}
					>
						<CertificateRecipientForm
							data={order.items[editRecipientIndex].recipient}
							onSubmit={(data) => handleEditRecipientSubmit(editRecipientIndex, data)}
						/>
					</Modal>
				)}
			</div>
		);
	} else {
		return (
			<div className={styles.orderEmpty}>
				<div className={styles.orderEmptyTitle}>
					В корзине пока ничего нет
				</div>
				<Button onClick={onAddMore}>
					Добавить карту
				</Button>
			</div>
		);
	}
}

export default GiftCardsCart;
