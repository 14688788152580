import React from 'react';
import Modal from '../../components/Modal';
import FlatButton from '../../components/Button/Button';
import Stars from '../../components/Stars';

import styles from './Modals.module.scss';

const Field = ({ title, name, value, onChange }) => (
	<div className={styles.rateField}>
		<div className={styles.fieldTitle}>{title}</div>
		<div className={styles.starsContainer}>
			<Stars
				name={name}
				value={value}
				onChange={onChange}
			/>
		</div>
	</div>
);

class RateModal extends React.Component {

	static defaultProps = {
		isOpen: false,
		submitting: false,
	};

	state = {
		answer1: 5,
		answer2: 5,
		answer3: 5,
		answer4: 5,
		answer5: 5,
		comment: '',
	};

	handleAnswerChange = (index, value, name) => {
		this.setState({ [name]: index });
	};

	handleCommentChange = (e) => {
		this.setState({ comment: e.target.value });
	};

	handleButtonClick = () => {
		this.props.onSubmit({
			answers: {
				answer1: this.state.answer1,
				answer2: this.state.answer2,
				answer3: this.state.answer3,
				answer4: this.state.answer4,
				answer5: this.state.answer5,
			},
			comment: this.state.comment,
		});
		this.setState({ comment: '' });
	};

	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				className={styles.container}
				title={'Оставить отзыв'}
				onClose={this.props.onClose}
			>
				<div className={styles.fieldsContainer}>
					<Field
						title={'Качество оказанной услуги'}
						name={'answer1'}
						value={this.state.answer1}
						onChange={this.handleAnswerChange}
					/>
					<Field
						title={'Время обслуживания'}
						name={'answer2'}
						value={this.state.answer2}
						onChange={this.handleAnswerChange}
					/>
					<Field
						title={'Чистота в салоне'}
						name={'answer3'}
						value={this.state.answer3}
						onChange={this.handleAnswerChange}
					/>
					<Field
						title={'Атмосфера в салоне'}
						name={'answer4'}
						value={this.state.answer4}
						onChange={this.handleAnswerChange}
					/>
					<Field
						title={'Общее впечатление от посещения салона'}
						name={'answer5'}
						value={this.state.answer5}
						onChange={this.handleAnswerChange}
					/>
					<div className={styles.field}>
						<textarea
							className={styles.textarea}
							value={this.state.comment}
							onChange={this.handleCommentChange}
							placeholder={'Комментарий'}
						/>
					</div>
				</div>
				<FlatButton
					disabled={this.props.submitting}
					onClick={this.handleButtonClick}
					className={styles.button}
				>
					Оставить отзыв
				</FlatButton>
			</Modal>
		);
	}
}

export default RateModal;
