import React from 'react';

import styles from './MenuItem.module.scss';
import { MenuContext } from '@/components/Menu/Menu';
import SmartLink from '@/components/SmartLink';

function MenuItem(props) {

	const { item, onClick } = props;

	const { position } = React.useContext(MenuContext);

	const title = item.title;
	const path = item.linkedPage?.fullPath ?? item.file?.link ?? item.path;

	return <li onClick={onClick} className={styles.container} data-position={position}>
		<SmartLink title={title} path={path} className={styles.link}>
			<span className={styles.text}>{title}</span>
		</SmartLink>
	</li>;
}

export default MenuItem;
