import React from 'react';
import Spinner from 'react-md-spinner';

import styles from './Loader.module.scss';

const Loader = (props) => {
	const { size = 32 } = props;
	return (
		<div className={styles.loader}>
			<Spinner size={size} singleColor={'#FFD9CD'} borderSize={4}/>
		</div>
	);
};

export default Loader;
