import React from 'react';
import { NavigationTop } from '@/components/NavigationTop/NavigationTop';
import { Header } from '@/components/Header/Header';
import PageContent from '@/components/PageContent/PageContent';
import AuthController from '@/components/AuthController/AuthController';
import Loader from '@/components/Loader/Loader';

import 'reset-css/reset.css';
import './App.scss';
import AppointmentController from '@/components/AppointmentController';
import HashRoute from '@/components/HashRoute';

export function AppContent({ initError, initLoading }) {

	if (initError) {
		return <div className={'init-error'}>
			Произошла ошибка при загрузке сайта!<br/>
			Мы уже работаем над исправлением проблемы.
		</div>;
	}

	return <div className={'App'}>
		<div className={'container'}>
			{initLoading ?
				(
					<Loader/>
				) : (
					<>
						<AuthController/>
						<HashRoute hash={'#appointment'}>
							<AppointmentController/>
						</HashRoute>
						<Header/>
						<NavigationTop/>
						<PageContent/>
					</>
				)}
		</div>
	</div>;
}
