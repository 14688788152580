import store from './store';
import React from 'react';
import request from './utils/request';
import apiConfig from './config.json';
import { AppContent } from '@/AppContent';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router';
import { initApp, initPage } from '@/utils/initApp';
import { createObservers } from '@/utils/createObservers';
import useScroll from '@/hooks/useScroll';
import history from './utils/history';
import { getPage, getToken } from '@/store/selectors';
import { setFirstStartAction } from '@/store/actions';

request.interceptors.request.use((config) => {
	const authToken = getToken(store.getState());
	if (authToken) {
		config.headers['Authorization'] = `Bearer ${authToken}`;
	}
	config.baseURL = apiConfig.frontendApi;
	return config;
});

export function App() {

	const [initError, setInitError] = React.useState(false);
	// eslint-disable-next-line no-undef
	const [initLoading, setInitLoading] = React.useState(!!IS_DEV_SERVER);

	React.useEffect(() => {
		initApp(store)
			.catch((e) => {
				console.error(e);
				setInitError(true);
			})
			.then(() => {
				return initPage(store, history.location.pathname, history.location.search);
			})
			.then(() => {
				if (Storage.isDefined) {
					if (!Storage.getItem('visited')) {
						const page = getPage(store.getState());
						if (page.template !== 'salon-page') {
							store.dispatch(setFirstStartAction(true));
						}
					} else {
						store.dispatch(setFirstStartAction(false));
					}
					Storage.setItem('visited', (new Date()).toString());
				}
			})
			.then(() => {
				setInitLoading(false);
			})
			.catch((e) => {
				console.error(e);
				setInitLoading(false);
			})
			.finally(() => {
				setInitLoading(false);
			});

		createObservers();

	}, []);

	useScroll(
		typeof window !== 'undefined' ? window : null,
		({ y }) => {
			if (y > 5) {
				document.body.classList.add('scrolled');
			} else {
				document.body.classList.remove('scrolled');
			}
		},
	);

	return (
		<Provider store={store}>
			<HelmetProvider>
				<Router history={history}>
					<AppContent initError={initError} initLoading={initLoading}/>
				</Router>
			</HelmetProvider>
		</Provider>
	);
}
