import formStyles from '@/components/Forms/Form.module.scss';
import ShadowInput from '@/components/ShadowInput/ShadowInput';
import { focusMaskStart, phoneInputMask } from '@/utils/masks';
import Button from '@/components/Button/Button';
import React, { useCallback, useState } from 'react';
import { validateEmail } from '@/utils/validateEmail';
import { conformToMask } from 'react-text-mask';

function CertificateRecipientForm(props) {
	const { data, onSubmit } = props;

	const [formValues, setFormValues] = useState({
		email: '',
		name: '',
		...data,
		phone: data.phone ? conformToMask('+' + data.phone, phoneInputMask).conformedValue : '',
	});

	const [formErrors, setFormErrors] = useState({
		email: false,
		name: false,
		phone: false,
	});
	const setFormField = useCallback((name, value) => {
		setFormValues((values) => ({ ...values, [name]: value }));
	}, []);

	const handleSubmit = useCallback((event) => {
		event.preventDefault();
		let hasErrors = false;
		const errors = {
			email: false,
			phone: false,
			name: false,
		};
		const email = formValues.email;
		const phone = formValues.phone.replace(/\D/g, '');
		if (!email.length || !validateEmail(email)) {
			hasErrors = true;
			errors.email = true;
		}
		if (phone.length > 0 && phone.length < 11) {
			hasErrors = true;
			errors.phone = true;
		}
		setFormErrors(errors);
		if (hasErrors) {
			return;
		}
		onSubmit({ ...formValues, phone });
	}, [formValues, onSubmit]);


	const handlePhoneInputBlur = (e) => {
		if (e.target.value.replace(/\D/g, '') === '7') {
			setFormField('phone', '');
		}
	};

	const handlePhoneInputChange = (value) => {
		setFormField('phone', value);
	};

	return (
		<form className={formStyles.form} onSubmit={handleSubmit}>
			<div className={formStyles.fields}>
				<ShadowInput
					name={'email'}
					placeholder={'Email *'}
					className={formStyles.field}
					value={formValues.email}
					error={formErrors.email}
					onChange={(value) => setFormField('email', value)}
				/>
				<ShadowInput
					name={'name'}
					placeholder={'Имя'}
					className={formStyles.field}
					value={formValues.name}
					error={formErrors.name}
					onChange={(value) => setFormField('name', value)}
				/>
				<ShadowInput
					value={formValues.phone}
					error={formErrors.phone}
					type={'tel'}
					name={'phone'}
					mask={phoneInputMask}
					className={formStyles.field}
					placeholder={'Мобильный телефон'}
					onFocus={focusMaskStart}
					onBlur={handlePhoneInputBlur}
					onChange={handlePhoneInputChange}
				/>
			</div>
			<div className={formStyles.actions}>
				<Button type={'submit'}>
					Готово
				</Button>
			</div>
		</form>
	);
}

export default CertificateRecipientForm;
