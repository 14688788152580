export default class NetworkError extends Error {
	constructor(...args) {
		super(...args);
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, this.constructor);
		} else {
			this.stack = (new Error()).stack;
		}
	}
}
